import axios from "axios";
import { ChatMessage, SocketMessage } from "../state/chat";
import { Response } from "../state/response";
import NavBar from "../components/NavBar";
import { identifyUser } from "../utils/analytics";

const TOKEN_KEY = "token";

axios.defaults.baseURL = "https://api.insessions.co/";

axios.defaults.headers.post["Content-Type"] = "applications/json";
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${window.localStorage.getItem(TOKEN_KEY)}`;

export const setAuth = async (token: string) => {
  window.localStorage.setItem(TOKEN_KEY, token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  NavBar.setIsAuthenticated(true);

  const user = await getCurrentUser();
  if (user) {
    identifyUser(user);
  }

  return user;
};

export const removeAuth = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  axios.defaults.headers.common["Authorization"] = undefined;
};

export const fetchStoredAuth = async () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (token) {
    return await setAuth(token);
  }
};

export const getAuthToken = () => window.localStorage.getItem(TOKEN_KEY);

export const requestHandler = async <T, E>(
  method: string,
  url: string,
  data?: T
): Promise<E> => {
  return (await axios({ method, url, data })).data;
};

export interface GetCurrentUserResponse {
  id: string;
  username: string;
  thumbnailUrl?: string;
  isCreator?: boolean;
}

export const getCurrentUser = async (): Promise<GetCurrentUserResponse> =>
  await requestHandler("get", "/currentuser");

export interface SubmitPhoneNumberRequest {
  phone: string;
}

export interface SubmitPhoneNumberResponse {
  verificationToken: string;
}

export const submitPhoneNumber = async (
  input: SubmitPhoneNumberRequest
): Promise<SubmitPhoneNumberResponse> =>
  await requestHandler("post", "/signin", input);

export interface VerifyPhoneRequest {
  phone: string;
  code: string;
  token: string;
}

export interface VerifyPhoneResponse {
  token: string;
  isSignedUp: boolean;
}

export const verifyPhoneNumber = async (
  input: VerifyPhoneRequest
): Promise<VerifyPhoneResponse> =>
  await requestHandler("post", "/verify", input);

export interface CreateAccountRequest {
  username: string;
  token: string;
  thumbnailMediaId?: string;
}

export interface CreateAccountResponse {
  token: string;
}

export interface CreateMediaRequest {
  Username: string;
}

export interface CreateMediaResponse {
  id: string;
  uploadUrl: string;
}

export const createMedia = async (
  input: CreateMediaRequest
): Promise<CreateMediaResponse> =>
  await requestHandler("post", "/createmedia", input);

export const createAccount = async (
  input: CreateAccountRequest
): Promise<CreateAccountResponse> =>
  await requestHandler("post", "/createaccount", input);

export interface Session {
  id: string;
  title: string;
  username: string;
  thumbnailUrl: string;
  ownerId: string;
  externalId: string;
  externalRoomName: string;
  startedAt?: { Time: Date };
}
export interface CreateSessionRequest {
  minimumPrice: number;
  title: string;
  sessionDate?: Date;
}

export interface CreateSessionResponse {
  session: Session;
}

export const createSession = async (
  input: CreateSessionRequest
): Promise<CreateSessionResponse> =>
  await requestHandler("post", "/createsession", input);

export interface JoinSessionRequest {
  sessionId: string;
}

export interface JoinSessionResponse {
  session: Session;
  token: string;
}

export const joinSession = async (
  input: JoinSessionRequest
): Promise<JoinSessionResponse> =>
  await requestHandler("post", "/joinsession", input);

export interface GetRoomRequest {
  username: string;
}

export interface UpdateThumbnailRequest {
  thumbnailMediaId: string;
}

export const updateThumbnail = async (
  input: UpdateThumbnailRequest
): Promise<void> => await requestHandler("post", "/updatethumbnail", input);

export interface GetRoomResponse {
  sessionId: string;
  sessionTopic: string;
  sessionDate: Date;
  creatorUsername: string;
  creatorThumbnailUrl?: string;
  inProgress?: boolean;
  minimumPrice: number;
}

export interface GetAllSessionsResponse {
  previous: GetRoomResponse[];
  ongoing: GetRoomResponse[];
  future: GetRoomResponse[];
}

export const getRoom = async (
  input: GetRoomRequest
): Promise<GetRoomResponse> => await requestHandler("post", "/getroom", input);

export interface GetSessionRequest {
  sessionId?: string;
  username?: string;
}

export interface GetSessionResponse {
  session: Session;
  entitlementToken: string;
  pinnedMessage?: SocketMessage;
  canJoin?: boolean;
  thumbnailUrl?: string;
  minimumPrice: number;
  hasTipped?: boolean;
}

export const getSession = async (
  input: GetSessionRequest
): Promise<GetSessionResponse> =>
  await requestHandler("post", "/getsession", input);

export const getAllSessions = async (): Promise<GetAllSessionsResponse> =>
  await requestHandler("get", "/getsessions");

export interface UpdateParticipantIdRequest {
  sessionId: string;
  participantId: string;
}

export const updateParticipantId = async (
  input: UpdateParticipantIdRequest
): Promise<void> => await requestHandler("post", "/participantid", input);

export interface GetResponsesRequest {
  sessionId: string;
}

export interface GetResponsesResponse {
  responses: Response[];
}

export const getResponses = async (
  input: GetResponsesRequest
): Promise<GetResponsesResponse> => {
  const a = await requestHandler<any, any>("post", "/getresponses", input);
  return a;
};

export enum ProductType {
  TIP = "tip",
  ENTRY_FEE = "entry_fee",
}

export interface CreatePaymentRequest {
  productType: ProductType;
  promptResponse?: string;
  isAnonymous?: boolean;
  paymentMethodId: string;
  customerId: string;
  amount: number;
  metadata: { sessionId: string };
}

export interface CreatePaymentResponse {
  id: string;
  captureExpiresAt: number;
  requiresClientAction: boolean;
  clientSecret: string;
}

export const createPayment = async (
  input: CreatePaymentRequest
): Promise<CreatePaymentResponse> =>
  await requestHandler("post", "/payment", input);

export interface CreateEntitlementRequest {
  userId: string;
  sessionId: string;
}

export interface CreateEntitlementResponse {}

export const createEntitlement = async (
  input: CreateEntitlementRequest
): Promise<CreateEntitlementResponse> =>
  await requestHandler("post", "/createentitlement", input);

export interface UpdateResponseRequest {
  sessionId: string;
  response: string;
}

export interface UpdateResponseResponse extends Response {}

export const updateResponse = async (
  input: UpdateResponseRequest
): Promise<UpdateResponseResponse> =>
  await requestHandler("post", "/updateresponse", input);

export interface EarningEntry {
  date: Date;
  sessionEarnings: number;
  participants: number;
}

export interface GetEarningsResponse {
  totalEarnings: number;
  earnings: EarningEntry[];
}

export const getEarnings = async (): Promise<GetEarningsResponse> =>
  await requestHandler("post", "/getearnings");

export interface PurchaseEntry {
  sessionDate: Date;
  hostUsername: string;
  amount: number;
}

export interface GetPurchasesResponse {
  purchases: PurchaseEntry[];
}

export const getPurchases = async (): Promise<GetPurchasesResponse> =>
  await requestHandler("post", "/getpurchases");

export interface GetMessagesRequest {
  sessionId: string;
  offset: number;
  limit: number;
}

export interface GetMessagesResponse {
  messages: ChatMessage[];
}

export const getMessages = async (
  input: GetMessagesRequest
): Promise<GetMessagesResponse> => {
  return await requestHandler<any, any>("post", "/messages", input);
};

export interface RemovePinRequest {
  sessionId: string;
}

export const removePin = async (input: RemovePinRequest): Promise<void> => {
  return await requestHandler("post", "/removepin", input);
};

export interface CreateInterestRequest {
  sessionId: string;
}

export const createInterest = async (
  input: CreateInterestRequest
): Promise<void> => {
  return await requestHandler("post", "/createinterest", input);
};

export interface UpdateAnonymityRequest {
  sessionId: string;
  isAnonymous: boolean;
}

export const updateAnonymity = async (
  input: UpdateAnonymityRequest
): Promise<void> => {
  return await requestHandler("post", "/updateanonymity", input);
};
