import { useState } from "react";
import Drawer from "../../../components/Drawer";
import Modal from "../../../components/Modal";
import UserThumbnail from "../../../components/UserThumbnail";
import { IS_MOBILE } from "../../../utils/constants";
import { formatCurrency } from "../../../utils/formatCurrency";
import clsx from "clsx";
import PaymentForm from "../../../components/PaymentForm";
import { ProductType, createPayment } from "../../../api";
import Toaster from "../../../components/Toaster";

const FIRST_TIP_AMOUNT = 300;
const SECOND_TIP_AMOUNT = 1000;
const THIRD_TIP_AMOUNT = 1500;
const BUTTON_CLASSES =
  "px-[14px] pt-[20px] pb-[22px] text-[18px] rounded-[15px] border-[0.5px] border-black mx-2";

interface Props {
  isActive: boolean;
  onClose: (tipped?: boolean) => void;
  thumbnail?: string;
  username?: string;
  currentUserId?: string;
  sessionId?: string;
  ongoingSession?: boolean;
}

export default function TipModal({
  isActive,
  username,
  thumbnail,
  currentUserId,
  sessionId,
  ongoingSession,
  onClose,
}: Props) {
  const [error, setError] = useState<string>();
  const [tipAmount, setTipAmount] = useState<number | undefined>(
    SECOND_TIP_AMOUNT
  );
  const Container = IS_MOBILE ? Drawer : Modal;

  const _createPayment = async (amount: number, id: string) => {
    return await createPayment({
      productType: ProductType.TIP,
      amount,
      paymentMethodId: id,
      customerId: currentUserId || "",
      metadata: {
        sessionId: sessionId || "",
      },
    });
  };

  const onSubmitPayment = () => {
    Toaster.show({ message: "Thank You!" });
    onClose(true);
  };

  const onPressFirst = () => {
    if (tipAmount === FIRST_TIP_AMOUNT) {
      setTipAmount(undefined);
    } else {
      setTipAmount(FIRST_TIP_AMOUNT);
    }
  };

  const onPressSecond = () => {
    if (tipAmount === SECOND_TIP_AMOUNT) {
      setTipAmount(undefined);
    } else {
      setTipAmount(SECOND_TIP_AMOUNT);
    }
  };
  const onPressThird = () => {
    if (tipAmount === THIRD_TIP_AMOUNT) {
      setTipAmount(undefined);
    } else {
      setTipAmount(THIRD_TIP_AMOUNT);
    }
  };

  return (
    <Container
      isActive={isActive}
      onClose={() => onClose(false)}
      maxHeightClassName="max-h-[90%]"
    >
      <div className="flex flex-col items-center text-center">
        <UserThumbnail
          name={username}
          thumbnailUrl={thumbnail}
          borderColor="#00C947"
        />
        <div className="text-[14px] mt-6">
          {ongoingSession ? "Enjoying" : "Enjoyed"} {username}'s Session?
        </div>
        <div className="text-[28px]">Give {username} extra thanks</div>
        <div className="text-[14px] text-[#FF461E] h-[22px]">{error}</div>
        <div className="mt-6 flex">
          <button
            onClick={onPressFirst}
            className={clsx(
              BUTTON_CLASSES,
              tipAmount === FIRST_TIP_AMOUNT && "bg-[#00C947]"
            )}
          >
            US{formatCurrency(FIRST_TIP_AMOUNT)}
          </button>
          <button
            onClick={onPressSecond}
            className={clsx(
              BUTTON_CLASSES,
              tipAmount === SECOND_TIP_AMOUNT && "bg-[#00C947]"
            )}
          >
            US{formatCurrency(SECOND_TIP_AMOUNT)}
          </button>
          <button
            onClick={onPressThird}
            className={clsx(
              BUTTON_CLASSES,
              tipAmount === THIRD_TIP_AMOUNT && "bg-[#00C947]"
            )}
          >
            US{formatCurrency(THIRD_TIP_AMOUNT)}
          </button>
        </div>
        <div className="text-[14px] text-[#aaa] mt-4">
          {username} will see your contribution
        </div>
        <div className={clsx("px-6 w-full mb-6", IS_MOBILE && "pb-16")}>
          <PaymentForm
            paymentAmount={tipAmount || 0}
            paymentDescription={`Tip to ${username}`}
            isDisabled={!tipAmount || tipAmount === 0}
            buttonText="Confirm"
            setError={setError}
            _createPayment={_createPayment}
            onSubmitPayment={onSubmitPayment}
          />
        </div>
      </div>
    </Container>
  );
}
