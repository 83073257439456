import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

interface Props {
  children: string | React.ReactNode;
  className?: string;
  step?: number;
  containerStyle?: React.CSSProperties;
}

function ScaledText({
  children,
  className,
  step = 0.5,
  containerStyle,
}: Props) {
  const [fontSize, setFontSize] = useState<number | null>();
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const shouldStopNodeMutation = useRef<boolean>(false);
  const previousChildren = useRef<string | React.ReactNode>(children);

  useEffect(() => {
    if (children !== previousChildren.current) {
      previousChildren.current = children;
      shouldStopNodeMutation.current = true;
      setFontSize(undefined);
    }
  }, [children, previousChildren]);

  useEffect(() => {
    const node = ref.current;
    const container = containerRef.current;

    if (fontSize === undefined && container && node && children) {
      shouldStopNodeMutation.current = false;

      const containerHeight = container.offsetHeight;
      const computedFontSize = parseInt(
        window.getComputedStyle(container).fontSize
      ); // this value is always in pixel units

      node.style.fontSize = `${computedFontSize}px`;

      const contentHeight = node.scrollHeight;

      // vertical overflow
      if (containerHeight < contentHeight) {
        // compute rough font size value using proportional ratio
        let adjustedFontSize = Math.floor(
          computedFontSize * (containerHeight / contentHeight)
        );
        // apply it
        node.style.fontSize = `${adjustedFontSize}px`;
        // update current overflow value
        let hasOverflow = node.scrollHeight > containerHeight;

        // judge where to go
        const direction = hasOverflow ? -1 : 1;
        const expectedOverflowValue = !hasOverflow;

        // loop
        while (hasOverflow !== expectedOverflowValue) {
          if (shouldStopNodeMutation.current) {
            break;
          }
          adjustedFontSize += direction * step;
          node.style.fontSize = `${adjustedFontSize}px`;
          hasOverflow = node.scrollHeight > containerHeight;
        }

        if (!shouldStopNodeMutation.current) {
          // if we were expecting overflow, make one step back
          if (expectedOverflowValue) {
            adjustedFontSize -= step;
          }

          setFontSize(adjustedFontSize);
        }
      } else {
        setFontSize(null);
      }
    }
  }, [
    containerRef.current,
    ref.current,
    fontSize,
    shouldStopNodeMutation,
    children,
  ]);

  const isFontSizeUndetermined = fontSize === undefined;
  const style = isFontSizeUndetermined
    ? { opacity: 0 }
    : fontSize !== null
    ? { fontSize: `${fontSize}px` }
    : undefined;

  return (
    <div
      className={clsx(
        "w-full h-full flex flex-col items-center justify-center",
        className
      )}
      style={containerStyle}
    >
      <div
        ref={containerRef}
        className="w-full h-full flex items-start relative"
      >
        <div
          ref={ref}
          className="w-full flex flex-col justify-center break-words whitespace-pre-wrap"
          style={style}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ScaledText);
