import clsx from "clsx";
import UserThumbnail from "../../components/UserThumbnail";
import PinIcon from "../../components/Icons/PinIcon";
import { useState } from "react";
import Drawer from "../../components/Drawer";
import Onboarding from "../Onboarding";
import Modal from "../../components/Modal";
import {
  APPLY_FOR_CREATOR_LINK,
  IS_MOBILE,
  PRIVACY_POLICY_LINK,
  TERMS_LINK,
} from "../../utils/constants";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../state/user";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import NavBar from "../../components/NavBar";
import SessionsTitleLargeIcon from "../../components/Icons/SessionsTitleLargeIcon";
import BaseButton from "../../components/BaseButton";
import openExternalLink from "../../utils/openExternalLink";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import { trackEvent } from "../../utils/analytics";

const BlurredChat = require("../../components/Images/BlurredChat.png");
const example1 = require("./images/sessionExample_1.png");
const example2 = require("./images/sessionExample_2.png");
const example3 = require("./images/sessionExample_3.png");

const Container = IS_MOBILE ? Drawer : Modal;

export default function LandingPage() {
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState<"fans" | "creators">("fans");
  const isFans = selectedPage === "fans";

  const buttonText = isFans ? "Sign In" : "Apply as a creator";
  const bodyText = isFans
    ? "Your favorite creators responding to you in live sessions"
    : "Earn from casual live audio sessions with your fans";

  const [isOnboardingActive, setIsOnboardingActive] = useState<boolean>(false);
  const currentUser = useRecoilValue(currentUserState);

  if (currentUser) {
    navigate(ROUTES.ALL_SESSIONS);
  }

  const onButtonClick = () => {
    if (isFans) {
      trackEvent("Landing page: Tap Sign Up");
      setIsOnboardingActive(true);
    } else {
      openExternalLink(APPLY_FOR_CREATOR_LINK);
    }
  };

  const onCompleteOnboarding = () => {
    navigate(ROUTES.ALL_SESSIONS);
  };

  NavBar.setNavigation({
    title: <SessionsTitleLargeIcon />,
  });

  return (
    <>
      {!IS_MOBILE && (
        <div className="absolute top-[-40px] flex h-[24px] w-full justify-center">
          <button
            className={clsx("px-6 w-[50%]", !isFans && "text-[#0000004D]")}
            onClick={() => setSelectedPage("fans")}
          >
            For Fans
          </button>
          <div className="w-[0.5px] h-full bg-[#0000004D]" />
          <button
            className={clsx("px-6 w-[50%]", isFans && "text-[#0000004D]")}
            onClick={() => setSelectedPage("creators")}
          >
            For Creators
          </button>
        </div>
      )}
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={!IS_MOBILE}
        infiniteLoop
        autoPlay
        renderIndicator={(_, isSelected) => (
          <div
            style={{ transition: "opacity .25s ease-in;" }}
            className={clsx(
              "h-[6px] w-[6px] inline-block rounded-full mx-1",
              isSelected ? "bg-black" : "bg-[#ccc] opacity-[0.3]"
            )}
          />
        )}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            hasNext && (
              <button
                onClick={clickHandler}
                className="z-10 right-[124px] bottom-[7.5px] absolute h-[24px] w-[24px] border-[1px] border-black rounded-full flex items-center justify-center"
              >
                <ChevronIcon direction="right" />
              </button>
            )
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            hasPrev && (
              <button
                onClick={clickHandler}
                className="z-10 left-[124px] bottom-[7.5px] absolute h-[24px] w-[24px] border-[1px] border-black rounded-full flex items-center justify-center"
              >
                <ChevronIcon direction="left" />
              </button>
            )
          );
        }}
        className="pb-4 mt-4"
      >
        <img src={example1} className="pb-[30px]" />
        <img src={example2} className="pb-[30px]" />
        <img src={example3} className="pb-[30px]" />
      </Carousel>
      {IS_MOBILE && (
        <div className="flex text-[14px] font-medium">
          <button
            className={clsx(
              "w-[50%] border-b-[1px] py-2",
              isFans ? "border-b-black" : "border-b-white"
            )}
            onClick={() => setSelectedPage("fans")}
          >
            For Fans
          </button>
          <button
            className={clsx(
              "w-[50%] border-b-[1px] py-2",
              !isFans ? "border-b-black" : "border-b-white"
            )}
            onClick={() => setSelectedPage("creators")}
          >
            For Creators
          </button>
        </div>
      )}
      <div
        className="px-4 flex flex-col flex-1"
        style={{
          height: "calc(50% - 20px)",
          backgroundImage: `url(${BlurredChat})`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex-1 text-[28px] text-center flex items-center justify-center mx-[10px] leading-[32px]">
          {bodyText}
        </div>
        <BaseButton className="mb-4" onClick={onButtonClick}>
          {buttonText}
        </BaseButton>
        {IS_MOBILE && (
          <div className="text-center text-[12px] px-6 text-[#0000004D] mb-2">
            {" "}
            By clicking ‘Sign In’ you agree to Sessions’{" "}
            <a className="underline" href={TERMS_LINK} target="_blank">
              Terms
            </a>{" "}
            and our{" "}
            <a className="underline" href={PRIVACY_POLICY_LINK} target="_blank">
              Privacy Policy
            </a>
            .
          </div>
        )}
      </div>
      {!IS_MOBILE && (
        <div
          className={clsx(
            "absolute bottom-[-50px] w-[375px] text-center text-[12px] px-6 text-[#0000004D]"
          )}
        >
          By clicking ‘Sign In’ you agree to Sessions’{" "}
          <a className="underline" href={TERMS_LINK} target="_blank">
            Terms
          </a>{" "}
          and our{" "}
          <a className="underline" href={PRIVACY_POLICY_LINK} target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      )}
      <Container
        isActive={isOnboardingActive}
        onClose={() => setIsOnboardingActive(false)}
        maxHeightClassName="max-h-[90%]"
        containerClassName={clsx(!IS_MOBILE && "px-8 py-4")}
      >
        <Onboarding onCompleteOnboarding={onCompleteOnboarding} />
      </Container>
    </>
  );
}
