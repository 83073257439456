import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import Drawer from "../../components/Drawer";
import Onboarding from "../Onboarding";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Modal from "../../components/Modal";
import { IS_MOBILE } from "../../utils/constants";
import { useRoom } from "../../state/room";
import { ScreenSpinner } from "../../components/Spinner";
import QuestionModal from "./components/QuestionModal";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../state/user";
import { createEntitlement, createInterest, joinSession } from "../../api";
import { ROUTES } from "../../routes";
import { useSession } from "../../state/session";
import NavBar from "../../components/NavBar";
import HomeIcon from "../../components/Icons/HomeIcon";
import ProfileIcon from "../../components/Icons/ProfileIcon";
import { trackEvent } from "../../utils/analytics";
import TipModal from "./components/TipModal";
import {
  getNewSessionTimestamp,
  getUpcomingSessionTimestamp,
} from "../../utils/getSessionTimestamp";
import Toaster from "../../components/Toaster";
import { Carousel } from "react-responsive-carousel";
import RoomHeader from "./components/RoomHeader";
import RoomBody from "./components/RoomBody";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import CarouselItem from "./components/CarouselItem";
import "./styles/style.css";
import { CarouselState } from "react-responsive-carousel/lib/ts/components/Carousel/types";

const preview1 = require("./assets/preview.mp4");

export default function Room() {
  const { username: creatorUsername } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [isOnboardingActive, setIsOnboardingActive] = useState<boolean>(false);
  const [isQuestionActive, setIsQuestionActive] = useState<boolean>(false);
  const [isTipActive, setIsTipActive] = useState<boolean>(false);
  const [question, setQuestion] = useState<{
    question?: string;
    isAnonymous?: boolean;
  }>({});

  const currentUser = useRecoilValue(currentUserState);
  const _room = useRoom(creatorUsername || "");
  const { session, refresh } = useSession(creatorUsername || "");

  const { canJoin } = session || {};

  const { isLoading, room, setRoom, refreshRoom } = _room;

  const Container = IS_MOBILE ? Drawer : Modal;

  useEffect(() => {
    refreshRoom();

    if (location?.state?.isFromSessionEnd) {
      setIsTipActive(true);
      window.history.replaceState({}, "");
    }
  }, []);

  useEffect(() => {}, [location]);

  const onLeftIconPress = () => {
    navigate(ROUTES.ALL_SESSIONS, {
      state: { fromRoom: true, fromSessionId: creatorUsername },
    });
  };

  const onRightIconPress = () => {
    navigate(ROUTES.SETTINGS, {
      state: { fromRoom: true, fromSessionId: creatorUsername },
    });
  };

  useEffect(() => {
    if (room) {
      if (!room.inProgress) {
        trackEvent("Landing page: Visit while not in session", {
          creator: room.creatorUsername,
        });
      } else {
        trackEvent("Landing page: Visit while in session", {
          creator: room.creatorUsername,
        });
      }
    }
  }, [room]);

  useEffect(() => {
    if (currentUser) {
      NavBar.setNavigation({
        leftIcon: currentUser?.isCreator ? (
          <button onClick={onLeftIconPress}>
            <HomeIcon />
          </button>
        ) : undefined,
        rightIcon: (
          <button onClick={onRightIconPress}>
            <ProfileIcon />
          </button>
        ),
      });
    }
  }, [currentUser]);

  const sessionDate = new Date(room?.sessionDate!);
  const now = new Date();

  const inSession = !!room?.inProgress;
  const isUpcoming = sessionDate > now;

  useEffect(() => {
    if (isUpcoming) {
      const timeRemaining = sessionDate.getTime() - now.getTime();
      const timeout = setTimeout(() => {
        refreshRoom();
        refresh(creatorUsername || "");
      }, timeRemaining);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isUpcoming, room?.sessionDate, refreshRoom, refresh, creatorUsername]);

  if (isLoading) {
    return <ScreenSpinner />;
  }

  const onSubmitQuestion = async (question?: string, isAnonymous?: boolean) => {
    trackEvent("Landing page: Submit Response", { creator: creatorUsername });
    setQuestion({ question, isAnonymous });
    setIsQuestionActive(false);
    setIsOnboardingActive(true);
  };

  const onCompleteOnboarding = async (canJoinSession?: boolean) => {
    if (isUpcoming) {
      await createInterest({ sessionId: room?.sessionId || "" });
      Toaster.show({
        message: "Thanks! We'll notify you prior to the session.",
      });
    } else {
      refresh(creatorUsername || "");
      if (room?.sessionId && !canJoinSession) {
        const resp = await joinSession({
          sessionId: room.sessionId,
        });
        navigate(
          generatePath(ROUTES.SESSION, {
            sessionId: resp.session.username,
          })
        );
      }
    }
    setIsOnboardingActive(false);
  };

  const onJoin = async () => {
    if (canJoin && inSession) {
      navigate(
        generatePath(ROUTES.SESSION, {
          sessionId: creatorUsername || "",
        })
      );
    } else if (!room?.inProgress && sessionDate < now) {
      // Do nothing
    } else if (isUpcoming) {
      if (currentUser) {
        await createInterest({ sessionId: room?.sessionId || "" });
        Toaster.show({
          message: "Thanks! We'll notify you prior to the session.",
        });
      } else {
        setIsOnboardingActive(true);
      }
    } else {
      trackEvent("Landing page: Tap Continue to join", {
        creator: creatorUsername,
      });
      setIsQuestionActive(true);
    }
  };

  const topic = room?.sessionTopic;

  const customRenderItem = (
    item: React.ReactNode,
    props?: {
      isSelected: boolean;
      isPrevious: boolean;
    }
  ) => {
    const i = item as React.ReactElement;
    return (
      <div className="flex h-full">
        <i.type {...i.props} {...props} />
      </div>
    );
  };

  return (
    <>
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop
        className="flex flex-1"
        centerMode
        centerSlidePercentage={90}
        swipeScrollTolerance={90}
        verticalSwipe="standard"
        axis="horizontal"
        renderIndicator={(_, isSelected) => (
          <div
            style={{ transition: "opacity .25s ease-in;" }}
            className={clsx(
              "h-[6px] w-[6px] inline-block rounded-full mx-1",
              isSelected ? "bg-black" : "bg-[#ccc] opacity-[0.3]"
            )}
          />
        )}
        renderItem={customRenderItem}
        renderArrowNext={(clickHandler, hasNext) => {
          return (
            hasNext && (
              <button
                onClick={clickHandler}
                className="absolute right-2 bg-[#00C947] z-10 h-[32px] w-[32px] border-[1px] border-black rounded-full flex items-center justify-center"
                style={{ top: "calc(50% - 35px)" }}
              >
                <ChevronIcon direction="right" />
              </button>
            )
          );
        }}
        renderArrowPrev={(clickHandler, hasPrev) => {
          return (
            hasPrev && (
              <button
                onClick={clickHandler}
                className="absolute left-2 bg-[#00C947] z-10 h-[32px] w-[32px] border-[1px] border-black rounded-full flex items-center justify-center"
                style={{ top: "calc(50% - 35px)" }}
              >
                <ChevronIcon direction="left" />
              </button>
            )
          );
        }}
      >
        <CarouselItem heading="Your favorite people responding to you in live audio sessions">
          <RoomHeader
            creatorUsername={creatorUsername}
            creatorThumbnailUrl={room?.creatorThumbnailUrl}
            inSession={inSession}
          />
          <RoomBody
            inSession={inSession}
            sessionDate={sessionDate}
            topic={room?.sessionTopic}
            canJoin={!!canJoin}
            isDisabled={
              !!session?.session.ownerId &&
              currentUser?.id === session.session.ownerId
            }
            isUpcoming={isUpcoming}
            onJoin={onJoin}
          />
        </CarouselItem>
        <CarouselItem
          heading="Chatting to the host is optional. You can join the session just to listen!"
          videoSrc={preview1}
        />
      </Carousel>
      <div className="text-[14px] text-center mb-[10px]">
        Swipe to preview what happens in a session
      </div>
      <Container
        isActive={isOnboardingActive}
        onClose={() => setIsOnboardingActive(false)}
        maxHeightClassName="max-h-[90%]"
        containerClassName={clsx(!IS_MOBILE && "px-8 py-4")}
      >
        <Onboarding
          showTerms
          isForSession
          isForUpcomingSession={isUpcoming}
          sessionId={room?.sessionId}
          promptResponse={question}
          onCompleteOnboarding={onCompleteOnboarding}
          creatorUsername={creatorUsername}
          creatorThumbnail={_room.room?.creatorThumbnailUrl}
        />
      </Container>
      <QuestionModal
        isActive={isQuestionActive}
        onClose={() => setIsQuestionActive(false)}
        topic={topic}
        onSubmit={onSubmitQuestion}
        creatorUsername={creatorUsername}
        creatorThumbnailUrl={room?.creatorThumbnailUrl}
      />
      <TipModal
        isActive={isTipActive}
        onClose={() => setIsTipActive(false)}
        username={creatorUsername}
        thumbnail={room?.creatorThumbnailUrl}
        sessionId={room?.sessionId}
        currentUserId={currentUser?.id}
      />
    </>
  );
}
