import { IconProps } from ".";

export default function ProfileIcon(props: IconProps) {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_396_4049)">
        <path
          d="M13.9628 0L9.8705 25H13.6363L17.7286 0H13.9628Z"
          fill="black"
        />
        <path
          d="M26.2752 5.6948L0.00102615 16.0127L1.3594 19.4718L27.6336 9.15386L26.2752 5.6948Z"
          fill="black"
        />
        <path
          d="M4.67301 2.12898L2.23153 4.93066L22.7993 22.8541L25.2408 20.0524L4.67301 2.12898Z"
          fill="black"
        />
        <path
          d="M20.9887 6.30664H7.02477V18.8066H20.9887V6.30664Z"
          fill="white"
        />
        <path
          d="M21.8806 15.6766L22.6464 10.9953C23.1836 7.71147 20.6498 4.72949 17.3232 4.72949H11.4786C8.83559 4.72949 6.58221 6.64391 6.1554 9.25314L5.38964 13.9344C4.85248 17.2182 7.38626 20.2002 10.7128 20.2002H16.5574C19.2004 20.2002 21.4538 18.2858 21.8806 15.6766Z"
          fill="white"
        />
        <path
          d="M16.5563 22.3398H10.7117C8.49662 22.3398 6.40428 21.3714 4.97072 19.6844C3.53604 17.9964 2.91892 15.7745 3.27703 13.5887L4.04392 8.90741C4.64302 5.2475 7.77027 2.58984 11.4786 2.58984H17.3232C19.5383 2.58984 21.6306 3.55831 23.0653 5.24525C24.5 6.93219 25.1171 9.15516 24.759 11.3398L23.9932 16.0212C23.3941 19.6811 20.2669 22.3387 16.5586 22.3387L16.5563 22.3398ZM11.4786 6.86912C9.87613 6.86912 8.5259 8.01665 8.26689 9.59773L7.50113 14.279C7.34685 15.2227 7.61374 16.1833 8.23311 16.9119C8.85248 17.6405 9.75676 18.0594 10.714 18.0594H16.5586C18.161 18.0594 19.5113 16.9119 19.7703 15.3308L20.536 10.6495C20.6903 9.70471 20.4234 8.74525 19.8041 8.01665C19.1847 7.28692 18.2804 6.86912 17.3232 6.86912H11.4786Z"
          fill="black"
        />
        <path
          d="M13.7815 14.9598C15.1498 14.9598 16.259 13.8506 16.259 12.4824C16.259 11.1141 15.1498 10.0049 13.7815 10.0049C12.4133 10.0049 11.3041 11.1141 11.3041 12.4824C11.3041 13.8506 12.4133 14.9598 13.7815 14.9598Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_396_4049">
          <rect width="27.6329" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
