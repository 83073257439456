import { useEffect, useState } from "react";
import SessionItem from "./components/SessionItem";
import CreateSessionDrawer from "./components/CreateSessionDrawer";
import {
  GetAllSessionsResponse,
  Session,
  getRoom,
  getSession,
} from "../../api";
import NavBar from "../../components/NavBar";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../state/user";
import { ROUTES } from "../../routes";
import { useDaily } from "@daily-co/daily-react";
import BaseButton from "../../components/BaseButton";
import { useSession } from "../../state/session";
import Spinner from "../../components/Spinner";

const EmptySessionState = require("./EmptySessionState.png");

export default function AllSessions() {
  const navigate = useNavigate();
  const location = useLocation();
  const daily = useDaily();

  const [isCreateSessionActive, setIsCreateSessionActive] =
    useState<boolean>(false);
  const [sessions, setSessions] = useState<GetAllSessionsResponse | undefined>(
    undefined
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [session, setSession] = useState<Session>();

  const currentUser = useRecoilValue(currentUserState);

  const isCreator = currentUser?.isCreator;

  const onBackPress = () => {
    const sessionId = location.state?.fromSessionId;

    if (sessionId) {
      if (location.state.fromRoom) {
        navigate(
          generatePath(ROUTES.ROOM, {
            username: sessionId,
          })
        );
      } else {
        navigate(
          generatePath(ROUTES.SESSION, {
            sessionId,
          })
        );
      }
    } else if (isCreator && false) {
      // navigate to blank session page
    } else {
      navigate(ROUTES.SETTINGS);
    }
  };

  useEffect(() => {
    NavBar.setNavigation({
      title: <div className="text-[24px] font-medium">All Sessions</div>,
      rightIcon: (
        <button onClick={onBackPress}>
          <ChevronIcon direction="right" />
        </button>
      ),
      inSessionHeader:
        daily?.meetingState() === "joined-meeting"
          ? { muted: !daily?.localAudio() }
          : undefined,
    });
  }, [daily]);

  useEffect(() => {
    if (currentUser?.username) {
      setIsLoading(true);
      getSession({ username: currentUser.username })
        .then((res) => {
          if (res.canJoin) {
            setSession(res.session);
          }
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [currentUser?.username]);

  return (
    <div className="relative flex-1">
      <div className="mx-6">
        {!!session && (
          <SessionItem
            name={session.title}
            sessionDate={session.startedAt?.Time || new Date()}
            thumbnailUrl={session.thumbnailUrl}
            sessionId={session.id}
            username={session.username}
          />
        )}
        {!!sessions &&
          sessions.ongoing.map((s) => (
            <SessionItem
              name={s.sessionTopic}
              sessionDate={s.sessionDate}
              thumbnailUrl={s.creatorThumbnailUrl}
              sessionId={s.sessionId}
              username={s.creatorUsername}
            />
          ))}

        {!!sessions &&
          sessions.future.map((s) => (
            <SessionItem
              name={s.sessionTopic}
              sessionDate={s.sessionDate}
              thumbnailUrl={s.creatorThumbnailUrl}
              sessionId={s.sessionId}
              username={s.creatorUsername}
            />
          ))}
        {!!sessions &&
          sessions.previous.map((s) => (
            <SessionItem
              name={s.sessionTopic}
              sessionDate={s.sessionDate}
              thumbnailUrl={s.creatorThumbnailUrl}
              sessionId={s.sessionId}
              username={s.creatorUsername}
            />
          ))}

        {!session && (
          <div className="mt-[150px] flex flex-col items-center">
            {isLoading ? (
              <Spinner className="w-[40px]" />
            ) : (
              <>
                <img src={EmptySessionState} height={107} width={148} />
                <div className="text-[16px] text-[#aaa] mt-[20px]">
                  No live sessions currently
                </div>
              </>
            )}
          </div>
        )}

        {isCreator && (
          <BaseButton
            onClick={() => setIsCreateSessionActive(true)}
            className="m-6 absolute left-0 bottom-0 right-0 !w-auto"
            isDisabled={isLoading || !!session}
          >
            Start New Session
          </BaseButton>
        )}
      </div>
      <CreateSessionDrawer
        isActive={isCreateSessionActive}
        onClose={() => setIsCreateSessionActive(false)}
      />
    </div>
  );
}
