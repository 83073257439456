import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import { useEffect, useState } from "react";
import { GetEarningsResponse, getEarnings } from "../../api";
import { formatCurrency } from "../../utils/formatCurrency";
import { useDaily } from "@daily-co/daily-react";

const EarningsEmptyState = require("./EarningsEmptyState.png");

export default function Earnings() {
  const [earnings, setEarnings] = useState<GetEarningsResponse>();
  const daily = useDaily();
  const navigate = useNavigate();
  const onBackPress = () => {
    navigate(-1);
  };

  useEffect(() => {
    getEarnings().then((r) => setEarnings(r));
  }, []);

  useEffect(() => {
    NavBar.setNavigation({
      leftIcon: (
        <button onClick={onBackPress}>
          <ChevronIcon direction="left" />
        </button>
      ),
      title: <div className="text-[20px] font-medium">Earnings</div>,
      inSessionHeader:
        daily?.meetingState() === "joined-meeting"
          ? { muted: !daily?.localAudio() }
          : undefined,
    });
  }, [daily]);

  return (
    <div
      className="mx-6 my-4 overflow-auto hide-scrollbar
    "
    >
      {(earnings?.earnings.length || 0) > 0 && (
        <div className="flex flex-col items-center justify-center bg-[#00C947] w-full  rounded-[15px] border-[1px] border-black h-[136px] py-[30px]">
          <div className="font-medium text-[32px]">
            US{formatCurrency(earnings?.totalEarnings || 0)}
          </div>
          <div className="font-medium text-[16px] mt-2">All time</div>
        </div>
      )}
      {earnings?.earnings.map((e) => (
        <div
          key={e.date.toString()}
          className="flex flex-col justify-between border-[0.5px] border-[#ccc] w-full h-[136px] rounded-[25px] mt-4 p-4"
        >
          <div className="text-[16px]">
            {new Date(e.date).toLocaleDateString()} Session
          </div>
          <div className="font-medium text-[24px]">
            US{formatCurrency(e.sessionEarnings || 0)}
          </div>
          <div className="text-[14px] text-[#aaa]">
            {e.participants} participant{e.participants > 1 ? "s" : ""}
          </div>
        </div>
      ))}
      {(earnings?.earnings.length || 0) === 0 && (
        <div className="mt-[100px] flex flex-col items-center">
          <img src={EarningsEmptyState} height={107} width={148} />
          <div className="text-[#aaa] text-[16px] mt-[22px]">
            No earnings yet
          </div>
        </div>
      )}
    </div>
  );
}
