import { NavigateFunction } from "react-router-dom";
import ChevronIcon from "../../../components/Icons/ChevronIcon";

export interface SettingsOptionProps {
  label: string;
  route: string;
  isExternalLink?: boolean;
  navigate?: NavigateFunction;
}

export default function SettingsOption({
  label,
  route,
  isExternalLink,
  navigate,
}: SettingsOptionProps) {
  const onClick = () => {
    if (isExternalLink) {
      window.location.href = route;
    } else {
      navigate?.(route);
    }
  };

  return (
    <button
      className="flex border-b-[0.5px] border-[#ccc] w-full pt-[20px] pb-[19px] px-6"
      onClick={onClick}
    >
      <div className="flex-1 text-start text-[15px]">{label}</div>
      <ChevronIcon direction="right" />
    </button>
  );
}
