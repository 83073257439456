import { useCallback, useEffect, useRef } from "react";

const useSafeSetState = () => {
  const isMounted = useRef(true);

  const safeSetState = useCallback((cb?: () => void) => {
    if (isMounted.current) {
      cb && cb();
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return safeSetState;
};

export default useSafeSetState;
