import * as amplitude from "@amplitude/analytics-browser";
import { User } from "../state/user";

export type EventName =
  | "Landing page: Visit while not in session"
  | "Landing page: Visit while in session"
  | "Landing page: Tap Continue to join"
  | "Landing page: Tap Join Just To Listen"
  | "Landing page: Submit Response"
  | "Landing page: Tap Notify Me Of Next Session"
  | "Landing page: Tap Sign Up"
  | "Onboarding: Enter Phone Number"
  | "Onboarding: Enter Verification Code"
  | "Onboarding: Add display picture & username"
  | "Onboarding: Enable microphone permissions"
  | "Onboarding: Submit payment"
  | "Creator: Start New Session"
  | "Creator: Spotlight Participant"
  | "Creator: End Spotlight"
  | "Creator: End Session"
  | "Chat: Send Message"
  | "Participant: Accept Spotlight Request"
  | "Participant: Decline Spotlight Request"
  | "Participant: Leave Session"
  | "Participant: End Spotlight";

export const initializeAnalytics = (userId?: string) => {
  const amplitudeKey = "37f393e7a5b719c057cd7c85f12755a5";
  if (amplitudeKey) {
    amplitude.init(amplitudeKey, userId, {
      defaultTracking: {
        pageViews: false,
      },
    });
  }
};

export const identifyUser = async (user: User) => {
  // if (ENV.ENVIRONMENT !== "prod") return;

  amplitude.setUserId(user.id);
  const identifyEvent = new amplitude.Identify();

  Object.entries(user).map(([k, v]) => {
    identifyEvent.set(k, v);
  });

  amplitude.identify(identifyEvent);
};

export const resetAnalytics = () => {
  // if (ENV.ENVIRONMENT !== "prod") return;

  amplitude.reset();
};

export const trackEvent = (eventName: EventName, traits?: any) => {
  // if (ENV.ENVIRONMENT !== "prod") return;

  try {
    amplitude.track(eventName, traits);
  } catch (er) {
    console.error(er);
  }
};
