import { IconProps } from "../Icons";

export default function EmptyThumbnail(props: IconProps) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="50" cy="50" r="19.5" fill="#FFC2FE" stroke="black" />
      <g clip-path="url(#clip0_520_2244)">
        <path
          d="M49.1258 61.9997C51.6089 61.9997 53.6218 59.9868 53.6218 57.5038C53.6218 55.0207 51.6089 53.0078 49.1258 53.0078C46.6428 53.0078 44.6299 55.0207 44.6299 57.5038C44.6299 59.9868 46.6428 61.9997 49.1258 61.9997Z"
          fill="black"
        />
        <path
          d="M65.5794 31.3022C64.1195 28.3175 61.5042 26.8346 58.3315 26.265C57.3682 26.0932 56.3764 26.0134 55.3969 26.0107C52.7085 25.9998 44.5758 25.9985 44.0535 26.0012C44.0535 26.0012 40.3653 26.0147 40 26.0188L41.1203 32.5807C41.15 32.5794 56.6781 32.5753 56.6781 32.5753C57.5305 32.5753 58.0704 32.9866 58.2706 33.705C58.4992 34.5195 58.207 35.1852 57.4385 35.6547C56.7038 36.1052 55.9665 36.5517 55.2278 36.9968C53.477 38.0562 51.7235 39.1142 50.0147 40.2372C47.3399 41.9947 46.8961 45.0241 46.4902 47.3241C46.4645 47.4729 45.8922 50.555 45.8922 50.7066H53.8369C53.9 50.1654 54.029 49.4519 54.2238 48.5661C54.4863 47.3715 54.5256 46.2688 55.6093 45.5815C56.6673 44.9131 57.7416 44.2677 58.8145 43.6224C60.0146 42.9012 61.216 42.1801 62.3931 41.4265C63.3727 40.8001 64.3942 40.1087 65.1058 39.2117C67.0704 36.7371 66.8918 33.9811 65.5807 31.2995L65.5794 31.3022Z"
          fill="black"
        />
      </g>
      <circle
        opacity="0.6"
        cx="50"
        cy="50"
        r="49.5"
        stroke="#AAAAAA"
        stroke-dasharray="6 6"
      />
      <defs>
        <clipPath id="clip0_520_2244">
          <rect
            width="26.5725"
            height="36"
            fill="white"
            transform="translate(40 26)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
