import { useDaily } from "@daily-co/daily-react";
import EndIcon from "../../../components/Icons/EndIcon";
import MuteIcon from "../../../components/Icons/UnmutedIcon";
import MutedIcon from "../../../components/Icons/MutedIcon";
import UnmutedIcon from "../../../components/Icons/UnmutedIcon";
import { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import clsx from "clsx";
import BaseButton from "../../../components/BaseButton";
import { trackEvent } from "../../../utils/analytics";
import { removePin } from "../../../api";

const LeaveIcon = require("./LeaveSessionIcon.png");

interface Props {
  hasResponse?: boolean;
  isAnonymousResponse?: boolean;
  hostUsername?: string;
  isSpotlighted?: boolean;
  sessionId: string;
  onRemovePin?: () => void;
}

export default function GuestControls({
  hasResponse,
  hostUsername,
  isSpotlighted,
  sessionId,
  isAnonymousResponse,
  onRemovePin,
}: Props) {
  const navigate = useNavigate();
  const daily = useDaily();

  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isLeaveSessionModalActive, setIsLeaveSessionModalActive] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsMuted(!daily?.localAudio());
  }, [daily]);

  const toggleAudio = () => {
    daily?.updateParticipant("local", { setAudio: isMuted });
    setIsMuted(!isMuted);
  };

  const onLeaveSession = async () => {
    trackEvent("Participant: Leave Session");
    daily?.leave();
    if (isSpotlighted) {
      setIsLoading(true);
      await removePin({ sessionId });
      setIsLoading(false);
    }
    navigate(
      generatePath(ROUTES.ROOM, {
        username: hostUsername || "",
      })
    );
  };

  return (
    <>
      {isSpotlighted ? (
        <>
          <button onClick={toggleAudio}>
            <div
              className="flex items-center justify-center h-[48px] w-[48px] rounded-full"
              style={{
                boxShadow: "0px 0px 8px 0px #00000026",
              }}
            >
              {isMuted ? <MutedIcon /> : <UnmutedIcon />}
            </div>
          </button>
          <button
            className="h-[48px] rounded-[24px] text-[16px] font-medium flex-1 mx-3 font-medium"
            style={{ boxShadow: "0px 0px 8px 0px #00000026" }}
            onClick={() => isSpotlighted && onRemovePin?.()}
          >
            End Your Spotlight
          </button>
        </>
      ) : (
        <>
          <div
            className="h-[48px] w-[48px] rounded-full flex items-center justify-center"
            style={{ boxShadow: "0px 0px 8px 0px #00000026" }}
          >
            <MutedIcon />
          </div>
          <div className="text-[14px] flex-1 mx-2 text-[#aaa]">
            {isAnonymousResponse
              ? "Your microphone is off"
              : "Your microphone is off until you get spotlighted"}
          </div>
        </>
      )}
      <button
        onClick={() => setIsLeaveSessionModalActive(true)}
        className="h-[48px] w-[48px] rounded-full flex items-center justify-center"
        style={{ boxShadow: "0px 0px 8px 0px #00000026" }}
      >
        <EndIcon />
      </button>
      <Modal isActive={isLeaveSessionModalActive}>
        <div className="flex flex-col items-center py-4 px-6">
          <img src={LeaveIcon} height={100} width={100} className="mt-8" />

          <div className="text-[28px] mt-6 text-center leading-[32px]">
            Sure you want to leave this live session?
          </div>
          <BaseButton
            onClick={onLeaveSession}
            className="my-4 mt-[96px]"
            isDisabled={isLoading}
          >
            Confirm & Leave Session
          </BaseButton>
          <button
            className="text-[#00C947] font-medium"
            onClick={() => setIsLeaveSessionModalActive(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}
