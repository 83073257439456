import EmptyThumbnail from "../../../components/EmptyThumbnail";
import UnmutedIcon from "../../../components/Icons/UnmutedIcon";
import UserThumbnail from "../../../components/UserThumbnail";

interface Props {
  creatorUsername?: string;
  creatorThumbnailUrl?: string;
  inSession?: boolean;
}
export default function RoomHeader({
  creatorUsername,
  creatorThumbnailUrl,
  inSession,
}: Props) {
  return (
    <>
      <div className="flex mt-[40px]">
        <div className="w-[170px] flex flex-col items-center">
          <div className="flex items-center justify-center relative">
            <UserThumbnail
              name={creatorUsername}
              thumbnailUrl={creatorThumbnailUrl}
              borderColor={inSession ? "#00C947" : "#fff"}
            />
            <div className="absolute ml-[68px] mt-[68px] h-[32px] w-[32px] bg-white border-[1px] border-black rounded-full flex items-center justify-center">
              <UnmutedIcon />
            </div>
          </div>
          <div className="text-center text-[14px] mt-2 font-medium">
            {creatorUsername}
          </div>
        </div>
        <div className="w-[170px] flex flex-col items-center">
          <div className="flex items-center justify-center relative flex-1">
            <EmptyThumbnail />
            <div className="absolute ml-[68px] mt-[68px] h-[32px] w-[32px] bg-white border-[1px] border-black rounded-full flex items-center justify-center">
              <UnmutedIcon />
            </div>
          </div>
          <div className="text-center text-[14px] mt-2 font-medium text-[#aaa]">
            Spotlighted Person
          </div>
        </div>
      </div>
      <div className="flex mt-4 ">
        <div className="w-[50%] text-[14px] font-medium border-b-[1px] border-black pt-[10px] pb-[13px]">
          Session Group Chat
        </div>
        <div className="w-[50%] text-[14px] font-medium text-[#aaa] pt-[10px] pb-[13px]">
          Guest Controls
        </div>
      </div>
    </>
  );
}
