import React from "react";
import { Transition } from "react-transition-group";
import { createPortal } from "react-dom";
import { modalDuration, ModalBackground } from "../Modal";
import clsx from "clsx";
import ChevronIcon from "../Icons/ChevronIcon";
import XIcon from "../Icons/XIcon";

const duration = 300;

interface Props {
  isActive: boolean;
  children?: React.ReactNode;
  onClose?: () => any;
  onGoBack?: () => void;
  onEntered?: () => void;
  onExited?: () => void;
  zIndex?: number;
  hasCustomWrapper?: boolean;
  unmountOnExit?: boolean;
  containerClassName?: string;
  hideControls?: boolean;
  maxHeightClassName?: string;
}

const transitionStyle = {
  entering: { transform: "translate3d(0, 100%, 0)" },
  entered: { transform: "translate3d(0, 0, 0)" },
  exiting: { transform: "translate3d(0, 100%, 0)" },
  exited: {},
  unmounted: {},
};

export default function Drawer({
  isActive,
  children,
  onClose,
  onGoBack,
  onExited,
  onEntered,
  unmountOnExit = true,
  hasCustomWrapper = false,
  hideControls = false,
  containerClassName,
  maxHeightClassName,
}: Props) {
  const shouldRenderControls = !hideControls && (onGoBack || onClose);
  const controlClassName =
    "flex items-center justify-center text-black bg-light-gray rounded-xl pointer-events-auto actionable cursor-pointer";

  return (
    <>
      {createPortal(
        <Transition
          in={isActive}
          mountOnEnter
          onExited={onExited}
          onEntered={onEntered}
          unmountOnExit={unmountOnExit}
          timeout={{
            appear: 0,
            enter: 16,
            exit: modalDuration,
          }}
        >
          {(state) => (
            <div
              className="fixed top-0 left-0 right-0 bottom-0 z-10"
              data-testid="drawer"
            >
              <ModalBackground state={state} onClose={onClose} />
              {children ? (
                !hasCustomWrapper ? (
                  <div
                    className="absolute top-0 right-0 left-0 bottom-0 flex flex-col justify-end pointer-events-none h-full overflow-hidden will-change-transform"
                    style={{
                      transform: "translate3d(0, 100%, 0)",
                      transition: `transform ${duration}ms`,
                      ...transitionStyle[state],
                    }}
                  >
                    <div
                      className={clsx(
                        "flex relative",
                        maxHeightClassName ?? "max-h-[75%]"
                      )}
                    >
                      <div
                        className={clsx(
                          "flex flex-col w-full relative rounded-t-[40px] bg-white overflow-y-scroll hide-scrollbar pointer-events-auto px-6",
                          shouldRenderControls && "pt-[64px]",
                          containerClassName
                        )}
                      >
                        <div className="w-full flex flex-col flex-grow">
                          {children}
                        </div>
                      </div>
                      {shouldRenderControls && (
                        <div
                          className="absolute left-0 right-0 top-0 flex flex-row pointer-events-none p-6"
                          style={{ transform: "translateZ(0)" }}
                        >
                          {onGoBack && (
                            <div
                              className={controlClassName}
                              onClick={onGoBack}
                            >
                              <ChevronIcon direction="left" />
                            </div>
                          )}
                          <div className="flex flex-grow" />
                          {onClose ? (
                            <div className={controlClassName} onClick={onClose}>
                              <XIcon />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                ) : typeof children === "function" ? (
                  (children as Function)(state)
                ) : state === "entered" ? (
                  children
                ) : null
              ) : null}
            </div>
          )}
        </Transition>,
        document.body
      )}
    </>
  );
}
