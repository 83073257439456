import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import { ChangeEvent, useState } from "react";
import CountryCodeSelector from "./CountryCodeSelector";
import axios from "axios";
import { submitPhoneNumber } from "../../../api";
import clsx from "clsx";
import BaseButton from "../../../components/BaseButton";
import { TERMS_LINK, PRIVACY_POLICY_LINK } from "../../../utils/constants";
import Toaster from "../../../components/Toaster";

interface Props {
  onSubmit: (phone: string, token: string) => void;
  showTerms?: boolean;
  isForUpcomingSession?: boolean;
}
export default function PhoneNumber({
  onSubmit,
  showTerms,
  isForUpcomingSession,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCode>("US");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const disabled = !phoneNumber;

  const onClick = async (e?: any) => {
    setIsLoading(true);
    e.preventDefault();
    const parsedPhone = parsePhoneNumber(phoneNumber, countryCode);
    const phone = parsedPhone.formatInternational().replaceAll(" ", "");
    submitPhoneNumber({ phone })
      .then((res) => {
        onSubmit(phone, res.verificationToken);
      })
      .catch(() => Toaster.showGenericError())
      .finally(() => setIsLoading(false));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value) {
      value = value.replace(/[^0-9]/, "");
    }

    setPhoneNumber(value);
  };

  return (
    <>
      <div className="text-[28px] mt-4">
        Sign in to {isForUpcomingSession ? "register interest" : "join session"}
      </div>
      <div className="relative flex mt-8 border-[0.5px] border-[#aaa] w-full p-5 rounded-[20px]">
        <div
          className="absolute top-0 left-[50%] bg-white text-[13px] text-[#aaa] px-2"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          With phone number
        </div>
        <div className="border-r-[0.5px] border-[#aaa] pr-3">
          <CountryCodeSelector
            value={countryCode}
            onSelect={(c: CountryCode) => setCountryCode(c)}
          />
        </div>
        <form onSubmit={onClick} className="w-full">
          <input
            className="pl-4 outline-none h-full w-full text-[20px]"
            placeholder="Enter number"
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={onChange}
          />
        </form>
      </div>
      <div className="mt-[95px]" />
      {showTerms && (
        <div className=" text-[12px] text-[#aaa] text-center">
          By clicking ‘Continue to {isForUpcomingSession ? "register" : "join"}’
          you agree to Sessions’{" "}
          <a className="underline" href={TERMS_LINK} target="_blank">
            Terms
          </a>{" "}
          and our{" "}
          <a className="underline" href={PRIVACY_POLICY_LINK} target="_blank">
            Privacy Policy
          </a>
          .
        </div>
      )}
      <BaseButton
        onClick={onClick}
        className="my-4"
        isDisabled={disabled || isLoading}
        isLoading={isLoading}
      >
        Continue to {isForUpcomingSession ? "register" : "join"}
      </BaseButton>
    </>
  );
}
