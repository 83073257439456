import { useCallback, useEffect, useRef, useState } from "react";
import UserThumbnail from "../../components/UserThumbnail";
import EmptyThumbnail from "../../components/EmptyThumbnail";
import clsx from "clsx";
import SampleSpotlightIcon from "../../components/Icons/SampleSpotlightIcon";
import PinIcon from "../../components/Icons/PinIcon";
import ChatTab from "./components/ChatTab";
import ControlsTab from "./components/ControlsTab";
import useWebSocket from "react-use-websocket";
import { getAuthToken, getSession, updateParticipantId } from "../../api";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  audioStream,
  chatState,
  MessageType,
  sendPinState,
  SocketMessage,
} from "../../state/chat";
import { currentUserState } from "../../state/user";
import { useDaily, useLocalSessionId } from "@daily-co/daily-react";
import { useSession } from "../../state/session";
import { useResponses } from "../../state/response";
import Modal from "../../components/Modal";
import { ROUTES } from "../../routes";
import NavBar from "../../components/NavBar";
import ProfileIcon from "../../components/Icons/ProfileIcon";
import HomeIcon from "../../components/Icons/HomeIcon";
import Microphone from "../Onboarding/components/Microphone";
import SendPinModal from "./components/SendPinModal";
import ReceivePinModal from "./components/ReceivePinModal";
import {
  DailyEventObjectLocalAudioLevel,
  DailyEventObjectParticipant,
  DailyEventObjectRemoteParticipantsAudioLevel,
} from "@daily-co/daily-js";
import MutedIcon from "../../components/Icons/MutedIcon";
import UnmutedIcon from "../../components/Icons/UnmutedIcon";
import { trackEvent } from "../../utils/analytics";
import TipModal from "../Room/components/TipModal";

// TODO: Clean this up

export default function Session() {
  const { sessionId: hostUsername } = useParams<{ sessionId: string }>();

  const navigate = useNavigate();
  const daily = useDaily();
  const localSessionId = useLocalSessionId();

  const currentUser = useRecoilValue(currentUserState);
  const setMessages = useSetRecoilState(chatState);

  const hasTipped = useRef<boolean>(false);
  const [activeTab, setActiveTab] = useState<"chat" | "controls">("chat");
  const [isHostMuted, setIsHostMuted] = useState<boolean>(false);
  const [isPinnedUserMuted, setIsPinnedUserMuted] = useState<boolean>(false);
  const [isTipModalActive, setIsTipModalActive] = useState<boolean>(false);

  const [isHostTalking, setIsHostTalking] = useState<boolean>(false);
  const [isPinnedUserTalking, setIsPinnedUserTalking] =
    useState<boolean>(false);

  const localStream = useRecoilValue(audioStream);

  // Host Functionality
  const [potenetialPinnedUser, setPotentialPinnedUser] = useState<string>("");
  const [pinTimer, setPinTimer] = useRecoilState(sendPinState);
  const pinTimerRef = useRef<NodeJS.Timer>();

  // Guest Functionality
  const [isPinPromptActive, setIsPinPromptActive] = useState<boolean>(false);

  const [potentialPin, setPotentialPin] = useState<string>("");
  const [showMicrophonePermissionsDrawer, setShowMicrophonePermissionsDrawer] =
    useState<boolean>(false);
  const [sessionDuration, setSessionDuration] = useState<number>(0);

  const { session, setSession, isLoading, refresh } = useSession(
    hostUsername || ""
  );

  const isHost =
    !!currentUser?.id && currentUser?.id === session?.session.ownerId;
  const sessionId = session?.session?.id || "";

  const { responses, setResponses } = useResponses(
    sessionId,
    isHost,
    activeTab
  );

  const { readyState, sendJsonMessage } = useWebSocket<SocketMessage>(
    `wss://api.insessions.co/ws?token=${getAuthToken()}&sessionId=${sessionId}`,
    {
      reconnectInterval: 5000,
      shouldReconnect: (event: CloseEvent) => true,
      onMessage: (e) => onMessage(e),
    },
    sessionId.length > 0
  );

  const participants = daily?.participants();
  const meetingState = daily?.meetingState();

  const hostSessionId = useCallback(() => {
    if (participants) {
      return Object.keys(participants).find((p) => participants[p].owner);
    }
  }, [participants])();

  const pinnedUserSessionId = useCallback(() => {
    if (participants && session?.pinnedMessage?.sender?.id) {
      return Object.keys(participants).find(
        (p) => participants[p].user_id === session.pinnedMessage?.sender?.id
      );
    }
  }, [participants, session?.pinnedMessage])();

  const spotlightName = session?.pinnedMessage?.sender.username;
  const spotlightTopic = session?.pinnedMessage?.message;

  const isChatFocused = activeTab === "chat";

  const onMessage = (e: MessageEvent<string>) => {
    const lastJsonMessage = JSON.parse(e.data);
    if (lastJsonMessage) {
      if (lastJsonMessage.type === MessageType.PIN) {
        if (
          !isHost &&
          responses.length === 1 &&
          responses[0]?.id === lastJsonMessage.pinnedMessageId
        ) {
          setIsPinPromptActive(true);
          setPotentialPin(lastJsonMessage.message);
        }

        if (session?.pinnedMessage) {
          if (
            !!responses[0] &&
            (session.pinnedMessage.messageId === responses[0]?.id ||
              session.pinnedMessage.pinnedMessageId === responses[0]?.id) &&
            !isHost
          ) {
            setIsTipModalActive(true);
            daily?.updateParticipant("local", { setAudio: false });
          }

          setSession((prev) => {
            if (!prev) return prev;
            return { ...prev, pinnedMessage: undefined };
          });
        }
      } else if (lastJsonMessage.type === MessageType.PIN_RESPONSE) {
        if (isHost) {
          setResponses(
            responses.map((r) => {
              if (r?.id === lastJsonMessage.pinnedMessageId) {
                const newResponse = {
                  ...r,
                  accepted: { Valid: true, Bool: true },
                };
                return newResponse;
              }
              return r;
            })
          );
        } else if (
          lastJsonMessage.pinnedMessageId === responses[0]?.id &&
          !isHost
        ) {
          setResponses((prev) => {
            if (prev[0]) {
              const updatedResponse = [
                { ...prev[0], accepted: { Valid: true, Bool: true } },
              ];
              return updatedResponse;
            }
            return [];
          });
        }

        if (pinTimerRef.current) {
          clearInterval(pinTimerRef.current);
          setPotentialPinnedUser("");
          setPinTimer(undefined);
        }

        if (lastJsonMessage?.sender?.id === currentUser?.id && !isHost) {
          daily?.updateParticipant("local", { setAudio: true });
        } else if (
          !!responses[0] &&
          (lastJsonMessage.messageId === responses[0]?.id ||
            lastJsonMessage.pinnedMessageId === responses[0]?.id) &&
          !isHost
        ) {
          setIsTipModalActive(true);
          daily?.updateParticipant("local", { setAudio: false });
        }

        setSession((prev) => {
          if (!prev) return prev;
          return { ...prev, pinnedMessage: lastJsonMessage };
        });
      } else if (lastJsonMessage.type === MessageType.REMOVE_PIN) {
        if (
          !!responses[0] &&
          (lastJsonMessage.messageId === responses[0]?.id ||
            lastJsonMessage.pinnedMessageId === responses[0]?.id) &&
          !isHost
        ) {
          setIsTipModalActive(true);
          daily?.updateParticipant("local", { setAudio: false });
        }

        setSession((prev) => {
          if (!prev) return prev;
          return { ...prev, pinnedMessage: undefined };
        });
      } else if (lastJsonMessage.type === MessageType.END_SESSION) {
        daily?.leave();
        navigate(
          generatePath(ROUTES.ROOM, {
            username: hostUsername || "",
          }),
          { state: { isFromSessionEnd: !isHost && !hasTipped.current } }
        );
      } else if (lastJsonMessage.type === MessageType.RESPONSE) {
        if (
          responses.find(
            (r) =>
              r.id === lastJsonMessage.messageId ||
              r.id === lastJsonMessage.pinnedMessageId
          )
        ) {
          setResponses((prev) =>
            prev.map((p) => {
              if (
                p.id === lastJsonMessage.pinnedMessageId ||
                p.id === lastJsonMessage.messageId
              ) {
                return { ...p, isAnonymous: lastJsonMessage.isAnonymous };
              }

              return p;
            })
          );
        } else if (isHost) {
          setResponses((prev) => [
            ...prev,
            {
              accepted: { Bool: false, Valid: false },
              amount: lastJsonMessage.amount || 0,
              id: lastJsonMessage.messageId || "",
              message: lastJsonMessage.message || "",
              userId: lastJsonMessage.sender?.id || "",
              username: lastJsonMessage.sender.username,
              isAnonymous: lastJsonMessage.isAnonymous,
            },
          ]);
        }
      } else if (lastJsonMessage.type === MessageType.SEND) {
        setMessages((prev) => [
          {
            id: lastJsonMessage.messageId || "",
            sender: lastJsonMessage.sender,
            message: lastJsonMessage.message,
            sentAt: lastJsonMessage.timestamp || "",
          },
          ...prev,
        ]);
      }
    }
  };

  const onLeftIconPress = () => {
    navigate(ROUTES.ALL_SESSIONS, {
      state: { fromSessionId: hostUsername },
    });
  };

  const onRightIconPress = () => {
    navigate(ROUTES.SETTINGS, {
      state: { fromSessionId: hostUsername },
    });
  };

  const handleParticipantUpdated = useCallback(
    (e?: DailyEventObjectParticipant) => {
      const { user_id, tracks } = e?.participant || {};
      if (user_id === session?.session.ownerId) {
        setIsHostMuted(tracks?.audio.state === "off");
      } else if (user_id === session?.pinnedMessage?.sender?.id) {
        setIsPinnedUserMuted(tracks?.audio.state === "off");
      }
    },
    [session]
  );

  const handleLocalAudio = useCallback(
    (e?: DailyEventObjectLocalAudioLevel) => {
      if (e) {
        if (hostSessionId === "local") {
          if (e.audioLevel > 0.01) {
            if (!isHostTalking) setIsHostTalking(true);
          } else {
            setIsHostTalking(false);
          }
        } else if (pinnedUserSessionId === "local") {
          if (e.audioLevel > 0.01) {
            if (!isPinnedUserTalking) setIsPinnedUserTalking(true);
          } else {
            setIsPinnedUserTalking(false);
          }
        }
      }
    },
    [hostSessionId, pinnedUserSessionId]
  );

  const handleRemoteAudio = useCallback(
    (e?: DailyEventObjectRemoteParticipantsAudioLevel) => {
      if (e) {
        if (hostSessionId && hostSessionId !== "local") {
          if (e.participantsAudioLevel[hostSessionId] > 0.002) {
            if (!isHostTalking) setIsHostTalking(true);
          } else {
            setIsHostTalking(false);
          }
        }

        if (pinnedUserSessionId && pinnedUserSessionId !== "local") {
          if (e.participantsAudioLevel[pinnedUserSessionId] > 0.002) {
            if (!isPinnedUserTalking) setIsPinnedUserTalking(true);
          } else {
            setIsPinnedUserTalking(false);
          }
        }
      }
    },
    [hostSessionId, pinnedUserSessionId]
  );

  useEffect(() => {
    NavBar.setNavigation({
      leftIcon: currentUser?.isCreator ? (
        <button onClick={onLeftIconPress}>
          <HomeIcon />
        </button>
      ) : undefined,
      rightIcon: (
        <button onClick={onRightIconPress}>
          <ProfileIcon />
        </button>
      ),
    });
  }, [currentUser]);

  useEffect(() => {
    if (hostSessionId === "local" || pinnedUserSessionId === "local") {
      daily?.on("local-audio-level", handleLocalAudio);
    }
    if (hostSessionId !== "local" || pinnedUserSessionId !== "local") {
      daily?.on("remote-participants-audio-level", handleRemoteAudio);
    }

    return () => {
      daily?.off("local-audio-level", handleLocalAudio);
      daily?.off("remote-participants-audio-level", handleRemoteAudio);
    };
  }, [hostSessionId, pinnedUserSessionId]);

  useEffect(() => {
    if (session?.hasTipped) {
      hasTipped.current = true;
    }

    if (session?.session.startedAt) {
      const minuteDuration = Math.floor(
        (new Date().getTime() -
          new Date(session.session.startedAt.Time).getTime()) /
          60000
      );
      setSessionDuration(minuteDuration);

      const interval = setInterval(() => {
        if (session.session.startedAt) {
          const minuteDuration = Math.floor(
            (new Date().getTime() -
              new Date(session.session.startedAt.Time).getTime()) /
              60000
          );
          setSessionDuration(minuteDuration);
        }
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [session]);

  useEffect(() => {
    if (daily) {
      daily.startLocalAudioLevelObserver(500);
      daily.startRemoteParticipantsAudioLevelObserver(500);
    }

    return () => {
      daily?.stopLocalAudioLevelObserver();
      daily?.stopRemoteParticipantsAudioLevelObserver();
    };
  }, [daily]);

  useEffect(() => {
    if (daily && session) {
      daily?.on("participant-updated", handleParticipantUpdated);

      if (participants) {
        const host = Object.keys(participants).find(
          (p) => participants[p].owner
        );
        const pinnedUser = Object.keys(participants).find(
          (p) => participants[p].user_id === session.pinnedMessage?.sender?.id
        );
        if (host) {
          setIsHostMuted(participants[host].tracks?.audio.state === "off");
        }
        if (pinnedUser) {
          setIsPinnedUserMuted(
            participants[pinnedUser].tracks?.audio.state === "off"
          );
        }
      }
    }

    return () => {
      daily?.off("participant-updated", handleParticipantUpdated);
    };
  }, [daily, session, participants]);

  useEffect(() => {
    refresh(hostUsername || "");

    if (!isHost) {
      // @ts-ignore
      window.navigator.permissions
        // @ts-ignore
        .query({ name: "microphone" } as PermissionDescriptor)
        .then(({ state }) => {
          if (state !== "granted") {
            setShowMicrophonePermissionsDrawer(true);
          }
        });
    }

    return () => {
      if (pinTimerRef.current) {
        clearInterval(pinTimerRef.current);
        setPotentialPinnedUser("");
      }
    };
  }, []);

  useEffect(() => {
    if (hostUsername) {
      getSession({ username: hostUsername })
        .then((res) => {
          const isUpcoming =
            new Date(res?.session?.startedAt?.Time || 0) > new Date();
          if (!res.canJoin || isUpcoming) {
            localStream?.getAudioTracks().forEach((track) => track.stop());
            navigate(
              generatePath(ROUTES.ROOM, {
                username: hostUsername || "",
              })
            );
          }
        })
        .catch(() => {
          localStream?.getAudioTracks().forEach((track) => track.stop());
          navigate(
            generatePath(ROUTES.ROOM, {
              username: hostUsername || "",
            })
          );
        });
    }
  }, [hostUsername]);

  useEffect(() => {
    const isUpcoming =
      new Date(session?.session.startedAt?.Time || 0) > new Date();
    if (
      session?.entitlementToken &&
      session.session.externalRoomName &&
      currentUser?.username &&
      daily &&
      daily.meetingState() !== "joined-meeting" &&
      session.canJoin &&
      !isUpcoming
    )
      daily?.join({
        token: session.entitlementToken,
        url: `https://sunroom.daily.co/${session?.session.externalRoomName}`,
        userName: currentUser.username,
      });
  }, [session, currentUser?.username, daily]);

  useEffect(() => {
    if (
      responses.length > 0 &&
      session?.pinnedMessage?.messageId === responses[0]?.id &&
      !isHost
    ) {
      if (meetingState === "joined-meeting")
        daily?.updateParticipant("local", { setAudio: true });
    }
  }, [session, session?.pinnedMessage, responses, meetingState]);

  useEffect(() => {
    if (localSessionId && sessionId) {
      updateParticipantId({ sessionId, participantId: localSessionId });
    }
  }, [localSessionId, sessionId]);

  useEffect(() => {
    if ((pinTimer?.timeRemaining || 0) <= 0 && pinTimerRef.current) {
      clearInterval(pinTimerRef.current);
      setPotentialPinnedUser("");
      setPinTimer(undefined);
    }
  }, [pinTimer?.timeRemaining]);

  const onSendMessage = (input: SocketMessage) => {
    trackEvent("Chat: Send Message", { isHost });
    sendJsonMessage(input);
  };

  const onPin = (messageId: string, message: string, username: string) => {
    if (daily?.participants().local.owner) {
      setPotentialPinnedUser(username);
      setPinTimer({ timeRemaining: 15, responseId: messageId });
      pinTimerRef.current = setInterval(() => {
        setPinTimer((prev) => {
          if (prev) {
            return {
              timeRemaining: prev.timeRemaining - 1,
              responseId: prev.responseId,
            };
          } else {
            if (pinTimerRef.current) {
              clearInterval(pinTimerRef.current);
            }
          }
        });
      }, 1000);

      trackEvent("Creator: Spotlight Participant", { messageId });
      sendJsonMessage({
        type: MessageType.PIN,
        sender: {
          id: currentUser?.id,
          username: currentUser?.username,
        },
        pinnedMessageId: messageId,
        roomId: sessionId,
        message,
      });
    }
  };

  const onAcceptPin = () => {
    trackEvent("Participant: Accept Spotlight Request");
    sendJsonMessage({
      type: MessageType.PIN_RESPONSE,
      sender: {
        id: currentUser?.id,
        username: currentUser?.username,
      },
      pinResponse: true,
      roomId: sessionId,
      roomName: session?.session.externalRoomName,
      message: potentialPin,
      pinnedMessageId: responses[0]?.id,
    });
    setIsPinPromptActive(false);
    setPotentialPin("");
  };

  const onRemovePin = () => {
    sendJsonMessage({
      type: MessageType.REMOVE_PIN,
      sender: {
        id: currentUser?.id,
        username: currentUser?.username,
      },
      roomName: session?.session.externalRoomName,
      roomId: sessionId,
      pinnedMessageId:
        session?.pinnedMessage?.pinnedMessageId ||
        session?.pinnedMessage?.messageId,
    });

    if (isHost) {
      trackEvent("Creator: End Spotlight", {
        messageId: session?.pinnedMessage?.pinnedMessageId,
      });
    } else {
      trackEvent("Participant: End Spotlight", {
        messageId: session?.pinnedMessage?.pinnedMessageId,
      });
    }
  };

  const onEndSession = () => {
    trackEvent("Creator: End Session");
    sendJsonMessage({
      type: MessageType.END_SESSION,
      sender: {
        id: currentUser?.id,
        username: currentUser?.username,
      },
      roomId: sessionId,
    });
  };

  const onTipModalClose = (tipped?: boolean) => {
    if (tipped) {
      hasTipped.current = true;
    }

    setIsTipModalActive(false);
  };

  if (!sessionId) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex items-center">
          <div className="rounded-full bg-[#00C947] w-[12px] h-[12px]" />
          <div className="font-medium text-[13px] ml-[6px]">In session</div>
          <div className="rounded-full bg-[#aaa] w-[2px] h-[2px] mx-[6px]" />
          <div className="text-[#aaa] text-[11px]">
            {sessionDuration} min{sessionDuration > 1 ? "s" : ""}
          </div>
        </div>
        <div className="flex mt-[15px] items-center">
          <div className="w-[170px] flex flex-col items-center">
            <div className="flex items-center justify-center h-[105px] relative">
              <UserThumbnail
                borderColor={isHostTalking && !isHostMuted ? "#00C947" : ""}
                name={session?.session.username}
                thumbnailUrl={session?.session.thumbnailUrl}
              />
              <div className="absolute ml-[68px] mt-[68px] h-[32px] w-[32px] bg-white border-[1px] border-black rounded-full flex items-center justify-center">
                {isHostMuted ? <MutedIcon /> : <UnmutedIcon />}
              </div>
            </div>
            <div className="font-medium text-[16px] mt-3">
              {session?.session.username}
            </div>
          </div>
          <div className="w-[170px] flex flex-col items-center">
            {!spotlightName ? (
              <>
                <EmptyThumbnail />
                <div className="text-[16px] font-medium text-[#aaa] italic mt-3">
                  Spotlight is empty
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center justify-center h-[105px] relative">
                  <UserThumbnail
                    borderColor={
                      isPinnedUserTalking && !isPinnedUserMuted ? "#00C947" : ""
                    }
                    name={spotlightName}
                    thumbnailUrl={session?.pinnedMessage?.sender.thumbnailUrl}
                  />
                  <div className="absolute ml-[68px] mt-[68px] h-[32px] w-[32px] bg-white border-[1px] border-black rounded-full flex items-center justify-center">
                    {isPinnedUserMuted ? <MutedIcon /> : <UnmutedIcon />}
                  </div>
                </div>
                <div className="font-medium text-[16px] mt-3">
                  {spotlightName}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[16px] flex justify-center">
          <div className="inline-block">
            <div className="text-[13px] text-[#aaa]">
              {!spotlightTopic
                ? "Spotlighted response"
                : `${spotlightName}'s Response:`}
            </div>
            {!spotlightTopic ? (
              <SampleSpotlightIcon />
            ) : (
              <div className="w-[280px] bg-[#eee] rounded-[12px] p-[10px] flex min-h-[50px]">
                <div className="text-[13px] flex-1">{spotlightTopic}</div>
                <div className="flex items-center justify-center mr-2">
                  <PinIcon />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[40px] flex text-center leading-[40px] text-[13px] text-[#aaa] border-b-[0.5px] border-[#ccc]">
        <button
          className={clsx(
            "flex-1 text-[14px] ",
            isChatFocused &&
              "font-medium text-black border-black border-b-[1px]"
          )}
          onClick={() => setActiveTab("chat")}
        >
          Session Group Chat
        </button>
        <button
          className={clsx(
            "flex-1 text-[14px]",
            !isChatFocused &&
              "font-medium text-black border-black border-b-[1px]"
          )}
          onClick={() => setActiveTab("controls")}
        >
          {isHost ? "Host Controls" : "Guest Controls"}
        </button>
      </div>

      <div
        className="flex flex-col px-4 relative flex-1"
        style={{
          // let it grow? not sure if this works for all browsers
          height: 0,
          background: "linear-gradient(#f6f6f6, #fff)",
        }}
      >
        {activeTab === "chat" ? (
          <ChatTab
            sendMessage={onSendMessage}
            sessionId={sessionId}
            currentUser={currentUser!}
          />
        ) : (
          <ControlsTab
            isHost={isHost}
            pinnedMessage={session?.pinnedMessage}
            responses={responses}
            currentUserId={currentUser?.id}
            hostUsername={session?.session.username}
            hostThumbnailUrl={session?.session.thumbnailUrl}
            topic={session?.session.title}
            sessionId={sessionId}
            onPin={onPin}
            onRemovePin={onRemovePin}
            onEndSession={onEndSession}
          />
        )}
      </div>
      <Modal isActive={showMicrophonePermissionsDrawer}>
        <div className="py-6 px-5">
          <Microphone
            onSubmit={() => setShowMicrophonePermissionsDrawer(false)}
          />
        </div>
      </Modal>
      <ReceivePinModal
        isActive={isPinPromptActive}
        onAccept={onAcceptPin}
        onClose={() => setIsPinPromptActive(false)}
        hostUsername={hostUsername || ""}
        currentUser={currentUser}
      />
      <SendPinModal
        isActive={!!potenetialPinnedUser}
        onClose={() => setPotentialPinnedUser("")}
        potentialSpotlightName={potenetialPinnedUser}
      />
      <TipModal
        isActive={isTipModalActive}
        onClose={onTipModalClose}
        currentUserId={currentUser?.id}
        username={session?.session.username}
        thumbnail={session?.thumbnailUrl}
        sessionId={session?.session.id}
        ongoingSession
      />
    </>
  );
}
