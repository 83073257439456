interface Props {
  isActive: boolean;
}

export default function RadioButton({ isActive }: Props) {
  return (
    <div className="h-[20px] w-[20px] rounded-full border-[0.5px] border-[#ccc] p-1">
      {isActive && <div className="rounded-full bg-black w-full h-full" />}
    </div>
  );
}
