import { useEffect, useState } from "react";
import EndIcon from "../../../components/Icons/EndIcon";
import { useDaily } from "@daily-co/daily-react";
import UnmutedIcon from "../../../components/Icons/UnmutedIcon";
import MutedIcon from "../../../components/Icons/MutedIcon";
import Modal from "../../../components/Modal";
import BaseButton from "../../../components/BaseButton";

const LeaveIcon = require("./LeaveSessionIcon.png");

interface Props {
  spotlightedName?: string;
  onRemovePin?: () => void;
  onEndSession?: () => void;
}

export default function HostControls({
  spotlightedName,
  onRemovePin,
  onEndSession,
}: Props) {
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isEndSessionModalActive, setIsEndSessionModalActive] =
    useState<boolean>(false);
  const daily = useDaily();

  useEffect(() => {
    setIsMuted(!daily?.localAudio());
  }, [daily]);

  const toggleAudio = () => {
    daily?.updateParticipant("local", { setAudio: isMuted });
    setIsMuted(!isMuted);
  };

  return (
    <>
      <button onClick={toggleAudio}>
        <div
          className="flex items-center justify-center h-[48px] w-[48px] rounded-full"
          style={{ boxShadow: "0px 0px 8px 0px #00000026" }}
        >
          {isMuted ? <MutedIcon /> : <UnmutedIcon />}
        </div>
      </button>
      {spotlightedName ? (
        // Use 0 width with flex-1 so names truncate properly
        <button
          className="h-[48px] rounded-[24px] text-[16px] font-medium w-full text-nowrap flex-1 mx-2 flex whitespace-pre items-center justify-center px-4"
          style={{ boxShadow: "0px 0px 8px 0px #00000026", width: 0 }}
          onClick={onRemovePin}
        >
          End <div className="truncate">{spotlightedName}</div>
          's Spotlight
        </button>
      ) : (
        <div className="flex-1" />
      )}
      <button
        onClick={() => setIsEndSessionModalActive(true)}
        className="h-[48px] w-[48px] rounded-full flex items-center justify-center"
        style={{ boxShadow: "0px 0px 8px 0px #00000026" }}
      >
        <EndIcon />
      </button>
      <Modal isActive={isEndSessionModalActive}>
        <div className="flex flex-col items-center py-4 px-6">
          <img src={LeaveIcon} height={100} width={100} className="mt-8" />
          <div className="text-[28px] mt-6 text-center leading-[32px]">
            Sure you want to end the session for everyone?
          </div>
          <div className="mt-2 text-center">
            All participants will be immediately removed from your live session
            & won’t be able to re-enter.
          </div>
          <BaseButton className="my-4 mt-8" onClick={() => onEndSession?.()}>
            Confirm & End Session
          </BaseButton>
          <button
            className="text-[#00C947] font-medium"
            onClick={() => setIsEndSessionModalActive(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}
