import React, { useEffect, useRef } from "react";
import clsx from "clsx";

import useSafeSetState from "../../utils/useSafeSetState";
import { AnimatePresence, motion } from "framer-motion";

const DEFAULT_TOAST_DURATION = 2000;

/* DEFAULT NOOP API */
const DEFAULT_API = {
  show: (_: Toast): number => 0,
  dismiss: (_: number) => {},
  remove: (_: number) => {},
};

interface Toast {
  message?: string | JSX.Element;
  duration?: number;
  className?: string;
}

interface RenderedToast {
  id: number;
  toast: Toast;
  isDismissed: boolean;
}

interface IToastItem {
  item: RenderedToast;
  onDismiss: (id: number) => void;
  onRemove: (id: number) => void;
  isFirst: boolean;
}

function ToastItem({ item, onDismiss, onRemove, isFirst }: IToastItem) {
  const { id, toast, isDismissed } = item;
  const setSafeState = useSafeSetState();
  const timer = useRef<NodeJS.Timeout>();

  const onDismissClick = () => {
    timer.current && clearTimeout(timer.current);
    onDismiss(id);
  };

  useEffect(() => {
    timer.current = setTimeout(
      () =>
        setSafeState(() => {
          onDismissClick();
        }),
      toast.duration
    );
  }, []);

  return (
    <AnimatePresence onExitComplete={() => onRemove(id)}>
      {!isDismissed && (
        <motion.div
          key={item.id}
          className={clsx(
            "bg-[#eee] text-black text-center w-[90%] max-w-[550px] rounded-[15px] p-3 text-[16px] z-[100] shadow-simple pointer-events-auto",
            !isFirst && "mt-4",
            toast.className
          )}
          initial={{ opacity: 0, y: "-100%" }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.2,
            },
          }}
          exit={{
            opacity: 0,
            scale: 0,
            transition: {
              duration: 0.15,
            },
          }}
          onClick={onDismissClick}
        >
          {toast.message}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default class Toaster extends React.PureComponent {
  static instance = DEFAULT_API;
  static show = (toast: Toast): number =>
    Toaster.instance && Toaster.instance.show(toast);
  static dismiss = (id: number) =>
    Toaster.instance && Toaster.instance.dismiss(id);
  static remove = (id: number) =>
    Toaster.instance && Toaster.instance.remove(id);
  static showGenericError = (): number =>
    Toaster.instance &&
    Toaster.instance.show({
      message: "Something went wrong",
      className: "text-white bg-[#F73006]",
    });

  state: {
    items: RenderedToast[];
  } = {
    items: [],
  };

  show = (toast: Toast) => {
    const id = Date.now();

    this.setState({
      items: [
        {
          id,
          toast: {
            ...toast,
            duration: toast.duration ?? DEFAULT_TOAST_DURATION,
          },
          isDismissed: false,
        },
        ...this.state.items,
      ],
    });

    return id;
  };

  onDismiss = (id: number) => {
    const { items } = this.state;
    const item = items.find((item) => item.id == id);

    if (item) {
      item.isDismissed = true;
      this.setState({
        items: [...items],
      });
    }
  };

  onRemove = (id: number) => {
    this.setState({
      items: this.state.items.filter((item) => item.id !== id),
    });
  };

  componentDidMount() {
    const { show, onDismiss, onRemove } = this;

    Toaster.instance = {
      show,
      dismiss: onDismiss,
      remove: onRemove,
    };
  }

  componentWillUnmount() {
    Toaster.instance = DEFAULT_API;
  }

  render() {
    const { items } = this.state;
    const hasToasts = items.length > 0;

    return hasToasts ? (
      <div className="flex flex-col fixed justify-center items-center top-4 left-0 right-0 pointer-events-none z-20">
        {items.map((item, index) => (
          <React.Fragment key={item.id}>
            <ToastItem
              item={item}
              onDismiss={this.onDismiss}
              onRemove={this.onRemove}
              isFirst={index === 0}
            />
          </React.Fragment>
        ))}
      </div>
    ) : null;
  }
}
