import { ChangeEvent, ChangeEventHandler, useRef, useState } from "react";
import Drawer from "../../../components/Drawer";
import LinkIcon from "../../../components/Icons/LinkIcon";
import clsx from "clsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getNewSessionTimestamp } from "../../../utils/getSessionTimestamp";
import { currentUserState } from "../../../state/user";
import { useRecoilValue } from "recoil";
import { createSession } from "../../../api";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import copySessionLink from "../../../utils/copySessionLink";
import { IS_MOBILE } from "../../../utils/constants";
import Modal from "../../../components/Modal";
import BaseButton from "../../../components/BaseButton";
import Toaster from "../../../components/Toaster";
import { trackEvent } from "../../../utils/analytics";

interface Props {
  isActive: boolean;
  onClose: () => void;
}

export default function CreateSessionDrawer({ isActive, onClose }: Props) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [time, setTime] = useState<"now" | "later">("now");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [date, setDate] = useState<Date | undefined>();
  const [topic, setTopic] = useState<string>("");
  const currentUser = useRecoilValue(currentUserState);

  const [amount, setAmount] = useState<string>("5.00");
  const [error, setError] = useState<string>();

  const url = `insessions.co/${currentUser?.username}`;
  const canSubmit = topic.length >= 5;

  const Container = IS_MOBILE ? Drawer : Modal;

  const onCopy = () => {
    if (currentUser?.username) {
      copySessionLink(currentUser.username);
      Toaster.show({ message: "Link copied!" });
    }
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTopic(e.target.value);
  };

  const onSelectNow = () => {
    setTime("now");
    setIsDatePickerOpen(false);
    setDate(undefined);
  };

  const onSelectTime = () => {
    setTime("later");
    setIsDatePickerOpen(true);
  };

  const onSetDate = (date: Date) => {
    setDate(date);
  };

  const onCreateSession = async () => {
    setIsLoading(true);
    createSession({
      title: topic,
      minimumPrice: Number.parseFloat(amount) * 100,
      sessionDate: date,
    })
      .then(() => {
        trackEvent("Creator: Start New Session");
        navigate(
          generatePath(ROUTES.SESSION, {
            sessionId: currentUser?.username || "",
          })
        );
      })
      .catch(() => {
        Toaster.showGenericError();
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*\.?\d{0,2}$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(e.target.value);
      if ((Number.parseFloat(e.target.value) || 0) < 3) {
        setError("US$3.00 minimum.");
      } else {
        setError(undefined);
      }
    }
  };

  return (
    <Container
      isActive={isActive}
      onClose={onClose}
      maxHeightClassName="max-h-[90%]"
      containerClassName={clsx(!IS_MOBILE && "px-8 pb-4")}
    >
      <div className="text-[28px] text-center">Create a Session</div>
      <div className="mt-4">
        <div className="text-[#aaa] text-[14px] border-b-[0.5px] border-[#ccc]">
          When
        </div>
        <button className="flex items-center mt-4" onClick={onSelectNow}>
          <div className="h-[20px] w-[20px] border border-[#ccc] rounded-full flex items-center justify-center">
            {time === "now" && (
              <div className="h-[14px] w-[14px] bg-black rounded-full" />
            )}
          </div>
          <div className="ml-2 text-[15px]">Create now</div>
        </button>
        <button className="flex items-center mt-4" onClick={onSelectTime}>
          <div className="h-[20px] w-[20px] border border-[#ccc] rounded-full flex items-center justify-center">
            {time === "later" && (
              <div className="h-[14px] w-[14px] bg-black rounded-full" />
            )}
          </div>
          <div className="ml-2 text-[15px]">Schedule for later</div>
        </button>
        <div className="ml-[28px] h-[20px] text-[13px] text-[#aaa]">
          {!!date && <div>{getNewSessionTimestamp(date)}</div>}
        </div>

        <DatePicker
          popperPlacement="bottom-end"
          showTimeSelect
          startDate={date || new Date()}
          openToDate={date || new Date()}
          onChange={onSetDate}
          open={isDatePickerOpen}
          showIcon={false}
          showFullMonthYearPicker={false}
          wrapperClassName="h-[0px] invisible"
          onClickOutside={() => setIsDatePickerOpen(false)}
        />
      </div>
      <div className="mt-8">
        <div className="text-[#aaa] text-[14px]">Topic/Question</div>
        <textarea
          className="border-[0.5px] rounded-[10px] border-[#ccc] w-full p-4 mt-1 outline-none"
          placeholder="Set a topic or ask a question"
          style={{ resize: "none" }}
          value={topic}
          onChange={onChange}
        />
      </div>
      <div className="mt-8">
        <div className="text-[#aaa] text-[14px]">Minimum entry price</div>
        <div className="flex mt-1 border-[0.5px] border-[#ccc] rounded-[12px] outline-none p-3 w-full ">
          <div className={clsx("text-[15px]", !amount && "text-[#aaa]")}>
            US$
          </div>
          <input
            className={clsx(
              "outline-none text-[15px] w-full",
              !!error && "text-[#FF461E]"
            )}
            placeholder="5.00"
            type="numeric"
            value={amount}
            onChange={onChangeAmount}
          />
        </div>
      </div>
      <div className="mt-8">
        <div className="text-[#aaa] text-[14px] border-b-[0.5px] border-[#ccc]">
          Copy session link
        </div>
        <button className="flex items-center mt-4" onClick={onCopy}>
          <LinkIcon className="mr-2" />
          <div>{url}</div>
        </button>
      </div>
      <BaseButton
        className="mb-4 mt-8"
        onClick={onCreateSession}
        isDisabled={!canSubmit || (time === "later" && !date)}
        isLoading={isLoading}
      >
        Create Session
      </BaseButton>
    </Container>
  );
}
