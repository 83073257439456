import { Route, Routes } from "react-router-dom";
import {
  DailyAudio,
  DailyProvider,
  useCallObject,
} from "@daily-co/daily-react";

import { ROUTES } from "../routes";
import Session from "./Session";
import AllSessions from "./AllSessions";
import NavBar from "../components/NavBar";
import Settings from "./Settings";
import MicrophonePermissions from "./MicrophonePermissions";
import Room from "./Room";
import Earnings from "./Earnings";
import Purchases from "./Purchases";
import { fetchStoredAuth } from "../api";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState } from "../state/user";
import { useCallback, useEffect } from "react";
import LandingPage from "./LandingPage";
import { IS_MOBILE } from "../utils/constants";
import Toaster from "../components/Toaster";
import AntiInstagramPopover from "../components/AntiInstagramModal";
import { initializeAnalytics } from "../utils/analytics";
import { audioStream } from "../state/chat";

export default function App() {
  const IS_INSTAGRAM = !!navigator.userAgent.match(/instagram/i);

  // Create an instance of the Daily call object
  const callObject = useCallObject({
    options: {
      audioSource: true,
      videoSource: false,
    },
  });

  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const localStream = useRecoilValue(audioStream);
  const isAuthenticated = !!currentUser;

  const handleLeftMeeting = () => {
    localStream?.getTracks().forEach((track) => track.stop());
  };

  useEffect(() => {
    fetchStoredAuth().then((res) => {
      if (res) {
        setCurrentUser({
          id: res.id,
          username: res.username,
          thumbnailUrl: res.thumbnailUrl,
          isCreator: res.isCreator,
        });
      }
    });
  }, []);

  useEffect(() => {
    initializeAnalytics(currentUser?.id);
  }, [currentUser]);

  useEffect(() => {
    if (callObject) {
      callObject.on("left-meeting", handleLeftMeeting);
    }

    return () => {
      callObject?.off("left-meeting", handleLeftMeeting);
    };
  }, [callObject]);

  const handlePlayFailed = useCallback((e: any) => {
    console.error(
      `Failed to play ${e.type} for ${e.sessionId}. Audio tag: ${e.target}.`
    );
    console.error(e);
  }, []);

  return (
    <DailyProvider
      callObject={callObject}
      recoilRootProps={{
        // stores Daily React's state in RecoilRoot above
        override: false,
      }}
    >
      <DailyAudio maxSpeakers={6} onPlayFailed={handlePlayFailed} />
      <div
        className="relative h-full md:h-auto md:w-fit md:mx-auto"
        style={{ marginTop: !IS_MOBILE ? "max(3.5rem, 7.5vh)" : "" }}
      >
        <div className="flex flex-1 flex-col w-full h-full overflow-scroll hide-scrollbar md:aspect-[9/16] md:h-[85vh] md:min-h-[650px] md:w-auto md:mx-auto md:rounded-[32px] md:shadow-[0_0_20px_0_#00000026] bg-white">
          <NavBar
            isAuthenticated={isAuthenticated}
            isCreator={currentUser?.isCreator}
          />
          <Routes>
            <Route path={ROUTES.LANDING} element={<LandingPage />} />
            <Route path={ROUTES.SESSION} element={<Session />} />
            <Route path={ROUTES.ROOM} element={<Room />} />
            <Route path={ROUTES.ALL_SESSIONS} element={<AllSessions />} />
            <Route path={ROUTES.SETTINGS} element={<Settings />} />
            <Route
              path={ROUTES.PERMISSIONS}
              element={<MicrophonePermissions />}
            />
            <Route path={ROUTES.EARNINGS} element={<Earnings />} />
            <Route path={ROUTES.PURCHASES} element={<Purchases />} />
          </Routes>
          <Toaster />
          <AntiInstagramPopover isActive={IS_INSTAGRAM} />
        </div>
      </div>
    </DailyProvider>
  );
}
