import { IconProps } from ".";

export default function SendIcon(props: IconProps) {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4612 0.974202C11.1118 0.474349 10.5868 0.139639 9.98526 0.033476C9.38366 -0.0726874 8.77765 0.0614913 8.27779 0.41242L0 6.21012L1.77529 8.74625L7.51843 4.72531L4.82454 20.001H7.96815L10.5898 5.13227L14.7198 11.0302L17.256 9.25494L11.4612 0.974202Z"
        fill="black"
      />
    </svg>
  );
}
