import React, { CSSProperties, useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import blackSpinner from "./black.json";
import whiteSpinner from "./white.json";
import orangeSpinner from "./orange.json";

const spinnerMap: Record<SpinnerColors, any> = {
  black: blackSpinner,
  orange: orangeSpinner,
  white: whiteSpinner,
};

type SpinnerColors = "white" | "black" | "orange";
export interface Props {
  style?: CSSProperties;
  className?: string;
  color?: SpinnerColors;
  delay?: number;
  renderer?: (animation: JSX.Element) => JSX.Element;
}

export default function Spinner({
  style,
  className,
  color = "black",
  delay = 250,
  renderer,
}: Props) {
  const [shouldPlay, setShouldPlay] = useState(delay === 0);
  const isMounted = useRef(true);

  useEffect(() => {
    if (!shouldPlay) {
      setTimeout(() => {
        if (isMounted.current) {
          setShouldPlay(true);
        }
      }, delay);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  if (shouldPlay) {
    const animation = (
      <Lottie
        animationData={spinnerMap[color]}
        className={className}
        style={style}
        autoplay
        loop
        data-testid="lottie-spinner"
      />
    );

    return renderer ? renderer(animation) : animation;
  }

  return null;
}

export function ScreenSpinner() {
  return (
    <div className="flex flex-1 items-center justify-center">
      <Spinner className="w-[40px]" />
    </div>
  );
}
