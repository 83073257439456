import React from "react";
import { IconProps } from ".";

export default function XIcon(props: IconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M17.6567 17.6567L6.34303 6.343"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M6.34277 17.6567L17.6565 6.343"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}
