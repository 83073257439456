import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";
import { GetRoomRequest, GetRoomResponse, getRoom } from "../api";
import { useEffect } from "react";

export const roomState = atomFamily<GetRoomResponse | undefined, string>({
  key: "RoomState",
  default: undefined,
});

const roomSelector = selectorFamily({
  key: "RoomSelector",
  get: (username: string) => async () => {
    const response = await getRoom({ username });
    return response;
  },
});

export function useRoom(username: string) {
  const loadableRoom = useRecoilValueLoadable(roomSelector(username));
  const [room, setRoom] = useRecoilState(roomState(username));

  useEffect(() => {
    if (loadableRoom.state === "hasError") {
      // showGenericErrorToast();
    } else if (loadableRoom.state === "hasValue" && !room) {
      const newRoom = loadableRoom.valueMaybe();
      if (newRoom) {
        setRoom(newRoom);
      }
    }
  }, [room, loadableRoom, setRoom]);

  const refreshRoom = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const roomResponse = await getRoom({ username });
        setRoom(roomResponse);
      },
    []
  );

  return {
    refreshRoom,
    room: room || loadableRoom.valueMaybe(),
    setRoom,
    isLoading: loadableRoom.state === "loading",
  };
}
