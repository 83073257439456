import React from "react";
import SessionsTitleIcon from "../Icons/SessionsTitleIcon";
import MutedIcon from "../Icons/MutedIcon";
import UnmutedIcon from "../Icons/UnmutedIcon";

/* DEFAULT NOOP API */
const DEFAULT_API = {
  setNavigation: (_: SetNavigationProps) => {},
  setIsAuthenticated: (_: boolean) => {},
};

interface Props {
  isAuthenticated: boolean;
  isCreator?: boolean;
}

interface SetNavigationProps {
  inSessionHeader?: { muted?: boolean };
  leftIcon?: JSX.Element;
  title?: JSX.Element;
  rightIcon?: JSX.Element;
}

export const DefaultNavTitle = () => <SessionsTitleIcon className="mb-[7px]" />;

export default class NavBar extends React.PureComponent<Props> {
  static instance = DEFAULT_API;
  static setNavigation = (props: SetNavigationProps) =>
    NavBar.instance && NavBar.instance.setNavigation(props);
  static setIsAuthenticated = (isAuth: boolean) =>
    NavBar.instance && NavBar.instance.setIsAuthenticated(isAuth);
  state: {
    isAuthenticated?: boolean;
    title?: JSX.Element;
    leftIcon?: JSX.Element;
    rightIcon?: JSX.Element;
    inSessionHeader?: { inSession?: boolean; muted?: boolean };
  } = {
    isAuthenticated: false,
    title: <DefaultNavTitle />,
  };

  constructor(props: Props) {
    super(props);
    this.setState({
      isAuthenticated: props.isAuthenticated,
    });
  }

  setNavigation = (props: SetNavigationProps) => {
    this.setState({
      title: props.title || <DefaultNavTitle />,
      leftIcon: props.leftIcon,
      rightIcon: props.rightIcon,
      inSessionHeader: props.inSessionHeader,
    });
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.setState({
      isAuthenticated,
    });
  };

  componentDidMount(): void {
    const { setNavigation, setIsAuthenticated } = this;
    NavBar.instance = {
      setNavigation,
      setIsAuthenticated,
    };
  }

  componentWillUnmount(): void {
    NavBar.instance = DEFAULT_API;
  }

  render(): React.ReactNode {
    return (
      <>
        {this.state.inSessionHeader && (
          <div className="h-[52px] w-full bg-[#00C947] p-4 flex items-center">
            <div className="h-[32px] w-[32px] flex items-center justify-center rounded-full border-[1px] border-black">
              {this.state.inSessionHeader.muted ? (
                <MutedIcon bgColor="00C947" />
              ) : (
                <UnmutedIcon />
              )}
            </div>
            <div className="text-[16px] ml-[22px]">
              You’re currently in a live session
            </div>
          </div>
        )}
        <div className="flex flex-row items-center mx-4 relative mt-2 h-[40px] ">
          <div className="absolute flex items-center top-0 left-0 bottom-0">
            {this.state.isAuthenticated && this.state.leftIcon}
          </div>
          <div className="flex-1">
            <div className="flex items-center justify-center font-medium text-[20px]">
              {this.state.title}
            </div>
          </div>
          <div className="absolute flex items-center top-0 right-0 bottom-0">
            {this.state.isAuthenticated && this.state.rightIcon}
          </div>
        </div>
      </>
    );
  }
}
