import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import { ModalBackground } from "../Modal";

const image = require("./assets/Instagram.gif");

const TRANSITION_DURATION = 500;

const transitionStyle = {
  entering: { transform: "translate3d(0, -150%, 0)" },
  entered: { transform: "none" },
  exiting: { transform: "translate3d(0, -150%, 0)" },
  exited: { display: "none" },
  unmounted: { display: "none" },
};

export default function AntiInstagramPopover({
  isActive,
}: {
  isActive: boolean;
}) {
  return createPortal(
    <Transition
      mountOnEnter
      unmountOnExit
      in={isActive}
      timeout={{
        appear: 0,
        enter: 16,
        exit: TRANSITION_DURATION,
      }}
    >
      {(state) => (
        <div className="fixed top-0 right-0 bottom-0 left-0 z-10">
          <ModalBackground state={state} />
          <div
            className="absolute top-6 left-4 right-4 bg-white rounded-xl px-6 pb-8 pt-9"
            style={{
              ...transitionStyle[state],
              transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0.175, 0.885, 0.32, 1.125)`,
            }}
          >
            <div className="flex justify-center">
              <img src={image} height={100} width={148} />
            </div>
            <div className="text-center text-[24px] mt-3">
              Open in external browser to join this session
            </div>
            <div className="text-[16px] mt-4">
              <div className="">1. Tap the 3 dots</div>
              <div className="mt-3">2. Select 'Open in External Browser'</div>
            </div>
          </div>
        </div>
      )}
    </Transition>,
    document.body
  );
}
