import clsx from "clsx";
import UserThumbnail from "../../../components/UserThumbnail";
import {
  getSessionTimestamp,
  getUpcomingSessionTimestamp,
} from "../../../utils/getSessionTimestamp";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes";
import { useEffect, useState } from "react";

interface Props {
  name: string;
  sessionDate: Date;
  thumbnailUrl?: string;
  sessionId: string;
  username: string;
}

export default function SessionItem({
  name,
  sessionDate,
  thumbnailUrl,
  sessionId,
  username,
}: Props) {
  const [_, setRefresh] = useState<number>(0);

  const navigate = useNavigate();
  const onClick = () => {
    navigate(generatePath(ROUTES.SESSION, { sessionId: username }));
  };

  const minuteDuration = Math.floor(
    (new Date().getTime() - new Date(sessionDate).getTime()) / 60000
  );

  const date = new Date(sessionDate);
  const now = new Date();

  const inSession = date <= now;
  const isUpcoming = date > now;

  const indicatorColor = isUpcoming
    ? "bg-[#FFC2FE]"
    : inSession
    ? "bg-[#00C947]"
    : "bg-[#ccc]";
  const label = isUpcoming
    ? "Upcoming session"
    : inSession
    ? "In session"
    : "Not in session";

  useEffect(() => {
    if (isUpcoming) {
      const timeRemaining = date.getTime() - now.getTime();
      const timeout = setTimeout(() => {
        setRefresh((prev) => prev + 1);
      }, timeRemaining);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isUpcoming]);

  return (
    <button className="flex mt-6 w-full" onClick={onClick}>
      <div>
        <UserThumbnail
          height={64}
          width={64}
          name={name}
          thumbnailUrl={thumbnailUrl}
        />
      </div>
      <div className="flex flex-col flex-1 ml-4 h-[64px] justify-center">
        <div className="flex items-center">
          <div className="flex-1 text-[22px] text-start truncate">{name}</div>
          <div className="text-[12px] text-[#aaa]">
            {getSessionTimestamp(sessionDate, isUpcoming)}
          </div>
        </div>
        <div className="flex items-center">
          <div
            className={clsx("rounded-full h-[12px] w-[12px]", indicatorColor)}
          />
          <div className="font-medium text-[13px] ml-[6px]">{label}</div>
          {inSession && (
            <>
              <div className="h-[2px] w-[2px] rounded-full bg-[#aaa] mx-[6px]" />
              <div className="text-[#aaa] text-[12px]">
                {minuteDuration} min{minuteDuration > 1 ? "s" : ""}
              </div>
            </>
          )}
        </div>
      </div>
    </button>
  );
}
