export const IS_MOBILE = window.innerWidth < 768;

export const APPLY_FOR_CREATOR_LINK =
  "https://tic71dryk6u.typeform.com/to/NrP1mqxg";

export const PRIVACY_POLICY_LINK =
  "https://polarized-innovation-388.notion.site/Sessions-Privacy-Policy-a329b4bbffda43c9b6e45e7265a55aa3?pvs=4";

export const TERMS_LINK =
  "https://polarized-innovation-388.notion.site/Sessions-Terms-of-Service-889fdd51f8a546469ef818c7caafa6ff?pvs=4";
