import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PlayIcon from "../../../components/Icons/PlayIcon";

interface Props {
  heading: string;
  videoSrc?: string;
  children?: React.ReactNode;
  isSelected?: boolean;
}

export default function CarouselItem({
  heading,
  videoSrc,
  children,
  isSelected,
}: Props) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>();

  const onToggleVideo = () => {
    if (isPlaying) {
      videoRef.current?.pause();
      setIsPlaying(false);
    } else {
      videoRef.current?.play();
      setIsPlaying(true);
    }
  };

  const onEnded = () => {
    if (videoRef.current) videoRef.current.currentTime = 0;
    setIsPlaying(false);
  };

  useEffect(() => {
    if (!isSelected) {
      if (videoRef.current) {
        videoRef.current?.pause();
        videoRef.current.currentTime = 0;
      }
      if (isPlaying) setIsPlaying(false);
    }
  }, [isSelected]);

  return (
    <div className="flex flex-col flex-1 w-full border-y-[1px] border-x-[0.5px] border-black mt-[40px] relative">
      <div
        className="absolute z-10 top-[-30px] h-[60px] text-[14px] bg-[#ffc2fe] border-[1px] border-black rounded-[15px] pt-[6px] pb-2 mx-5"
        style={{ WebkitTransform: "translate3d(0,0,0)" }}
      >
        {heading}
      </div>
      {!!videoSrc ? (
        <div className="absolute h-full w-full overflow-hidden">
          <video
            ref={(r) => (videoRef.current = r)}
            className={clsx("h-full min-w-full", !isPlaying && "opacity-[0.5]")}
            src={`${videoSrc}#t=0.001`}
            onEnded={onEnded}
            playsInline
            preload="metadata"
          />
          <button
            className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center"
            onClick={onToggleVideo}
            style={{ WebkitTransform: "translate3d(0,0,0)" }}
          >
            {!isPlaying && (
              <>
                <div className="flex items-center justify-center h-[88px] w-[88px] bg-[#00C947] rounded-full border-[1px] border-black">
                  <PlayIcon />
                </div>
                <div className="w-[88px] bg-[#FFC2FE] border-[1px] border-black text-[14px] font-medium rounded-[20px] py-1 mt-2">
                  Play audio
                </div>
              </>
            )}
          </button>
        </div>
      ) : (
        children
      )}
    </div>
  );
}
