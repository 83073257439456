import { IconProps } from ".";

export default function LinkIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="11.5"
        cy="4.5"
        r="3.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <circle
        cx="4.5"
        cy="11.5"
        r="3.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <rect
        x="11.5446"
        y="2.91789"
        width="2.75"
        height="12.2"
        transform="rotate(45 11.5446 2.91789)"
        fill="currentColor"
        stroke="white"
      />
    </svg>
  );
}
