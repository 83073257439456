import { ChangeEvent, useEffect, useState } from "react";
import AddDisplayIcon from "../icons/AddDisplayIcon";
import clsx from "clsx";
import { useRef } from "react";
import { createAccount, createMedia } from "../../../api";
import BaseButton from "../../../components/BaseButton";
import { trackEvent } from "../../../utils/analytics";

interface Props {
  authToken: string;
  onCreateAccount: (token: string) => void;
}

export default function CreateAccount({ authToken, onCreateAccount }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const [thumbnailMediaId, setThumbnailMediaId] = useState<string | undefined>(
    undefined
  );
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const isButtonDisabled = username.length < 3;

  const onSubmit = async (e?: any) => {
    setIsLoading(true);
    e.preventDefault();
    trackEvent("Onboarding: Add display picture & username", {
      hasDisplayPicture: !!thumbnailMediaId,
    });
    await createAccount({
      username,
      token: authToken,
      thumbnailMediaId: thumbnailMediaId,
    })
      .then(({ token }) => {
        onCreateAccount(token);
      })
      .catch((r) => {
        // Conflict status code
        // For when username is already taken
        if (r.response.status === 409) {
          setError("Username already taken");
        } else {
          setError("An unkown error has occured. Please try again.");
        }
        setIsLoading(false);
      });
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!error) {
      setError("");
    }

    let value = e.target.value;

    if (value) {
      value = value.replace(/[\W]/, "");
      value = value.toLowerCase();
    }

    setUsername(value);
  };

  const cloudflareAccountHash = "Qjhc1Lhyla2MVXmWlhHj6A";

  const mediaURL = (id: string, variant: string): string => {
    const u = new URL(
      `/${cloudflareAccountHash}/${id}/${variant}`,
      "https://imagedelivery.net"
    );
    return u.toString();
  };

  const _onChange = async (e: React.FormEvent<HTMLInputElement>) => {
    setIsUploadingPhoto(true);
    const files = e.currentTarget.files;
    if (!files) return;
    const file = files[0];

    const res = await createMedia({ Username: username });
    const data = new FormData();

    data.append("file", file);
    await fetch(res.uploadUrl, { method: "POST", body: data });

    setThumbnailMediaId(res.id);
    setIsUploadingPhoto(false);
  };

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        accept=".jpg, .png, .heic"
        className="hidden"
        onChange={_onChange}
        data-testid="input-file"
      />
      <div className="text-[28px] mt-4 text-center leading-[32px]">
        Create account to join session
      </div>
      <div className="flex">
        <div className="flex flex-col items-center">
          <div className="text-[11px] text-[#aaa] mt-1">Display Photo</div>
          <AddDisplayIcon
            onClick={() => inputRef.current?.click()}
            disabled={isUploadingPhoto}
            thumbnailUrl={
              thumbnailMediaId
                ? mediaURL(thumbnailMediaId, "smallthumbnail")
                : undefined
            }
          />
        </div>
        <div className="flex flex-col flex-1 ml-2">
          <div className="text-[11px] text-[#aaa] text-center">Username</div>
          <form onSubmit={onSubmit}>
            <input
              className="border-[0.5px] border-[#aaa] text-[20px] placeholder-[#aaa] rounded-[16px] flex-1 p-[18px] mt-1 outline-none"
              placeholder="Create username"
              onChange={onChangeText}
              value={username}
              maxLength={20}
            />
          </form>
        </div>
      </div>
      <div className="text-[13px] text-[#FF461E] mt-3 h-[20px]">{error}</div>
      <BaseButton
        onClick={onSubmit}
        className="my-4 mt-[100px]"
        isDisabled={isButtonDisabled}
        isLoading={isLoading}
      >
        Create Account & Continue
      </BaseButton>
    </>
  );
}
