import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./screens";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// TODO: ENV
const stripePromise = loadStripe(
  "pk_live_51OoVLiD0KCIqKyHrWAPpT1bNFB8o8UKbRewBrnA5K1ZJeymJs8viA2M8qJNxLlg9uGRa5FR0WTNHQGHTL3XPbhZH00S2TBfIx0"
);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <RecoilRoot>
    <BrowserRouter>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [
            {
              family: "Klarheit Grotesk",
              style: "normal",
              src: "https://d2acjb97inqimx.cloudfront.net/static/KlarheitGrotesk-Regular.woff",
            },
          ],
        }}
      >
        <App />
      </Elements>
    </BrowserRouter>
  </RecoilRoot>
);
