import ReactTextareaAutosize from "react-textarea-autosize";
import Modal from "../../../components/Modal";
import { ChangeEvent, useState } from "react";
import BaseButton from "../../../components/BaseButton";
import { IS_MOBILE } from "../../../utils/constants";
import Drawer from "../../../components/Drawer";
import UserThumbnail from "../../../components/UserThumbnail";
import RadioButton from "../../../components/RadioButton";
import clsx from "clsx";

interface Props {
  isActive: boolean;
  creatorUsername?: string;
  creatorThumbnailUrl?: string;
  topic?: string;
  onClose: () => void;
  onSubmit: (question?: string, isAnonymous?: boolean) => void;
}

export default function QuestionModal({
  isActive,
  topic,
  creatorUsername,
  creatorThumbnailUrl,
  onSubmit,
  onClose,
}: Props) {
  const [question, setQuestion] = useState<string>("");
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const _onSubmit = () => {
    onSubmit(question, isAnonymous);
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
    if (e.target.value.length >= 100) {
      setError("Character limit reached");
    } else if (!!error) {
      setError("");
    }
  };

  const Container = IS_MOBILE ? Drawer : Modal;

  return (
    <Container
      isActive={isActive}
      onClose={onClose}
      maxHeightClassName="max-h-[90%]"
      containerClassName={clsx(!IS_MOBILE && "px-6")}
    >
      <div className="mb-4 text-center flex flex-col items-center">
        <UserThumbnail
          name={creatorUsername}
          thumbnailUrl={creatorThumbnailUrl}
          borderColor="#00C947"
        />
        <div className="text-[14px] mt-4">{creatorUsername}'s Live Session</div>
        <div className="text-[18px] mt-6">{topic}</div>
        <ReactTextareaAutosize
          minRows={2}
          maxRows={2}
          className="w-full bg-[#eee] border-[#ccc] rounded-[10px] outline-none p-4 text-[15px] mt-2 resize-none"
          onChange={onChange}
          maxLength={100}
          placeholder="Write response"
        />
        <div className="text-[#f73006] text-[12px] h-[18px]">{error}</div>
        <div className="items-start w-full">
          <button className="flex" onClick={() => setIsAnonymous(false)}>
            <RadioButton isActive={!isAnonymous} />
            <div className="text-start ml-2">
              <div className="text-[14px]">
                I'm open to chatting with {creatorUsername}
              </div>
              <div className="text-[12px] text-[#aaa]">
                Your response may be pinned in front of the group
              </div>
            </div>
          </button>
          <button className="flex mt-4" onClick={() => setIsAnonymous(true)}>
            <RadioButton isActive={isAnonymous} />
            <div className="text-start ml-2">
              <div className="text-[14px]">
                I don’t want to chat with {creatorUsername}
              </div>
              <div className="text-[12px] text-[#aaa]">
                Your response will remain anonymous
              </div>
            </div>
          </button>
        </div>
        <BaseButton
          onClick={_onSubmit}
          className="mt-4"
          isDisabled={question.length === 0}
        >
          Continue to join
        </BaseButton>
      </div>
    </Container>
  );
}
