import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Transition, TransitionStatus } from "react-transition-group";
// import styled from "styled-components";
import clsx from "clsx";

// import { XIcon } from "@/src/components/Icons/XIcon";
// import { ChevronIcon } from "@/src/components/Icons/ChevronIcon";
// import { Spacer } from "@/src/components/Spacer";

// import { IS_MOBILE } from "@/src/utils/constants";
import ChevronIcon from "../Icons/ChevronIcon";
import XIcon from "../Icons/XIcon";

export const modalDuration = 350;

// export const modalExitDuration = IS_MOBILE ? 250 : 170;
export const modalExitDuration = 250;

type StylesMap = {
  [key in TransitionStatus]: React.CSSProperties;
};

const transitionStyle: StylesMap = {
  entering: { transform: "translate3d(0, -50%, 0)", opacity: 0 },
  entered: { transform: "translate3d(0, 0, 0)", opacity: 1 },
  exiting: {
    transform: "translate3d(0, -50%, 0)",
    transitionTimingFunction: "ease-in-out",
    transitionDuration: `${modalExitDuration}ms`,
    opacity: 0,
  },
  exited: {},
  unmounted: {},
};

export const backdropStyle: StylesMap = {
  entering: {},
  entered: { opacity: 0.5 },
  exiting: { opacity: 0, transition: `opacity ${modalExitDuration}ms linear` },
  exited: {},
  unmounted: {},
};

export interface ModalProps {
  isActive: boolean;
  blur?: boolean;
  children?: React.ReactNode;
  onClose?: () => any;
  onGoBack?: () => void;
  onEntered?: () => void;
  onExited?: () => void;
  hasCustomWrapper?: boolean;
  unmountOnExit?: boolean;
  containerClassName?: string;
  hideControls?: boolean;
  maxHeightClassName?: string;
}

interface ModalBackgroundProps {
  state: TransitionStatus;
  onClose?: () => void;
  className?: string;
  opacity?: number;
}

export const ModalBackground = ({
  state,
  onClose,
  opacity,
  className,
}: ModalBackgroundProps) => (
  <div
    className={clsx(
      "absolute top-0 right-0 bottom-0 left-0 bg-black",
      className
    )}
    style={{
      opacity: 0,
      transition: `opacity ${modalDuration}ms linear`,
      ...backdropStyle[state],
      ...(opacity !== undefined && state === "entered" ? { opacity } : {}),
    }}
    onClick={onClose}
    data-testid="modal-background"
  />
);

interface ModalControlsProps {
  onGoBack?: () => void;
  onClose?: () => void;
  paddingClassName?: string;
}

export const ModalControls = ({
  onGoBack,
  onClose,
  paddingClassName = "p-6",
}: ModalControlsProps) => {
  const controlClassName =
    "flex items-center justify-center text-black bg-light-gray rounded-xl pointer-events-auto actionable cursor-pointer";

  return onGoBack || onClose ? (
    <div
      className={clsx(
        "absolute left-0 top-0 right-0 flex flex-row pointer-events-none",
        paddingClassName
      )}
    >
      {onGoBack && (
        <div className={controlClassName} onClick={onGoBack}>
          <ChevronIcon direction="left" />
        </div>
      )}
      <div className="flex flex-grow" />
      {onClose ? (
        <div className={controlClassName} onClick={onClose}>
          <XIcon />
        </div>
      ) : null}
    </div>
  ) : null;
};

type ModalStack = {
  stack: number[];
  add: () => number;
  remove: (id: number) => void;
  isCurrent: (id: number) => boolean;
};

const modalStack: ModalStack = {
  stack: [],
  add: function () {
    const id = performance.now();
    this.stack.push(id);
    return id;
  },
  remove: function (id: number) {
    this.stack = this.stack.filter((item) => item !== id);
  },
  isCurrent: function (id: number) {
    return this.stack[this.stack.length - 1] === id;
  },
};

export const useKeyboardDismiss = (
  isActive: boolean,
  onDismiss?: () => void
) => {
  const modalId = useRef<number>();

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (
        modalStack.isCurrent(modalId.current as number) &&
        e.code === "Escape"
      ) {
        onDismiss && onDismiss();
      }
    };

    if (isActive) {
      modalId.current = modalStack.add();
      window.addEventListener("keydown", handler);

      return () => {
        modalStack.remove(modalId.current as number);
        window.removeEventListener("keydown", handler);
      };
    }
  }, [isActive]);
};

export default function Modal({
  isActive,
  onGoBack,
  children,
  onClose,
  hasCustomWrapper = false,
  unmountOnExit = true,
  onExited,
  onEntered,
  containerClassName,
  maxHeightClassName,
  hideControls = false,
}: ModalProps) {
  useKeyboardDismiss(isActive, onClose);

  const shouldRenderControls = !hideControls && (onGoBack || onClose);

  return createPortal(
    <Transition
      in={isActive}
      mountOnEnter
      onExited={onExited}
      onEntered={onEntered}
      unmountOnExit={unmountOnExit}
      timeout={{
        appear: 0,
        enter: 16,
        exit: modalExitDuration,
      }}
    >
      {(state) => (
        <div
          className="fixed top-0 right-0 bottom-0 left-0 z-10"
          data-testid="modal-component"
        >
          <ModalBackground state={state} onClose={onClose} />
          {children ? (
            !hasCustomWrapper ? (
              <div
                className="absolute top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center pointer-events-none opacity-0 overflow-hidden"
                style={{
                  transform: "translate3d(0, -50%, 0)",
                  transition: `transform ${modalDuration}ms cubic-bezier(0.34, 1.56, 0.64, 1), opacity ${modalDuration}ms linear`,
                  willChange: "transform, transform, opacity",
                  ...transitionStyle[state],
                }}
              >
                <div
                  className={clsx(
                    "flex relative w-[90%] max-w-[550px]",
                    maxHeightClassName ?? "max-h-[75%]"
                  )}
                >
                  <div
                    className={clsx(
                      "flex flex-col w-full relative rounded-[40px] bg-white overflow-y-scroll hide-scrollbar pointer-events-auto",
                      shouldRenderControls && "pt-[72px]",
                      containerClassName
                    )}
                  >
                    {children}
                  </div>
                  {shouldRenderControls && (
                    <ModalControls onGoBack={onGoBack} onClose={onClose} />
                  )}
                </div>
              </div>
            ) : typeof children === "function" ? (
              (children as Function)(state)
            ) : state === "entered" ? (
              children
            ) : null
          ) : null}
        </div>
      )}
    </Transition>,
    document.body
  );
}
