import { IconProps } from ".";

export default function SampleSpotlightIcon(props: IconProps) {
  return (
    <svg
      width="271"
      height="51"
      viewBox="0 0 271 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 8.9543 8.9543 0 20 0H271V31C271 42.0457 262.046 51 251 51H20C8.95431 51 0 42.0457 0 31V20Z"
        fill="#EEEEEE"
      />
      <g clip-path="url(#clip0_370_800)">
        <path
          d="M253.972 17.8606L253.112 17.0141C252.448 16.3607 251.565 16 250.627 16C249.688 16 248.805 16.3593 248.141 17.0141L244.565 20.5356L244.243 20.489C242.724 20.2668 241.169 20.7712 240.083 21.8399L237 24.8716L240.777 28.5887L237.998 31.3236L239.719 33.0178L242.498 30.2829L246.275 34L249.231 31.0907C250.417 29.9235 250.913 28.2001 250.524 26.5937L250.437 26.233L253.972 22.7542C255.343 21.4047 255.343 19.2101 253.972 17.8606ZM252.251 21.06L247.755 25.4851L248.156 27.1474C248.351 27.9512 248.102 28.8123 247.51 29.3965L246.275 30.6116L240.443 24.8716L241.803 23.5327C242.346 22.999 243.123 22.7462 243.883 22.8566L245.416 23.0816L249.861 18.707C250.066 18.506 250.337 18.3956 250.627 18.3956C250.916 18.3956 251.188 18.506 251.392 18.707L252.252 19.5534C252.674 19.9687 252.674 20.6434 252.252 21.0586L252.251 21.06Z"
          fill="black"
        />
      </g>
      <rect x="14" y="14" width="207" height="7" fill="#CCCCCC" />
      <rect x="14" y="29" width="158" height="7" fill="#CCCCCC" />
      <defs>
        <clipPath id="clip0_370_800">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(237 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
