import clsx from "clsx";
import Spinner from "../Spinner";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSmall?: boolean;
  isDisabled?: boolean;
  backgroundColor?: string;
  isLoading?: boolean;
  onClick: () => void;
}

export default function BaseButton({
  isSmall,
  isDisabled,
  children,
  className,
  isLoading,
  onClick,
  backgroundColor = "#00C947",
  ...props
}: Props) {
  return (
    <button
      className={clsx(
        "w-full border-[1px] border-black",
        isSmall ? "h-[40px] rounded-[20px]" : "h-[48px] rounded-[24px]",
        // isDisabled ? "bg-[#ccc]" : `bg-[${backgroundColor}]`,
        className
      )}
      style={{ backgroundColor: isDisabled ? "#ccc" : backgroundColor }}
      onClick={onClick}
      disabled={isDisabled}
      {...props}
    >
      <div
        className={clsx("font-medium", isSmall ? "text-[18px]" : "text-[20px]")}
      >
        {isLoading ? <Spinner className="h-[20px]" /> : children}
      </div>
    </button>
  );
}
