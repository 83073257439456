import { atom } from "recoil";

export interface User {
  id?: string;
  username: string;
  thumbnailUrl?: string;
  isCreator?: boolean;
}

export const currentUserState = atom<User | undefined>({
  key: "CurrentUserState",
  default: undefined,
});
