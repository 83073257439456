import { times } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useEventListener } from "../../../utils/useEventListener";
import { verifyPhoneNumber } from "../../../api";
import clsx from "clsx";
import BaseButton from "../../../components/BaseButton";

interface Props {
  phone: string;
  verificationToken: string;
  isForUpcomingSession?: boolean;
  onVerify: (isSignedUp: boolean, token: string) => void;
  onResendCode: () => void;
}

export default function VerificationCode({
  phone,
  verificationToken,
  isForUpcomingSession,
  onVerify,
  onResendCode,
}: Props) {
  const inputRefs = useRef<any>([]);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [canResend, setCanResend] = useState<boolean>(false);
  const disabled = verificationCode.length !== 6 || isLoading;

  useEventListener("keyup", ({ key }) => {
    if (key === "Enter") {
      if (!disabled) {
        onSubmit();
      }
    }
  });

  useEffect(() => {
    inputRefs.current[0].focus();

    const timeout = setTimeout(() => {
      setCanResend(true);
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const _onResendCode = async () => {
    setError(undefined);
    setCanResend(false);
    setVerificationCode("");
    inputRefs.current.map((i: any) => (i.value = ""));
    onResendCode();
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    currentIndex: number
  ) => {
    if (event.key !== "Enter" && event.key !== "Unidentified") {
      setError(undefined);

      const currentRef = inputRefs.current[currentIndex];
      event.preventDefault();

      if (
        event.key === "Backspace" &&
        !event.currentTarget.value &&
        currentIndex !== 0
      ) {
        const prevRef = inputRefs.current[currentIndex - 1];
        prevRef.select();
        return;
      }

      if (isNaN(Number(event.key))) {
        currentRef.value = "";
        updateVerificationCode();
        return;
      }

      if (currentIndex !== 5) {
        const nextRef = inputRefs.current[currentIndex + 1];
        nextRef.select();
      }

      currentRef.value = event.key;
      updateVerificationCode();
    }
  };

  const updateVerificationCode = () => {
    const code = inputRefs.current.map((ref: any) => ref.value).join("");

    setVerificationCode(code);

    if (code.length === 6) {
      onSubmit(code);
    }
  };

  const verifyCode = async (code: string) => {
    const { isSignedUp, token } = await verifyPhoneNumber({
      phone,
      code,
      token: verificationToken,
    });

    onVerify(isSignedUp, token);
  };

  const onSubmit = async (rawCode?: string) => {
    setIsLoading(true);

    const code = rawCode ? rawCode : verificationCode;

    try {
      await verifyCode(code);
    } catch (e) {
      setError("Invalid code.");
      setIsLoading(false);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const code = e.target.value;

    if (code.length > 0) {
      if (code.length < 6) {
        inputRefs.current[index].value = code[0];
        if (index < 5) {
          inputRefs.current[index + 1].select();
        }
      } else {
        e.preventDefault();
        times(6, (i) => {
          inputRefs.current[i].value = code[i];
        });
      }
    }

    updateVerificationCode();
  };

  return (
    <>
      <div className="text-[28px] mt-4">
        Verify to {isForUpcomingSession ? "register interest" : "join session"}
      </div>
      <div className="flex gap-2 mt-6">
        {times(6, (i) => (
          <input
            value={inputRefs.current[i] ? inputRefs.current[i].value : ""}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            ref={(el) => (inputRefs.current[i] = el)}
            onKeyDown={(e) => onKeyDown(e, i)}
            key={`code-${i}`}
            autoFocus={i === 0}
            maxLength={i === 0 ? 6 : 1}
            onChange={(e) => onChange(e, i)}
            className="border-[0.5px] border-[#aaa] rounded-[10px] w-[16%] h-[80px] outline-none text-center text-[20px]"
          />
        ))}
      </div>
      <div className="h-[40px]">
        <div className="text-[13px] text-[#FF461E] mt-3 h-[20px]">{error}</div>
        {canResend && (
          <button
            className="font-medium text-[13px] mt-3"
            onClick={_onResendCode}
          >
            Re-send code
          </button>
        )}
      </div>
      <BaseButton
        onClick={onSubmit}
        isDisabled={disabled || !!error}
        className="my-4 mt-[100px]"
        isLoading={isLoading}
      >
        Continue to {isForUpcomingSession ? "register" : "join"}
      </BaseButton>
    </>
  );
}
