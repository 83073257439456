import clsx from "clsx";
import UserThumbnail from "../../../components/UserThumbnail";

interface Props {
  name: string;
  message: string;
  isSender?: boolean;
  thumbnailUrl?: string;
  isResponse?: boolean;
  isPreviousMessageMine?: boolean;
}

export default function Message({
  name,
  message,
  isSender,
  thumbnailUrl,
  isResponse,
  isPreviousMessageMine,
}: Props) {
  return (
    <div
      className={clsx(
        "flex w-full",
        isSender && "justify-end",
        !isSender ? "mt-2" : "mt-0.5",
        !isPreviousMessageMine && "mt-4"
      )}
    >
      <div className="flex items-end max-w-[70%]">
        {!isSender && (
          <UserThumbnail
            height={32}
            width={32}
            name={name}
            fontSize={14}
            thumbnailUrl={thumbnailUrl}
          />
        )}
        <div className={clsx("flex flex-col w-full", !isSender && "ml-1")}>
          <div className="flex items-center">
            {!isSender && <div className="text-[11px] text-[#aaa]">{name}</div>}
          </div>
          <div
            className={clsx(
              "text-[15px] rounded-[20px] p-[10px] break-words",
              isSender
                ? "bg-[#00C947] text-black rounded-br-none"
                : "bg-[#eee] rounded-bl-none"
            )}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
}
