import clsx from "clsx";

interface Props {
  id: string;
  message: string;
  name: string;
  price: number;
  isHost: boolean;
  isPinned?: boolean;
  accepted?: boolean;
  disabled?: boolean;
  left?: boolean;
  timeRemaining?: number;
  isAnonymous?: boolean;
  onPin?: (messageId: string, message: string, username: string) => void;
}

export default function ResponseMessage({
  id,
  isHost,
  message,
  name,
  price,
  isPinned,
  accepted,
  disabled,
  left,
  timeRemaining,
  isAnonymous,
  onPin,
}: Props) {
  return (
    <div
      className={clsx(
        "flex my-2 gap-4",
        (accepted || isPinned || disabled || left) && "opacity-[0.5]"
      )}
    >
      <div
        className="flex flex-col flex-1 justify-center"
        style={{ width: "calc(100% - 116px)" }}
      >
        <div className="text-[16px]">{message}</div>
        <div className="flex items-center">
          <div className="text-[12px] text-[#aaa] truncate">
            {!isHost && isAnonymous
              ? "Submitted anonymously"
              : isAnonymous
              ? "Anonymous"
              : name}
          </div>
          {isHost && (
            <>
              <div className="w-[2px] h-[2px] rounded-full bg-[#aaa] mx-[6px] shrink-0" />

              <div className="text-[#00C947] text-[12px] text-nowrap">
                Paid US
                {price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                })}
                entry
              </div>
            </>
          )}
        </div>
      </div>
      {isHost && (
        <div
          className={clsx(
            "flex flex-col w-[100px] justify-center",
            timeRemaining ? "items-end" : "items-center"
          )}
        >
          {isAnonymous ? (
            <div className="text-[12px] text-[#aaa] text-center">
              Does not want to be spotlighted
            </div>
          ) : (
            <button
              className={clsx(
                "whitespace-nowrap text-black p-2 bg-[#00C947] rounded-[20px] font-medium w-[100px]",
                timeRemaining ? "text-[16px] py-[5px]" : "text-[12px]"
              )}
              onClick={() => onPin?.(id, message, name)}
              disabled={
                isPinned ||
                accepted ||
                disabled ||
                left ||
                (timeRemaining || 0) > 0
              }
            >
              {timeRemaining ? timeRemaining : "Start Spotlight"}
            </button>
          )}
          <div className="text-[11px] text-[#aaa] italic mt-1 h-[16.5px]">
            {timeRemaining
              ? "Spotlight Request Sent"
              : isPinned
              ? "Current Spotlight"
              : accepted
              ? "Previous Spotlight"
              : left && "Left"}
          </div>
        </div>
      )}
    </div>
  );
}
