import { IconProps } from ".";

export default function PlayIcon(props: IconProps) {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.5 15.6699C36.8333 17.5944 36.8333 22.4056 33.5 24.3301L7.99999 39.0526C4.66666 40.9771 0.499998 38.5714 0.499998 34.7224L0.499999 5.27756C0.5 1.42856 4.66667 -0.977062 8 0.947439L33.5 15.6699Z"
        fill="black"
      />
    </svg>
  );
}
