import { useEffect, useRef, useState } from "react";
import UserThumbnail from "../../components/UserThumbnail";
import VerificationCode from "./components/VerificationCode";
import PhoneNumber from "./components/PhoneNumber";
import CreateAccount from "./components/CreateAccount";
import { getRoom, getSession, setAuth, submitPhoneNumber } from "../../api";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentUserState } from "../../state/user";
import Microphone from "./components/Microphone";
import Payment from "./components/Payment";
import { trackEvent } from "../../utils/analytics";

const enum ONBOARDING_STEP {
  PHONE,
  VERIFICATION,
  CREATE_ACCOUNT,
  MICROPHONE,
  PAYMENT,
}

interface Props {
  promptResponse?: { question?: string; isAnonymous?: boolean };
  showTerms?: boolean;
  isForSession?: boolean;
  isForUpcomingSession?: boolean;
  sessionId?: string;
  creatorUsername?: string;
  creatorThumbnail?: string;
  onCompleteOnboarding: (redirect?: boolean) => void;
}

export default function Onboarding({
  showTerms,
  isForUpcomingSession,
  isForSession,
  sessionId,
  promptResponse,
  onCompleteOnboarding,
  creatorUsername,
  creatorThumbnail,
}: Props) {
  const navigate = useNavigate();
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>(
    undefined
  );
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [phone, setPhone] = useState<string>("");
  const [minimumPriceCents, setMinimumPriceCents] = useState<
    number | undefined
  >(undefined);
  const [verificationToken, setVerificationToken] = useState<string>("");
  const [authToken, setAuthToken] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<ONBOARDING_STEP>(
    !currentUser ? ONBOARDING_STEP.PHONE : ONBOARDING_STEP.PAYMENT
  );
  const scrollableRef = useRef<HTMLDivElement | null>();

  const onResendCode = async () => {
    const { verificationToken } = await submitPhoneNumber({ phone });
    setVerificationToken(verificationToken);
  };

  const onSubmitPhone = (phone: string, token: string) => {
    trackEvent("Onboarding: Enter Phone Number");
    setPhone(phone);
    setVerificationToken(token);
    setCurrentStep(ONBOARDING_STEP.VERIFICATION);
  };

  const onSubmitVerification = (isSignedUp: boolean, token: string) => {
    trackEvent("Onboarding: Enter Verification Code");
    if (isSignedUp) {
      setAuth(token).then((res) => {
        setCurrentUser(res);

        if (isForUpcomingSession) {
          onCompleteOnboarding();
        } else if (isForSession && sessionId) {
          getSession({ sessionId })
            .then((r) => {
              if (r.canJoin) {
                onCompleteOnboarding(true);
              } else setCurrentStep(ONBOARDING_STEP.MICROPHONE);
            })
            .catch(() => setCurrentStep(ONBOARDING_STEP.MICROPHONE));
          getRoom({ username: creatorUsername ? creatorUsername : "" }).then(
            (r) => {
              setThumbnailUrl(r.creatorThumbnailUrl);
            }
          );
        } else setCurrentStep(ONBOARDING_STEP.MICROPHONE);
      });
    } else {
      setAuthToken(token);
      setCurrentStep(ONBOARDING_STEP.CREATE_ACCOUNT);
    }
  };

  const onCreateAccount = (token: string) => {
    setAuth(token).then((res) => {
      setCurrentUser(res);
      if (isForUpcomingSession) {
        onCompleteOnboarding();
      } else {
        setCurrentStep(ONBOARDING_STEP.MICROPHONE);
      }
    });
  };

  const onGrantMicrophonePermissions = () => {
    trackEvent("Onboarding: Enable microphone permissions");

    if (isForSession) {
      setCurrentStep(ONBOARDING_STEP.PAYMENT);
    } else {
      onCompleteOnboarding();
    }
  };

  const onSubmitPayment = () => {
    trackEvent("Onboarding: Submit payment");
    onCompleteOnboarding();
  };

  useEffect(() => {
    if (isForSession && creatorUsername) {
      getRoom({ username: creatorUsername }).then((r) => {
        setThumbnailUrl(r.creatorThumbnailUrl);
        setMinimumPriceCents(r.minimumPrice);
      });
    }
  }, []);

  const LoginSteps: Record<ONBOARDING_STEP, JSX.Element> = {
    [ONBOARDING_STEP.PHONE]: (
      <PhoneNumber
        onSubmit={onSubmitPhone}
        showTerms={showTerms}
        isForUpcomingSession={isForUpcomingSession}
      />
    ),
    [ONBOARDING_STEP.VERIFICATION]: (
      <VerificationCode
        phone={phone}
        verificationToken={verificationToken}
        onVerify={onSubmitVerification}
        onResendCode={onResendCode}
        isForUpcomingSession={isForUpcomingSession}
      />
    ),
    [ONBOARDING_STEP.CREATE_ACCOUNT]: (
      <CreateAccount authToken={authToken} onCreateAccount={onCreateAccount} />
    ),
    [ONBOARDING_STEP.MICROPHONE]: (
      <Microphone onSubmit={onGrantMicrophonePermissions} />
    ),
    [ONBOARDING_STEP.PAYMENT]: (
      <Payment
        onSubmitPayment={onSubmitPayment}
        promptResponse={promptResponse}
        currentUserId={currentUser?.id}
        creatorUsername={creatorUsername}
        _sessionId={sessionId}
        scrollableRef={scrollableRef.current}
        minimumPriceCents={minimumPriceCents ? minimumPriceCents : 300}
      />
    ),
  };

  return (
    <div
      ref={(r) => (scrollableRef.current = r)}
      className="flex flex-col items-center"
    >
      {isForSession && (
        <>
          <UserThumbnail
            thumbnailUrl={creatorThumbnail}
            name={creatorUsername}
            borderColor="#00C947"
          />
          <div className="text-[14px] mt-3">
            {isForUpcomingSession && "Register interest for "}
            {creatorUsername}'s Live Session
          </div>
        </>
      )}
      {LoginSteps[currentStep]}
    </div>
  );
}
