import { IconProps } from ".";

export default function UnmutedIcon(props: IconProps) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.93745 9.328H8.61165C9.96553 9.328 11.1304 8.34114 11.3839 6.98007L12.0833 3.4434L12.0868 3.4257C12.2424 2.57695 12.0234 1.70931 11.4853 1.04471C10.9472 0.380108 10.1557 0 9.31337 0H7.63917C6.28529 0 5.12038 0.986865 4.86688 2.34794L4.16748 5.8846L4.16402 5.9023C4.00847 6.75106 4.22739 7.61869 4.76549 8.28329C5.30358 8.94789 6.09517 9.32918 6.93861 9.32918L6.93745 9.328ZM6.53993 6.36505L7.23819 2.83193L7.24164 2.81422C7.27736 2.61944 7.44444 2.47779 7.63801 2.47779H9.31222C9.47698 2.47779 9.57723 2.57104 9.62217 2.62771C9.6671 2.68319 9.73739 2.79887 9.70859 2.96178L9.01033 6.4949L9.00687 6.5126C8.97115 6.70738 8.80408 6.84903 8.6105 6.84903H6.9363C6.77153 6.84903 6.67129 6.75578 6.62635 6.69912C6.58141 6.64363 6.51113 6.52795 6.53993 6.36505Z"
        fill="black"
      />
      <path
        d="M13.5374 4.6672L12.9613 7.80604C12.6387 9.56257 11.1385 10.8375 9.39399 10.8375H6.04905C4.96595 10.8375 3.94737 10.3476 3.25603 9.49292C2.56468 8.63827 2.28239 7.52273 2.48172 6.43199L2.8055 4.66602H0.344323L0.104658 5.97515C-0.228339 7.79306 0.241774 9.65228 1.39401 11.0759C2.48172 12.4193 4.05453 13.2208 5.74486 13.3082L5.28281 15.9996H7.73938L8.19913 13.3164H9.39284C12.3011 13.3164 14.8014 11.1916 15.3384 8.26288L15.9975 4.66602H13.5351L13.5374 4.6672Z"
        fill="black"
      />
    </svg>
  );
}
