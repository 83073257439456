import React, { useMemo } from "react";
// import styled from 'styled-components';
import countries from "./countries.json";
import {
  CountryCode,
  getCountryCallingCode,
  isSupportedCountry,
} from "libphonenumber-js";

type CountryList = Array<{
  countryName: string;
  countryCode: string;
  shortName: string;
}>;

interface Props {
  onSelect: (countryCode: CountryCode) => any;
  value: CountryCode;
}

export default function CountryCodeSelector({ onSelect, value }: Props) {
  const parsedCountryList = useMemo((): CountryList => {
    return countries.reduce((acc, { code, name }) => {
      if (!isSupportedCountry(code as CountryCode)) return acc;

      const callingCode = getCountryCallingCode(code as CountryCode);
      const flag = String.fromCodePoint(
        ...code
          .toUpperCase()
          .split("")
          .map((char) => 127397 + char.charCodeAt(0))
      );

      acc.push({
        countryName: name,
        countryCode: code,
        shortName: `${flag} +${callingCode}`,
      });
      return acc;
    }, [] as CountryList);
  }, [countries]);

  return (
    <div>
      <select
        className="text-[20px] outline-none"
        defaultValue={value}
        onChange={(e) => onSelect(e.target.value as CountryCode)}
      >
        {parsedCountryList.map((c) => (
          <option
            className="text-[20px]"
            value={c.countryCode}
            key={c.countryCode}
          >
            {c.shortName}
          </option>
        ))}
      </select>
    </div>
  );
}
