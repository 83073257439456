import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js";

const ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
  style: {
    base: {
      fontSize: "15px",
      color: "#2A2D35",
      "::placeholder": {
        color: "#AAAAAA",
      },
      fontFamily: "Klarheit Grotesk, sans-serif",
    },
    invalid: {
      color: "#FF6711",
    },
  },
  classes: {
    base: "border border-sea-gray py-4 px-5 rounded-lg",
    invalid: "border border-bright-orange",
  },
};

// const Label = styled.div`
//   ${tw`mb-1 text-gray`};
//   font-size: 13px;
// `;

interface Props {
  isComplete: boolean;
  setComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CardElements({ isComplete, setComplete }: Props) {
  const [isCardComplete, setCardComplete] = useState<boolean>(false);
  const [isExpiryComplete, setExpiryComplete] = useState<boolean>(false);
  const [isCvcComplete, setCvcComplete] = useState<boolean>(false);

  const onCardChange = (event: StripeCardNumberElementChangeEvent) => {
    if (event.complete) {
      setCardComplete(true);
    } else if (isCardComplete) {
      setCardComplete(false);
    }

    if (event.complete && isExpiryComplete && isCvcComplete) {
      setComplete(true);
    } else if (isComplete) {
      setComplete(false);
    }
  };

  const onExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
    if (event.complete) {
      setExpiryComplete(true);
    } else if (isExpiryComplete) {
      setExpiryComplete(false);
    }

    if (isCardComplete && event.complete && isCvcComplete) {
      setComplete(true);
    } else if (isComplete) {
      setComplete(false);
    }
  };

  const onCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.complete) {
      setCvcComplete(true);
    } else if (isCvcComplete) {
      setCvcComplete(false);
    }

    if (isCardComplete && isExpiryComplete && event.complete) {
      setComplete(true);
    } else if (isComplete) {
      setComplete(false);
    }
  };

  return (
    <>
      <div>
        <div className="mb-1 text-gray text-[13px]">Card Number</div>
        <CardNumberElement options={ELEMENT_OPTIONS} onChange={onCardChange} />
      </div>
      <div className="flex items-center justify-between mt-4">
        <div className="mr-2.5 w-1/2">
          <div className="mb-1 text-gray text-[13px]">Exp</div>
          <CardExpiryElement
            options={ELEMENT_OPTIONS}
            onChange={onExpiryChange}
          />
        </div>
        <div className="ml-2.5 w-1/2">
          <div className="mb-1 text-gray text-[13px]">CVC</div>
          <CardCvcElement options={ELEMENT_OPTIONS} onChange={onCvcChange} />
        </div>
      </div>
    </>
  );
}
