import { ROUTES } from "../../routes";
import { APPLY_FOR_CREATOR_LINK, TERMS_LINK } from "../../utils/constants";
import { SettingsOptionProps } from "./components/SettingsOption";

export const NonCreatorOptions: SettingsOptionProps[] = [
  {
    label: "Purchases",
    route: ROUTES.PURCHASES,
  },
  {
    label: "Microphone permissions",
    route: ROUTES.PERMISSIONS,
  },
  {
    label: "Become a creator",
    route: APPLY_FOR_CREATOR_LINK,
    isExternalLink: true,
  },
  {
    label: "Terms",
    route: TERMS_LINK,
    isExternalLink: true,
  },
];

export const CreatorOptions: SettingsOptionProps[] = [
  {
    label: "Earnings",
    route: ROUTES.EARNINGS,
  },
  {
    label: "Purchases",
    route: ROUTES.PURCHASES,
  },
  {
    label: "Microphone permissions",
    route: ROUTES.PERMISSIONS,
  },
  {
    label: "Terms",
    route:
      "https://polarized-innovation-388.notion.site/Sessions-Terms-of-Service-889fdd51f8a546469ef818c7caafa6ff?pvs=4",
    isExternalLink: true,
  },
];
