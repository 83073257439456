import { useNavigate } from "react-router-dom";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import NavBar from "../../components/NavBar";
import { useEffect, useState } from "react";
import { useDaily } from "@daily-co/daily-react";
import Toaster from "../../components/Toaster";
import { useSetRecoilState } from "recoil";
import { audioStream } from "../../state/chat";

export default function MicrophonePermissions() {
  const [enabledPermissions, setEnabledPermissions] = useState<boolean>(true);
  const navigate = useNavigate();
  const daily = useDaily();
  const onBackPress = () => {
    navigate(-1);
  };
  const setMediaStream = useSetRecoilState(audioStream);

  useEffect(() => {
    NavBar.setNavigation({
      leftIcon: (
        <button onClick={onBackPress}>
          <ChevronIcon direction="left" />
        </button>
      ),
      title: (
        <div className="text-[20px] font-medium">Microphone Permissions</div>
      ),
      inSessionHeader:
        daily?.meetingState() === "joined-meeting"
          ? { muted: !daily?.localAudio() }
          : undefined,
    });
  }, [daily]);

  const permissions = window.navigator.permissions
    // @ts-ignore
    .query({ name: "microphone" } as PermissionDescriptor)
    .then(({ state }) => {
      if (state !== "granted") {
        setEnabledPermissions(false);
      }
    });

  const onClick = async () => {
    const permissions = navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        setMediaStream(stream);
      })
      .catch(() => Toaster.showGenericError());
  };

  return (
    <div className="mx-6 mt-4">
      <div className="py-3 px-4 border-[0.5px] border-[#ccc] rounded-[10px] flex items-center gap-4">
        <div className="text-[15px]">
          You have {!enabledPermissions ? "not " : ""}enabled microphone access
        </div>
        <button
          className="text-[13px] text-[#00C947] font-bold whitespace-nowrap"
          onClick={onClick}
        >
          Open settings
        </button>
      </div>
      <div className="text-[#aaa] text-[13px] mt-2">
        Microphone access is required if a creator selects your submitted
        response
      </div>
    </div>
  );
}
