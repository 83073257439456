import clsx from "clsx";
import { getInitials } from "../../utils/getInitials";

interface Props {
  height?: number;
  width?: number;
  thumbnailUrl?: string;
  className?: string;
  name?: string;
  backgroundColor?: string;
  fontSize?: number;
  isBadge?: boolean;
  borderColor?: string;
  isActive?: boolean;
  onClick?: () => void;
}

export default function UserThumbnail({
  height = 100,
  width = 100,
  thumbnailUrl,
  fontSize = 40,
  name,
  onClick,
  backgroundColor,
  className,
  borderColor,
  isBadge,
  isActive,
}: Props) {
  const initials = getInitials(name || "");

  const activityBadgeDimension = Math.round(Math.max(14, height * 0.21));

  return (
    <div
      onClick={onClick}
      className="rounded-full"
      style={{
        border: borderColor ? `5px solid ${borderColor}` : "none",
      }}
    >
      <div
        className={clsx(
          "relative flex-shrink-0 bg-cover bg-no-repeat rounded-full border-[1px] border-black",
          className
        )}
        style={{
          height,
          width,
          backgroundImage: thumbnailUrl && `url(${thumbnailUrl})`,
          backgroundColor: !thumbnailUrl?   backgroundColor || "#FFC2FE" : "none",
        }}
      >
        {!thumbnailUrl && (
          <div
            className="font-medium absolute top-[50%] left-[50%]"
            style={{ fontSize, transform: "translate(-50%, -50%)" }}
          >
            {!!name ? initials : "?"}
          </div>
        )}
        {isActive && (
          <div
            className="bg-message-blue absolute bottom-0 right-0 rounded-full border-[2.5px] border-white"
            style={{
              height: activityBadgeDimension,
              width: activityBadgeDimension,
            }}
            data-testid="activity-badge"
          />
        )}
      </div>
    </div>
  );
}
