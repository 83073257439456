import { useState } from "react";
import Drawer from "../../../components/Drawer";
import Modal from "../../../components/Modal";
import RadioButton from "../../../components/RadioButton";
import { IS_MOBILE } from "../../../utils/constants";
import BaseButton from "../../../components/BaseButton";
import clsx from "clsx";
import { updateAnonymity } from "../../../api";
import Toaster from "../../../components/Toaster";

interface Props {
  isActive: boolean;
  sessionId: string;
  creatorUsername?: string;
  response?: string;
  isAnonymous?: boolean;
  onClose: () => void;
}

export default function EditPreferencesModal({
  isActive,
  sessionId,
  creatorUsername,
  response,
  isAnonymous,
  onClose,
}: Props) {
  const [_isAnonymous, setIsAnonymous] = useState<boolean>(!!isAnonymous);

  const onSubmit = async () => {
    await updateAnonymity({
      sessionId,
      isAnonymous: _isAnonymous,
    }).catch((e) => Toaster.showGenericError());
    onClose();
  };

  const Container = IS_MOBILE ? Drawer : Modal;

  return (
    <Container
      isActive={isActive}
      onClose={onClose}
      containerClassName={clsx("pb-6", !IS_MOBILE && "px-6")}
    >
      <div className="text-[13px] text-[#aaa]">Your Submitted Response</div>
      <div className="text-[16px] mt-2">{response}</div>
      <div className="text-[12px] text-[#aaa] mt-[10px]">
        Submitted anonymously
      </div>
      <div className="items-start w-full mt-6">
        <button className="flex" onClick={() => setIsAnonymous(false)}>
          <RadioButton isActive={!_isAnonymous} />
          <div className="text-start ml-2">
            <div className="text-[14px]">
              I'm open to chatting with {creatorUsername}
            </div>
            <div className="text-[12px] text-[#aaa]">
              Your response may be pinned in front of the group
            </div>
          </div>
        </button>
        <button className="flex mt-4" onClick={() => setIsAnonymous(true)}>
          <RadioButton isActive={!!_isAnonymous} />
          <div className="text-start ml-2">
            <div className="text-[14px]">
              I don’t want to chat with {creatorUsername}
            </div>
            <div className="text-[12px] text-[#aaa]">
              Your response will remain anonymous
            </div>
          </div>
        </button>
      </div>
      <BaseButton
        onClick={onSubmit}
        className="mt-4"
        isDisabled={_isAnonymous === isAnonymous}
      >
        Update preferences
      </BaseButton>
    </Container>
  );
}
