import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { User } from "./user";
import { useEffect } from "react";
import { getMessages } from "../api";

export enum MessageType {
  PIN = "pin",
  PIN_RESPONSE = "pin_response",
  SEND = "send",
  DELETE = "delete",
  REMOVE_PIN = "remove_pin",
  END_SESSION = "end_session",
  RESPONSE = "response",
}

export interface PinMessage {
  type: MessageType;
  messageId: string;
}

export interface SocketMessage {
  messageId?: string;
  type?: MessageType;
  sender: User;
  message: string;
  timestamp?: string;
  roomId?: string;
  pinnedMessageId?: string;
  thumbnailUrl?: string;
  amount?: number;
  isAnonymouse?: boolean;
}

export interface ChatMessage {
  id: string;
  sender: User;
  message: string;
  sentAt: string;
  thumbnailUrl?: string;
}

export const sendPinState = atom<
  { timeRemaining: number; responseId: string } | undefined
>({
  key: "SendPinState",
  default: undefined,
});

export const chatState = atom<ChatMessage[]>({
  key: "ChatState",
  default: [],
});

export const audioStream = atom<MediaStream | undefined>({
  key: "MediaStream",
  default: undefined,
});

export function useMessageList(sessionId: string) {
  const messages = useRecoilValue(chatState);

  useEffect(() => {
    loadInitialMessages(sessionId);

    const listener = () => {
      if (document.visibilityState !== "hidden") {
        loadInitialMessages(sessionId);
      }
    };

    window.addEventListener("visibilitychange", listener);

    return () => {
      window.removeEventListener("visibilitychange", listener);
    };
  }, []);

  const loadInitialMessages = useRecoilCallback(
    ({ set, snapshot }) =>
      async (sessionId: string) => {
        const { messages } = await getMessages({
          sessionId,
          limit: 30,
          offset: 0,
        });
        if (messages) set(chatState, messages);
      }
  );

  return { messages };
}
