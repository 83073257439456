import { useEffect } from "react";
import { getResponses } from "../api";
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";
import { useRecoilRefresher_UNSTABLE } from "recoil";

export interface Response {
  id: string;
  userId: string;
  username: string;
  message: string;
  amount: number;
  accepted?: { Valid: boolean; Bool: boolean };
  isAnonymous?: boolean;
}

export const responseState = atomFamily<Response[], string>({
  key: "ResponseState",
  default: [],
});

export const responseSelector = selectorFamily({
  key: "ResponseSelector",
  get: (sessionId?: string) => async () => {
    if (sessionId) {
      const response = await getResponses({ sessionId });
      return response;
    }
  },
});

export function useResponses(
  sessionId: string,
  isHost: boolean,
  activeTab: string
) {
  const loadableResponses = useRecoilValueLoadable(responseSelector(sessionId));
  const [responses, setResponses] = useRecoilState(responseState(sessionId));

  useEffect(() => {
    if (loadableResponses.state === "hasError") {
      // showGenericErrorToast();
    } else if (
      loadableResponses.state === "hasValue" &&
      responses.length === 0
    ) {
      const newResponses = loadableResponses.valueMaybe();
      if (newResponses) {
        setResponses(newResponses.responses);
      }
    }
  }, [responses, loadableResponses, setResponses, isHost, activeTab]);

  return {
    responses: responses || loadableResponses.valueMaybe(),
    setResponses,
    isLoading: loadableResponses.state === "loading",
  };
}
