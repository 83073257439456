import { useEffect, useRef, useState } from "react";
import BaseButton from "../../../components/BaseButton";
import Modal from "../../../components/Modal";
import UserThumbnail from "../../../components/UserThumbnail";
import { User } from "../../../state/user";
import React from "react";
import { trackEvent } from "../../../utils/analytics";

interface Props {
  isActive: boolean;
  hostUsername: string;
  currentUser?: User;
  onAccept: () => void;
  onClose: () => void;
}

function ReceivePinModal({
  isActive,
  hostUsername,
  currentUser,
  onAccept,
  onClose,
}: Props) {
  const [timeRemaining, setTimeRemaining] = useState<number>(15);
  const timerRef = useRef<NodeJS.Timer>();
  useEffect(() => {
    if (isActive) {
      timerRef.current = setInterval(() => {
        setTimeRemaining((prev) => prev - 1);
      }, 1000);
    } else {
      setTimeRemaining(15);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isActive]);

  useEffect(() => {
    if (timeRemaining <= 0) {
      _onClose();
    }
  }, [timeRemaining]);

  const _onClose = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    trackEvent("Participant: Decline Spotlight Request", { timeRemaining });

    onClose();
  };

  const _onAccept = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    onAccept();
  };

  return (
    <Modal isActive={isActive}>
      <div className="flex flex-col items-center p-6">
        <UserThumbnail
          name={currentUser?.username}
          thumbnailUrl={currentUser?.thumbnailUrl}
        />
        <div className="text-[16px] font-medium mt-3">
          {currentUser?.username}
        </div>
        <div className="text-[24px] mt-3 text-center">
          {hostUsername} wants to spotlight your response
        </div>
        <div className="text-[16px] mt-4 text-center">
          You have {timeRemaining} seconds to accept or decline the request
        </div>
        <BaseButton onClick={_onAccept} className="mt-8">
          Accept Spotlight Request
        </BaseButton>
        <BaseButton
          onClick={_onClose}
          className="mt-3"
          backgroundColor="#f73006"
        >
          Decline Spotlight Request
        </BaseButton>
      </div>
    </Modal>
  );
}

export default React.memo(ReceivePinModal);
