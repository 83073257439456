import { IconProps } from ".";

interface Props extends IconProps {
  bgColor?: string;
}

export default function MutedIcon({ bgColor = "#fff", ...props }: Props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.93739 10.328H9.61159C10.9655 10.328 12.1304 9.34114 12.3839 7.98007L13.0833 4.4434L13.0867 4.4257C13.2423 3.57695 13.0234 2.70931 12.4853 2.04471C11.9472 1.38011 11.1556 1 10.3133 1H8.63911C7.28523 1 6.12032 1.98687 5.86682 3.34794L5.16741 6.8846L5.16396 6.9023C5.00841 7.75106 5.22733 8.61869 5.76543 9.28329C6.30352 9.94789 7.09511 10.3292 7.93855 10.3292L7.93739 10.328ZM7.53987 7.36505L8.23813 3.83193L8.24158 3.81422C8.2773 3.61944 8.44438 3.47779 8.63795 3.47779H10.3122C10.4769 3.47779 10.5772 3.57104 10.6221 3.62771C10.667 3.68319 10.7373 3.79887 10.7085 3.96178L10.0103 7.4949L10.0068 7.5126C9.97109 7.70738 9.80402 7.84903 9.61044 7.84903H7.93624C7.77147 7.84903 7.67123 7.75578 7.62629 7.69912C7.58135 7.64363 7.51107 7.52795 7.53987 7.36505Z"
        fill="black"
      />
      <path
        d="M14.5374 5.6672L13.9613 8.80604C13.6387 10.5626 12.1385 11.8375 10.394 11.8375H7.04905C5.96595 11.8375 4.94737 11.3476 4.25603 10.4929C3.56468 9.63827 3.28239 8.52273 3.48172 7.43199L3.8055 5.66602H1.34432L1.10466 6.97515C0.771661 8.79306 1.24177 10.6523 2.39401 12.0759C3.48172 13.4193 5.05453 14.2208 6.74486 14.3082L6.28281 16.9996H8.73938L9.19913 14.3164H10.3928C13.3011 14.3164 15.8014 12.1916 16.3384 9.26288L16.9975 5.66602H14.5351L14.5374 5.6672Z"
        fill="black"
      />
      <rect
        x="17.0276"
        y="16.1689"
        width="2"
        height="21.2765"
        transform="rotate(135 17.0276 16.1689)"
        fill="#FF461E"
      />
      <rect
        x="18"
        y="15.0449"
        width="1.50558"
        height="21.2765"
        transform="rotate(135 18 15.0449)"
        fill={bgColor}
      />
    </svg>
  );
}
