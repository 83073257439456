import clsx from "clsx";
import { useEffect, useState } from "react";
import CardElements from "../../screens/Onboarding/components/CardElements";
import { IS_MOBILE } from "../../utils/constants";
import BaseButton from "../BaseButton";
import ApplePayIcon from "../Icons/ApplePayIcon";
import GooglePayIcon from "../Icons/GooglePayIcon";
import RadioButton from "../RadioButton";
import Spinner from "../Spinner";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { CreatePaymentResponse, createPayment } from "../../api";
import Toaster from "../Toaster";

const DEFAULT_ERROR =
  "We weren't able to process your payment. Please try another card or contact your bank";

interface Props {
  // Passed in as cents
  paymentAmount: number;
  paymentDescription: string;
  isDisabled: boolean;
  buttonText: string;
  _createPayment: (
    amount: number,
    paymentId: string
  ) => Promise<CreatePaymentResponse>;
  onSubmitPayment: () => void;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function PaymentForm({
  paymentAmount,
  paymentDescription,
  isDisabled,
  buttonText,
  _createPayment,
  onSubmitPayment,
  setError,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitiateLoading, setIsInitiateLoading] = useState<boolean>(false);
  const [isComplete, setComplete] = useState<boolean>(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    "APPLE" | "GOOGLE"
  >();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  const [availableSources, setAvailableSources] = useState<{
    applePay: boolean;
    googlePay: boolean;
  }>();

  const initiatePayment = async () => {
    setIsLoading(true);
    setError(undefined);

    if (isLoading) return;

    try {
      if (!isDisabled) {
        if (
          selectedPaymentMethod === "APPLE" ||
          selectedPaymentMethod === "GOOGLE"
        ) {
          paymentRequest?.update({
            total: {
              amount: paymentAmount,
              label: paymentDescription,
            },
          });

          paymentRequest?.show();
          paymentRequest?.once("paymentmethod", async (event) => {
            setError("");
            _createPayment(paymentAmount, event.paymentMethod.id)
              .then((res) => {
                if (res.requiresClientAction) {
                  stripe
                    ?.handleNextAction({ clientSecret: res.clientSecret })
                    .then(() => {
                      event.complete("success");
                      setIsLoading(false);
                      onSubmitPayment();
                    })
                    .catch((err) => {
                      event.complete("fail");
                      setIsLoading(false);
                      setError(parseError(err) || DEFAULT_ERROR);
                    });
                } else {
                  event.complete("success");
                  setIsLoading(false);
                  onSubmitPayment();
                }
              })
              .catch((err) => {
                setIsLoading(false);
                setError(parseError(err) || DEFAULT_ERROR);
                event.complete("fail");
              });
          });

          paymentRequest?.once("cancel", () => {
            setIsLoading(false);
          });
        } else {
          if (!stripe) return false;

          const card = (await elements?.getElement(CardNumberElement)) || null;
          if (!card) return false;

          const paymentMethodResponse = await stripe.createPaymentMethod({
            type: "card",
            card,
          });

          if (
            paymentMethodResponse.error?.message ||
            !paymentMethodResponse.paymentMethod
          ) {
            setError(paymentMethodResponse.error?.message || DEFAULT_ERROR);
            setIsLoading(false);
            return false;
          }

          const paymentMethodId = paymentMethodResponse?.paymentMethod?.id;

          const res = await _createPayment(
            paymentAmount,
            paymentMethodId || ""
          );
          if (res.requiresClientAction) {
            stripe
              .handleNextAction({ clientSecret: res.clientSecret })
              .then(() => {
                setIsLoading(false);
                onSubmitPayment();
              })
              .catch(() => {
                Toaster.showGenericError();
                setIsLoading(false);
              });
          } else {
            setIsLoading(false);
            onSubmitPayment();
          }
        }
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(parseError(err) || DEFAULT_ERROR);
    }
  };

  const initiatePaymentMethods = async () => {
    setIsInitiateLoading(true);
    const pr = stripe?.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        amount: 0,
        label: paymentDescription,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    setPaymentRequest(pr);

    const canMakePayment = await pr?.canMakePayment();

    const hasApplePay = !!(canMakePayment && canMakePayment["applePay"]);
    const hasGooglePay = !!(canMakePayment && canMakePayment["googlePay"]);
    const sources = {
      applePay: hasApplePay,
      googlePay: hasGooglePay,
    };

    setAvailableSources(sources);
    if (hasApplePay) {
      setSelectedPaymentMethod("APPLE");
    } else if (hasGooglePay) {
      setSelectedPaymentMethod("GOOGLE");
    }
    setIsInitiateLoading(false);
  };

  useEffect(() => {
    initiatePaymentMethods();
  }, []);

  return (
    <>
      <div className="border-y-[#ccc] border-y-[0.5px] mt-8 w-full py-4">
        {availableSources?.applePay && !isInitiateLoading && (
          <button
            className="flex items-center w-full"
            onClick={() => setSelectedPaymentMethod("APPLE")}
          >
            <ApplePayIcon />
            <div className="ml-2">Apple Pay</div>
            <div className="flex flex-1" />
            <RadioButton isActive={selectedPaymentMethod === "APPLE"} />
          </button>
        )}
        {availableSources?.googlePay && !isInitiateLoading && (
          <button
            className="flex items-center w-full mt-5"
            onClick={() => setSelectedPaymentMethod("GOOGLE")}
          >
            <GooglePayIcon />
            <div className="ml-2">Google Pay</div>
            <div className="flex flex-1" />
            <RadioButton isActive={selectedPaymentMethod === "GOOGLE"} />
          </button>
        )}
        {!availableSources?.applePay &&
          !availableSources?.googlePay &&
          !isInitiateLoading && (
            <CardElements isComplete={isComplete} setComplete={setComplete} />
          )}
        {isInitiateLoading && (
          <div className="flex justify-center">
            <Spinner className="w-[40px]" />
          </div>
        )}
      </div>
      <BaseButton
        className={clsx(
          "mt-4",
          IS_MOBILE && "mb-4 fixed left-0 bottom-0 right-0 !w-[90%] mx-auto"
        )}
        onClick={initiatePayment}
        isDisabled={
          isLoading || (!isComplete && !selectedPaymentMethod) || isDisabled
        }
        isLoading={isLoading}
      >
        {buttonText}
      </BaseButton>
    </>
  );
}

function parseError(err: any) {
  let sripeErrorMsg;

  try {
    let spripeError = JSON.parse(err.message);
    sripeErrorMsg = spripeError?.message;
  } catch {}

  return sripeErrorMsg || err?.message;
}
