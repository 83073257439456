import Message from "./Message";
import { ChangeEvent, useState } from "react";
import {
  SocketMessage,
  MessageType,
  useMessageList,
} from "../../../state/chat";
import { User } from "../../../state/user";
import ReactTextareaAutosize from "react-textarea-autosize";
import SendIcon from "../../../components/Icons/SendIcon";

const ChatEmptyState = require("./ChatEmptyState.png");

interface Props {
  sendMessage: (input: SocketMessage) => void;
  sessionId: string;
  currentUser: User;
}
export default function ChatTab({
  sessionId,
  currentUser,
  sendMessage,
}: Props) {
  const { messages } = useMessageList(sessionId);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const onSubmit = (e?: any) => {
    e?.preventDefault();
    if (message.length > 0) {
      sendMessage({
        type: MessageType.SEND,
        sender: currentUser,
        message,
        roomId: sessionId,
        timestamp: new Date().toISOString(),
      });
    }

    setMessage("");
  };

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    if (e.target.value.length >= 500) {
      setError("Character limit reached");
    } else if (!!error) {
      setError("");
    }
  };

  const map = { SHIFT: false, ENTER: false };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // we must allow new line feeds on mobile
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  const onTouchEndHandler = (e: React.TouchEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      {messages.length === 0 && (
        <div className="mt-[40px] flex flex-col items-center mx-4">
          <img src={ChatEmptyState} height={90} width={143} />
          <div className="text-[16px] text-[#aaa] mt-4 text-center">
            Group members will chat here when in session
          </div>
        </div>
      )}
      <div className="flex flex-1 flex-col-reverse flex-start overflow-scroll hide-scrollbar pb-3">
        {messages.map((m, i) => (
          <Message
            name={m.sender.username}
            message={m.message}
            isResponse={false}
            thumbnailUrl={m.sender.thumbnailUrl}
            isSender={m.sender.id === currentUser.id}
            isPreviousMessageMine={
              messages[i + 1]?.sender.id === currentUser.id
            }
          />
        ))}
      </div>

      <div className="flex pb-4 pt-2 sticky left-0 bottom-0 right-0 bg-white">
        <form
          className="flex flex-1 items-center border-[#ccc] border-[0.5px] rounded-[24px] p-2 pl-4 min-h-[50px] gap-2"
          onSubmit={onSubmit}
        >
          <div className="flex flex-col w-full">
            <ReactTextareaAutosize
              placeholder="Message the group"
              className="outline-none w-full text-[16px] placeholder-[#aaa] hide-scrollbar"
              style={{ resize: "none" }}
              minRows={1}
              maxRows={3}
              onChange={onChange}
              value={message}
              maxLength={500}
              onKeyDown={onKeyDownHandler}
            />
            {error && (
              <div className="text-[12px] text-[#F73006] text-end">{error}</div>
            )}
          </div>
          {!!message && (
            <div className="flex h-full items-end">
              <button
                onTouchEnd={onTouchEndHandler}
                className="flex items-center justify-center h-[32px] w-[32px] rounded-full bg-[#00C947]"
              >
                <SendIcon />
              </button>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
