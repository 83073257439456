import { useState } from "react";
import LinkIcon from "../../../components/Icons/LinkIcon";
import ResponseMessage from "./Response";
import EndIcon from "../../../components/Icons/EndIcon";
import MuteIcon from "../../../components/Icons/UnmutedIcon";
import Modal from "../../../components/Modal";
import { Response, responseState } from "../../../state/response";
import { SocketMessage, sendPinState } from "../../../state/chat";
import HostControls from "./HostControls";
import GuestControls from "./GuestControls";
import {
  useDaily,
  useParticipantCounts,
  useParticipantIds,
} from "@daily-co/daily-react";
import UserThumbnail from "../../../components/UserThumbnail";
import copySessionLink from "../../../utils/copySessionLink";
import QuestionModal from "../../Room/components/QuestionModal";
import { updateResponse } from "../../../api";
import clsx from "clsx";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { formatCurrency } from "../../../utils/formatCurrency";
import BaseButton from "../../../components/BaseButton";
import Toaster from "../../../components/Toaster";
import ChevronIcon from "../../../components/Icons/ChevronIcon";
import EditPreferencesModal from "./EditPreferencesModal";

interface Props {
  isHost: boolean;
  responses: Response[];
  sessionId: string;
  topic?: string;
  hostUsername?: string;
  hostThumbnailUrl?: string;
  currentUserId?: string;
  pinnedMessage?: SocketMessage;
  onPin?: (messageId: string, message: string, username: string) => void;
  onRemovePin?: () => void;
  onEndSession?: () => void;
}

export default function ControlsTab({
  isHost,
  pinnedMessage,
  sessionId,
  responses,
  currentUserId,
  hostUsername,
  hostThumbnailUrl,
  topic,
  onEndSession,
  onPin,
  onRemovePin,
}: Props) {
  const [isQuestionActive, setIsQuestionActive] = useState<boolean>(false);
  const [isEditPreferencesActive, setIsEditPreferencesActive] =
    useState<boolean>(false);

  const daily = useDaily();
  const setResponses = useSetRecoilState(responseState(sessionId));
  const partcipantCounts = useParticipantCounts();
  const participants = daily?.participants();
  const potentialPin = useRecoilValue(sendPinState);

  const isMyResponsePinned =
    !!currentUserId && pinnedMessage?.sender.id === currentUserId;

  const onSubmitQuestion = async (question?: string, isAnon?: boolean) => {
    if (question) {
      await updateResponse({ sessionId, response: question }).then((r) => {
        setIsQuestionActive(false);
        if (r) {
          setResponses([r]);
        }
      });
    }
  };

  const onCopy = () => {
    if (hostUsername) {
      copySessionLink(hostUsername);
      Toaster.show({ message: "Link copied!" });
    }
  };

  const isUserInSession = (userId: string) =>
    Object.keys(participants || {}).find(
      (p) => participants?.[p].user_id === userId
    );

  let sortedResponses = [
    ...responses.filter(
      (r) =>
        !r.accepted?.Bool &&
        r.userId !== pinnedMessage?.sender.id &&
        isUserInSession(r.userId)
    ),
    ...responses.filter((r) => r.userId === pinnedMessage?.sender.id),
    ...responses.filter(
      (r) => r.accepted?.Bool && r.userId !== pinnedMessage?.sender.id
    ),
    ...responses.filter(
      (r) =>
        !isUserInSession(r.userId) &&
        !(r.accepted?.Bool && r.userId !== pinnedMessage?.sender.id) &&
        !(r.userId === pinnedMessage?.sender.id)
    ),
  ];

  return (
    <>
      <div
        className={clsx(
          "flex justify-between mt-4",
          isHost && "border-b-[0.5px] border-[#ccc] pb-2"
        )}
      >
        <div className="text-[14px] text-[#aaa]">
          {isHost ? (
            <div className="text-black flex items-center">
              {partcipantCounts.present}{" "}
              {partcipantCounts.present > 1 ? "people" : "person"} here
              <div className="w-[2px] h-[2px] rounded-full bg-black mx-[6px]" />
              US
              {formatCurrency(
                responses.reduce((a, b) => {
                  return a + b.amount;
                }, 0)
              )}
            </div>
          ) : responses.length ? (
            "Your Submitted Response"
          ) : null}
        </div>
        {isHost && (
          <button
            className="flex text-[13px] font-medium text-[#00C947] font-medium"
            onClick={onCopy}
          >
            <LinkIcon className="mr-2" />
            Session link
          </button>
        )}
      </div>
      {isHost && (
        <div className="text-[14px] text-[#aaa] mt-4">
          Submitted Responses ({sortedResponses.length})
        </div>
      )}
      <div
        className={clsx(
          "overflow-scroll hide-scrollbar",
          responses.length > 0 && "pb-[80px]"
        )}
      >
        {sortedResponses.length > 0
          ? sortedResponses.map((r) => (
              <>
                <ResponseMessage
                  id={r?.id}
                  isHost={isHost}
                  message={r.message}
                  name={r.username}
                  price={r.amount / 100}
                  isPinned={r.userId === pinnedMessage?.sender.id}
                  accepted={r.accepted?.Valid && r.accepted.Bool}
                  disabled={potentialPin && potentialPin.responseId !== r?.id}
                  timeRemaining={
                    potentialPin && potentialPin.responseId === r?.id
                      ? potentialPin.timeRemaining
                      : undefined
                  }
                  onPin={onPin}
                  isAnonymous={r.isAnonymous}
                  left={
                    !Object.keys(participants || {}).find(
                      (p) => participants?.[p].user_id === r.userId
                    )
                  }
                />
                {!isHost && !(r.accepted?.Valid && r.accepted.Bool) && (
                  <div className="bg-[#eee] rounded-[15px] p-[14px] text-[14px]">
                    {r.isAnonymous
                      ? `${hostUsername} will not spotlight your response`
                      : `We'll let you know if ${hostUsername} wants to spotlight your
                    response!`}
                    {r.isAnonymous && (
                      <button
                        className="text-[#00C947] text-[14px] font-medium flex items-center py-1"
                        onClick={() => setIsEditPreferencesActive(true)}
                      >
                        Edit preferences{" "}
                        <ChevronIcon
                          direction="right"
                          color="#00C947"
                          className="h-[15px] w-[15px] ml-[2px]"
                          strokeWidth={3}
                        />
                      </button>
                    )}
                  </div>
                )}
              </>
            ))
          : !isHost && (
              <div className="bg-white rounded-[20px] border-[#ccc] border-[0.5px] mx-[44px] mt-5 md:mt-2 p-4 text-center mb-4">
                <div className="mt-7 text-[13px] font-medium">
                  Today's Session
                </div>
                <div className="text-[20px] mt-2">{topic}</div>
                <BaseButton
                  className="mt-3"
                  onClick={() => setIsQuestionActive(true)}
                  isSmall
                >
                  Answer
                </BaseButton>
                <div className="text-[11px] text-[#aaa] mt-2">
                  {hostUsername} may respond to you in front of the group. You
                  can answer anonymously.
                </div>
              </div>
            )}
      </div>
      <div
        className={clsx(
          "absolute left-0 bottom-0 right-0 h-[80px] flex items-center pt-6 pb-4 mx-4",
          responses.length === 0 && !isHost ? "justify-end" : "justify-between"
        )}
        style={{
          backgroundImage:
            responses.length === 0 && !isHost
              ? "none"
              : "linear-gradient(rgba(0,0,0,0) 0%, white 30%, white 100%)",
        }}
      >
        {isHost ? (
          <HostControls
            onRemovePin={onRemovePin}
            onEndSession={onEndSession}
            spotlightedName={pinnedMessage?.sender.username}
          />
        ) : (
          <GuestControls
            hasResponse={responses.length > 0}
            isAnonymousResponse={responses?.[0]?.isAnonymous}
            hostUsername={hostUsername}
            isSpotlighted={isMyResponsePinned}
            onRemovePin={onRemovePin}
            sessionId={sessionId}
          />
        )}
      </div>
      <QuestionModal
        isActive={isQuestionActive}
        onClose={() => setIsQuestionActive(false)}
        topic={topic}
        onSubmit={onSubmitQuestion}
        creatorUsername={hostUsername}
        creatorThumbnailUrl={hostThumbnailUrl}
      />
      <EditPreferencesModal
        isActive={isEditPreferencesActive}
        onClose={() => setIsEditPreferencesActive(false)}
        response={responses?.[0]?.message}
        isAnonymous={responses?.[0]?.isAnonymous}
        creatorUsername={hostUsername}
        sessionId={sessionId}
      />
    </>
  );
}
