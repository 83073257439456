import BaseButton from "../../../components/BaseButton";
import Modal from "../../../components/Modal";

const PinUserImg = require("./PinUser.png");

interface Props {
  isActive: boolean;
  potentialSpotlightName: string;
  onClose: () => void;
}

export default function SendPinModal({
  isActive,
  potentialSpotlightName,
  onClose,
}: Props) {
  return (
    <Modal
      isActive={isActive}
      containerClassName="flex flex-col items-center justify-center p-4"
    >
      <img src={PinUserImg} height={100} width={148} />
      <div className="text-[28px] leading-[32px] mt-2">
        {potentialSpotlightName} has been sent a spotlight request
      </div>
      <div className="text-[16px] mt-4">
        They have 15 seconds to accept or decline the request
      </div>
      <BaseButton onClick={onClose} className="mt-[50px]">
        OK Got It
      </BaseButton>
    </Modal>
  );
}
