import { ChangeEvent, useEffect, useState } from "react";
import ApplePayIcon from "../../../components/Icons/ApplePayIcon";
import GooglePayIcon from "../../../components/Icons/GooglePayIcon";
import RadioButton from "../../../components/RadioButton";
import { PaymentRequest } from "@stripe/stripe-js";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import clsx from "clsx";
import { ProductType, createPayment } from "../../../api";
import CardElements from "./CardElements";
import Spinner from "../../../components/Spinner";
import BaseButton from "../../../components/BaseButton";
import { IS_MOBILE } from "../../../utils/constants";
import Toaster from "../../../components/Toaster";
import PaymentForm from "../../../components/PaymentForm";

const DEFAULT_ERROR =
  "We weren't able to process your payment. Please try another card or contact your bank";

interface Props {
  onSubmitPayment: () => void;
  promptResponse?: { question?: string; isAnonymous?: boolean };
  _sessionId?: string;
  creatorUsername?: string;
  currentUserId?: string;
  scrollableRef?: HTMLDivElement | null;
  minimumPriceCents: number;
}

export default function Payment({
  onSubmitPayment,
  promptResponse,
  _sessionId,
  creatorUsername,
  currentUserId,
  minimumPriceCents,
  scrollableRef,
}: Props) {
  const [amount, setAmount] = useState<string>("");
  const [error, setError] = useState<string>();

  if (!currentUserId) return <></>;

  const _createPayment = async (amount: number, id: string) => {
    return await createPayment({
      productType: ProductType.ENTRY_FEE,
      promptResponse: promptResponse?.question,
      isAnonymous: !!promptResponse?.isAnonymous,
      paymentMethodId: id,
      customerId: currentUserId || "",
      amount: amount,
      metadata: {
        sessionId: _sessionId || "",
      },
    });
  };

  const paymentAmount = (Number.parseFloat(amount) || 0) * 100;

  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^\d*\.?\d{0,2}$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setAmount(e.target.value);
      if ((Number.parseFloat(e.target.value) || 0) < minimumPriceCents / 100) {
        setError("US$" + (minimumPriceCents / 100).toFixed(2) + " minimum.");
      } else {
        setError(undefined);
      }
    }
  };

  return (
    <div className={clsx("w-full", IS_MOBILE && "pb-[96px]")}>
      <div className="text-[28px] mt-4 text-center">Select payment method</div>
      <div className="text-[15px] mt-2 text-center">
        Pay what you can to enter the session
      </div>
      <div className="flex mt-6 border-[0.5px] border-[#ccc] rounded-[12px] outline-none p-3 w-full ">
        <div className={clsx("text-[15px]", !amount && "text-[#aaa]")}>US$</div>
        <input
          className={clsx(
            "outline-none text-[15px] w-full",
            !!error && "text-[#FF461E]"
          )}
          placeholder="0.00"
          type="numeric"
          value={amount}
          onChange={onChangeAmount}
          onFocus={() => scrollableRef?.scrollTo({ top: 0 })}
        />
      </div>
      <div
        className={clsx(
          "text-[13px] mt-1 w-full whitespace-pre-wrap",
          !!error ? "text-[#FF461E]" : "text-[#aaa]"
        )}
      >
        {!!error
          ? `${error}\n\n`
          : "US$" +
            (minimumPriceCents / 100).toFixed(2) +
            " minimum. Creators see your contribution and higher contributions bump to the top of the list"}
      </div>
      <PaymentForm
        paymentAmount={paymentAmount}
        paymentDescription={`Entry to ${creatorUsername}'s live session`}
        isDisabled={paymentAmount < 3}
        buttonText="Pay & Join"
        setError={setError}
        onSubmitPayment={onSubmitPayment}
        _createPayment={_createPayment}
      />
    </div>
  );
}
