import { IconProps } from ".";

export default function EndIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2675 2.6614C16.8366 0.970332 14.729 0 12.4868 0H8.33305C4.72158 0 1.61662 2.52399 0.949844 6.00288L0.129965 10.2796C-0.283552 12.439 0.300236 14.6475 1.73109 16.3386C3.16338 18.0297 5.26959 19 7.51317 19H11.6669C15.2784 19 18.3834 16.476 19.0502 12.9971L19.87 8.72037C20.2836 6.56096 19.6998 4.35247 18.2689 2.6614H18.2675ZM12.4854 2.94465C13.8304 2.94465 15.0953 3.52657 15.9538 4.54177C16.8123 5.55697 17.1629 6.88207 16.9153 8.17771L16.0954 12.4545C16.0711 12.5835 16.0396 12.7097 16.0053 12.8345L6.36706 3.38775C6.96516 3.1031 7.63194 2.94465 8.33305 2.94465H12.4868H12.4854ZM7.51317 16.0554C6.16817 16.0554 4.9033 15.4734 4.04478 14.4582C3.18627 13.443 2.83571 12.1179 3.08325 10.8223L3.90313 6.54554C3.95035 6.30015 4.0176 6.06317 4.10345 5.83461L13.9248 15.4594C13.2566 15.8394 12.4825 16.0554 11.6655 16.0554H7.51174H7.51317Z"
        fill="#FF461E"
      />
    </svg>
  );
}
