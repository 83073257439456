import { useNavigate } from "react-router-dom";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import NavBar from "../../components/NavBar";
import { useEffect, useState } from "react";
import { PurchaseEntry, getPurchases } from "../../api";
import { formatCurrency } from "../../utils/formatCurrency";
import { useDaily } from "@daily-co/daily-react";

const EmptyPurchasesState = require("./EmptyPurchasesState.png");

export default function Purchases() {
  const [purchases, setPurchases] = useState<PurchaseEntry[]>([]);
  const navigate = useNavigate();
  const daily = useDaily();
  const onBackPress = () => {
    navigate(-1);
  };

  useEffect(() => {
    NavBar.setNavigation({
      leftIcon: (
        <button onClick={onBackPress}>
          <ChevronIcon direction="left" />
        </button>
      ),
      title: <div className="text-[20px] font-medium">Purchases</div>,
      inSessionHeader:
        daily?.meetingState() === "joined-meeting"
          ? { muted: !daily?.localAudio() }
          : undefined,
    });
  }, [daily]);

  useEffect(() => {
    getPurchases().then((r) => setPurchases(r.purchases));
  }, []);

  return (
    <div className="mx-6 my-4 overflow-auto hide-scrollbar">
      {purchases?.map((p) => (
        <div
          key={p.sessionDate.toString()}
          className="flex flex-col  border-[0.5px] border-[#ccc] w-full h-[136px] rounded-[25px] mt-4 p-4"
        >
          <div className="text-[16px]">
            {new Date(p.sessionDate).toLocaleDateString()} Session with{" "}
            {p.hostUsername}
          </div>
          <div className="font-medium text-[24px] mt-4">
            US{formatCurrency(p.amount || 0)}
          </div>
        </div>
      ))}
      {(purchases.length || 0) === 0 && (
        <div className="mt-[100px] flex flex-col items-center">
          <img src={EmptyPurchasesState} height={107} width={148} />
          <div className="text-[16px] text-[#aaa] mt-[22px]">
            No purchases yet
          </div>
        </div>
      )}
    </div>
  );
}
