import clsx from "clsx";
import BaseButton from "../../../components/BaseButton";
import { useState } from "react";
import Toaster from "../../../components/Toaster";
import { useSetRecoilState } from "recoil";
import { audioStream } from "../../../state/chat";

interface Props {
  onSubmit: () => void;
}

export default function Microphone({ onSubmit }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setMediaStream = useSetRecoilState(audioStream);

  const onClick = async () => {
    setIsLoading(true);
    const permissions = navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        setMediaStream(stream);
        onSubmit();
      })
      .catch((e) => {
        Toaster.showGenericError();
        setIsLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      <div className="text-[28px] mt-4 text-center">
        Allow microphone access
      </div>
      <div className="text-[15px] mt-4 text-center">
        Microphone access may be required if a creator spotlights your submitted
        response. Your microphone will be off when you enter the session.
      </div>
      <div className="mt-[95px]" />
      <BaseButton
        onClick={onClick}
        isLoading={isLoading}
        disabled={isLoading}
        className="my-4"
      >
        Grant Microphone Access
      </BaseButton>
    </>
  );
}
