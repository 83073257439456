import { generatePath, useLocation, useNavigate } from "react-router-dom";
import LinkIcon from "../../components/Icons/LinkIcon";
import UserThumbnail from "../../components/UserThumbnail";
import SettingsOption from "./components/SettingsOption";
import { CreatorOptions, NonCreatorOptions } from "./constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentUserState } from "../../state/user";
import { removeAuth, updateThumbnail } from "../../api";
import { ROUTES } from "../../routes";
import copySessionLink from "../../utils/copySessionLink";
import NavBar from "../../components/NavBar";
import ChevronIcon from "../../components/Icons/ChevronIcon";
import ProfileIcon from "../../components/Icons/ProfileIcon";
import { useDaily } from "@daily-co/daily-react";
import { useEffect } from "react";
import BaseButton from "../../components/BaseButton";
import Toaster from "../../components/Toaster";
import { useState } from "react";
import {createMedia} from "../../api";
import { useRef } from "react";
import { update } from "lodash";

export default function Settings() {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const navigate = useNavigate();
  const location = useLocation();
  const [thumbnailMediaId, setThumbnailMediaId] = useState<string | undefined>(
    undefined
  );
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
  const daily = useDaily();
  const isCreator = currentUser?.isCreator;

  const cloudflareAccountHash = "Qjhc1Lhyla2MVXmWlhHj6A";
  const inputRef = useRef<HTMLInputElement>(null);


  const mediaURL = (id: string, variant: string): string => {
    const u = new URL(
      `/${cloudflareAccountHash}/${id}/${variant}`,
      "https://imagedelivery.net"
    );
    return u.toString();
  };

  const _onChange = async (e: React.FormEvent<HTMLInputElement>) => {
    setIsUploadingPhoto(true);
    const files = e.currentTarget.files;
    if (!files) return;
    const file = files[0];

    const res = await createMedia({ Username: currentUser?.username || ""});
    const data = new FormData();

    data.append("file", file);
    await fetch(res.uploadUrl, { method: "POST", body: data });
    await updateThumbnail({ thumbnailMediaId: res.id });
    setThumbnailMediaId(res.id);
    setIsUploadingPhoto(false);
  };

  const logOut = () => {
    removeAuth();
    daily?.leave();
    setCurrentUser(undefined);
    navigate(ROUTES.LANDING);
  };

  const onCopy = () => {
    if (currentUser?.username) {
      copySessionLink(currentUser.username);
      Toaster.show({ message: "Link copied!" });
    }
  };

  useEffect(() => {});

  const onBackPress = () => {
    const sessionId = location.state?.fromSessionId;

    if (sessionId) {
      if (location.state.fromRoom) {
        navigate(
          generatePath(ROUTES.ROOM, {
            username: sessionId,
          })
        );
      } else {
        navigate(
          generatePath(ROUTES.SESSION, {
            sessionId,
          })
        );
      }
    } else if (isCreator && false) {
      // navigate to blank session page
    } else {
      navigate(ROUTES.ALL_SESSIONS);
    }
  };

  useEffect(() => {
    NavBar.setNavigation({
      leftIcon: (
        <button onClick={onBackPress}>
          <ChevronIcon direction="left" />
        </button>
      ),
      title: <div className="text-[24px] font-medium">Settings</div>,
      inSessionHeader:
        daily?.meetingState() === "joined-meeting"
          ? { muted: !daily?.localAudio() }
          : undefined,
    });
  }, [daily]);

  return (
    <>
    <input
    ref={inputRef}
    type="file"
    accept=".jpg, .png, .heic"
    className="hidden"
    onChange={_onChange}
    data-testid="input-file"
  />
    <div className=" flex flex-col flex-1">
      <div className="flex flex-col items-center border-b-[0.5px] border-[#ccc] pb-6">
        <UserThumbnail onClick={() => inputRef.current?.click()} name={currentUser?.username} className="mt-4" thumbnailUrl={thumbnailMediaId? mediaURL(thumbnailMediaId, "smallthumbnail") : currentUser?.thumbnailUrl || undefined}/>
        <div className="text-[24px]">{currentUser?.username}</div>
        {isCreator && (
          <button className="flex items-center mt-4" onClick={onCopy}>
            <LinkIcon className="mr-2" />
            insessions.co/{currentUser?.username}
          </button>
        )}
      </div>
      {(isCreator ? CreatorOptions : NonCreatorOptions).map((option) => (
        <SettingsOption
          label={option.label}
          route={option.route}
          isExternalLink={option.isExternalLink}
          navigate={navigate}
        />
      ))}
      <div className="flex flex-1" />
      <BaseButton onClick={logOut} className="mx-6 !w-auto">
        Log out
      </BaseButton>
      <button className="text-[15px] font-medium text-[#00C947] py-2 my-2">
        Delete Account
      </button>
    </div>
    </>
  );
}
