import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";
import { GetSessionResponse, getSession } from "../api";
import { useEffect } from "react";

export const sessionState = atomFamily<GetSessionResponse | undefined, string>({
  key: "SessionState",
  default: undefined,
});

const sessionSelector = selectorFamily({
  key: "SessionSelector",
  get: (sessionId: string) => async () => {
    const response = await getSession({ username: sessionId }).catch();
    return response;
  },
});

export function useSession(sessionId: string) {
  const loadableSession = useRecoilValueLoadable(sessionSelector(sessionId));
  const [session, setSession] = useRecoilState(sessionState(sessionId));

  useEffect(() => {
    if (loadableSession.state === "hasError") {
      // showGenericErrorToast();
    } else if (loadableSession.state === "hasValue" && !session) {
      const newSession = loadableSession.valueMaybe();
      if (newSession) {
        setSession(newSession);
      }
    }
  }, [session, loadableSession, setSession]);

  const refresh = useRecoilCallback(
    ({ snapshot }) =>
      async (sessionId: string) => {
        const sessionResponse = await getSession({ username: sessionId });
        setSession(sessionResponse);
        return sessionResponse;
      },
    []
  );

  return {
    session: session || loadableSession.valueMaybe(),
    setSession,
    refresh,
    isLoading: loadableSession.state === "loading",
  };
}
