import Spinner from "../../../components/Spinner";

interface Props {
  thumbnailUrl?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export default function AddDisplayIcon(props: Props) {
  
  console.log(props.thumbnailUrl, "thumbnailUrl", props.thumbnailUrl!="", !!props.thumbnailUrl)
  return (
    <button onClick={props.onClick} disabled={props.disabled}>
       {((!!props.thumbnailUrl && props.thumbnailUrl!="") || props.disabled) ? <div className="rounded-[15px] w-[64px] h-[64px]">{!props.disabled?<img src={props.thumbnailUrl} className="rounded-[15px]"/> : <Spinner className="w-[20px] mx-6 my-6"></Spinner>}</div> : <>
      <svg
        width="82"
        height="66"
        viewBox="0 0 82 66"
        fill="none"
        xmlns={"http://www.w3.org/2000/svg"}
      > 
       
        <rect
          x="7"
          y="1"
          width="64"
          height="64"
          rx="15"
          fill="white"
          stroke="#AAAAAA"
          strokeWidth="0.5"
          strokeDasharray="5 5"
        
        />
          <path d="M40 24L40 40" stroke="black" strokeWidth="2" />
          <path d="M32 32L48 32" stroke="black" strokeWidth="2" />
        
      </svg>
      </>}
    </button>
  );
}
