import { useRef, useEffect } from 'react';

export const useEventListener = (eventName: string, handler: (event: KeyboardEvent) => void, element = window) => {
  const savedHandler = useRef<(event: KeyboardEvent) => void>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    let eventListener: any;
    if (savedHandler.current) {
      eventListener = (event: any) => {
        savedHandler.current!(event);
      };
      element.addEventListener(eventName, eventListener);
    }
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
