import { IconProps } from ".";

const directionMap = {
  left: "180deg",
  up: "270deg",
  right: "0",
  down: "90deg",
};

type Direction = keyof typeof directionMap;

interface Props {
  direction: Direction;
  strokeWidth?: number;
}

export default function ChevronIcon({
  style,
  direction,
  strokeWidth = 2,
  ...rest
}: Props & IconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      data-testid="back-drawer-button"
      style={{ transform: `rotate(${directionMap[direction]})`, ...style }}
      {...rest}
    >
      <path
        d="M9.50195 6.50006L15.0022 12.0002L9.34531 17.6571"
        stroke="currentColor"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}
