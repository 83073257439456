export enum ROUTES {
  LANDING = "/",
  ROOM = "/:username",
  SESSION = "/session/:sessionId",
  ALL_SESSIONS = "/sessions",
  SETTINGS = "/settings",
  PURCHASES = "/purchases",
  EARNINGS = "/earnings",
  PERMISSIONS = "/permissions",
}
