import clsx from "clsx";
import BaseButton from "../../../components/BaseButton";
import ScaledText from "../../../components/ScaledText";
import { IS_MOBILE } from "../../../utils/constants";
import { getSessionTimestamp } from "../../../utils/getSessionTimestamp";
import { useEffect, useRef } from "react";

const BlurredChat = require("../../../components/Images/BlurredChat.png");

interface Props {
  inSession: boolean;
  sessionDate: Date;
  canJoin: boolean;
  isUpcoming?: boolean;
  topic?: string;
  isDisabled?: boolean;
  onJoin: () => void;
}

export default function RoomBody({
  inSession,
  sessionDate,
  canJoin,
  topic,
  isUpcoming,
  isDisabled,
  onJoin,
}: Props) {
  const ref = useRef<HTMLDivElement | null>();

  const now = new Date();

  const isButtonDisabled =
    (!inSession && !isUpcoming) || (isUpcoming && isDisabled);

  const heading = inSession
    ? "Today's Session"
    : isUpcoming
    ? "Upcoming Session"
    : "Previous Session";

  const buttonText =
    canJoin && inSession
      ? "Re-enter session"
      : inSession
      ? "Answer & Join"
      : isUpcoming
      ? "Register Interest"
      : "Answer & Join";

  useEffect(() => {
    if (ref.current && IS_MOBILE) {
      ref.current.style.maxHeight = `${window.innerHeight - 403}px`;
    }
  }, []);
  return (
    <div
      ref={(r) => (ref.current = r)}
      className="flex flex-col bg-cover bg-no-repeat items-center px-6 flex-1"
      style={{
        backgroundImage: `url(${BlurredChat})`,
        maxHeight: "calc(100% - 240px)",
      }}
    >
      <div className="flex items-center text-[14px] my-2">
        <div
          className={clsx(
            "h-[12px] w-[12px] rounded-full mr-[6px]",
            isUpcoming
              ? "bg-[#FFC2FE]"
              : inSession
              ? "bg-[#00C947]"
              : "bg-[#aaa]"
          )}
        />
        {inSession ? "In session" : isUpcoming ? "Upcoming" : "Not in session"}
        {isUpcoming && (
          <>
            <div className="rounded-full h-[2px] w-[2px] bg-[#aaa] mx-[6px]" />
            <div className="text-[12px]">
              {getSessionTimestamp(sessionDate, true)}
            </div>
          </>
        )}
      </div>
      <div
        className="bg-white rounded-[20px] mx-[60px] p-4 text-center w-full flex-1"
        style={{ maxHeight: "calc(100% - 100px)" }}
      >
        {heading && (
          <div className="mt-4 text-[14px] font-medium">{heading}</div>
        )}
        <ScaledText
          className={clsx(
            "text-[20px]  overflow-hidden",
            !IS_MOBILE && "max-h-[100px]",
            !!heading ? "mt-2" : "mt-10"
          )}
          containerStyle={{
            maxHeight: IS_MOBILE ? "calc(100% - 40px)" : "160px",
          }}
        >
          {topic}
        </ScaledText>
      </div>
      <div className="flex-1" />
      <BaseButton
        onClick={onJoin}
        isSmall
        className="mt-[10px] mb-[14px]"
        isDisabled={isButtonDisabled}
      >
        {buttonText}
      </BaseButton>
    </div>
  );
}
