import spacetime from "spacetime";

export const getSessionTimestamp = (date: Date, isUpcoming?: boolean) => {
  const st = spacetime(new Date(date));

  spacetime.tomorrow();

  var zone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "shortGeneric" })
    .split(" ")[2];

  if (isUpcoming) {
    return st.format(`{time} ${zone}, {month-short} {day-ordinal} {year}`);
  }

  if (st.isSame(spacetime.now(), "day")) {
    return "Today";
  } else if (st.isSame(spacetime.tomorrow(), "day")) {
    return "Tomorrow";
  } else if (st.isSame(spacetime.yesterday(), "day")) {
    return "Yesterday";
  }

  return st.format("numeric-us");
};

export const getUpcomingSessionTimestamp = (date: Date) => {
  const st = spacetime(date);

  const timeZone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "shortGeneric" })
    .split(" ")[2];

  return st.format(
    `{time} ${timeZone}, {day-short} {date-ordinal} {month}, {year}`
  );
};

export const getNewSessionTimestamp = (date: Date) => {
  const st = spacetime(date);

  const timeZone = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "shortGeneric" })
    .split(" ")[2];

  return st.format(`{time} ${timeZone}, {month} {date-ordinal}, {year}`);
};
