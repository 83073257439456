import { IconProps } from ".";

export default function HomeIcon(props: IconProps) {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_370_919)">
        <path
          d="M5.41943 5.34368C6.89505 5.34368 8.09127 4.14746 8.09127 2.67184C8.09127 1.19622 6.89505 0 5.41943 0C3.94381 0 2.74759 1.19622 2.74759 2.67184C2.74759 4.14746 3.94381 5.34368 5.41943 5.34368Z"
          fill="black"
        />
        <path
          d="M20.8096 6.11729L16.304 2.9813C14.2911 1.58045 11.5856 1.58045 9.57149 2.9813L4.42621 6.5634C3.13839 7.45922 2.24858 8.86128 1.98404 10.4076L0.783996 17.4311H0.780389L0 22.0004H21.5455L22.3259 17.4311H22.3138L23.2505 11.9456C23.6329 9.70419 22.6746 7.41593 20.8084 6.11729H20.8096ZM5.41944 17.4311L6.48841 11.1772C6.54733 10.8297 6.74694 10.5158 7.03673 10.315L12.182 6.73294C12.6341 6.4179 13.2414 6.4179 13.6935 6.73294L18.1991 9.86893C18.6175 10.1599 18.8327 10.6746 18.7474 11.1772L17.6784 17.4323H5.41944V17.4311Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_370_919">
          <rect width="23.3347" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
