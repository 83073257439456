import { IconProps } from ".";

export default function PinIcon(props: IconProps) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9461 2.67239C13.6314 1.91338 11.9528 2.31492 11.1239 3.58667L9.03907 6.78541L8.19581 8.24598C6.75782 8.2599 5.35691 8.9623 4.5005 10.2357L3.84566 11.2093L2.88875 12.8668L4.86824 14.0096L5.78601 12.42L6.39716 11.5113C7.04733 10.5446 8.33503 10.2464 9.34397 10.8289L10.4868 8.84938C10.4868 8.84937 10.4868 8.84936 10.4868 8.84935L10.9877 7.98174L13.0388 4.83473C13.2046 4.58038 13.5403 4.50007 13.8032 4.65187L15.3808 5.56268C15.6437 5.71448 15.742 6.04539 15.6047 6.31613L13.9048 9.66595L12.9829 11.2627L12.9856 11.2643L12.2637 12.5146C13.2712 13.0962 13.6562 14.3587 13.1448 15.4036L12.6621 16.3899L11.7443 17.9795L13.7238 19.1224L14.6807 17.465L15.1979 16.4083C15.8722 15.0304 15.7798 13.4664 15.0726 12.2148L15.9152 10.7553L17.643 7.35045C18.3299 5.99675 17.8383 4.3422 16.5237 3.58319L14.9461 2.67239Z"
        fill="black"
      />
      <rect
        x="7.34253"
        y="15.4385"
        width="2.28571"
        height="5.14286"
        transform="rotate(30 7.34253 15.4385)"
        fill="black"
      />
    </svg>
  );
}
